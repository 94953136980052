/**
 * el-input 去除首尾空格
 * @param {*} el
 *  v-trim
 */


export default {
  inserted(el, binding) {
    if (el) {
      const inputEle = getInput(el);
      const handler = (event) => {
        const newVal = event.target.value.trim();
        if (event.target.value !== newVal) {
          event.target.value = newVal;
          dispatchEvent(inputEle, 'input');
        }
      };
      el.inputEle = inputEle;
      el._blurHandler = handler;
      inputEle && inputEle.addEventListener('blur', handler);
    }
  },
  unbind: (el) => {
    if (el) {
      const { inputEle } = el;
      inputEle && inputEle.removeEventListener('blur', el._blurHandler);
    }
  }
};
function getInput(el) {
  let inputEle;
  if (el.tagName !== 'INPUT') {
    inputEle = el.querySelector('input');
  } else {
    inputEle = el;
  }
  return inputEle;
}
function dispatchEvent(el, type) {
  const evt = document.createEvent('HTMLEvents');
  evt.initEvent(type, true, true);
  el.dispatchEvent(evt);
}