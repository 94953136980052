<template>
    <div>
        <!--    v-for="(title, index) in PURE_LIGHT_SOC_SYSREM_TITLE" :key="index" -->
        <el-card :body-style="{ padding: '0px' }" shadow="never" class="mb-10">
            <slot name="header" slot="header">
                <div class="font_14 lh30">
                    <span> {{ title }}</span>
                </div>
            </slot>
            <div class="card_content" v-loading="loading">
                <slot>
                </slot>
            </div>
        </el-card>
    </div>
</template>

<script>

export default {
    name: "customCard",
    components: {},
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: [String, Number],
            default: ""
        }
    },
    data() {
        return {

        };
    },
    watch: {

    },
    computed: {

    },
    methods: {

    },
    created() {

    },
    mounted() {

    }
};
</script>
<style scoped lang='scss'>
.card_content {
    padding: 20px;
    overflow: hidden;
}
</style>
