export default {
	"language": {
		"name": "繁體中文"
	},
  product:{
    "PleaseEnter": "請輸入產品名稱",
    "PleaseEnterTheProductSerialNumber": "請輸入產品序號",
    "PleaseEnterSubmodelNumber": "請輸入子型號",
    "PleaseenterTheSubmodelSerialNumber": "請輸入子型號序號",
    "PleaseSelectTheDeviceType": "請選擇設備類型",
    "PleaseSelectDevelopmentType": "請選擇研發類型",
    "management": "產品管理",
    "name": "產品名稱",
    "reset": "重置",
    "search": "搜尋",
    "ApplicationList": "應用列表",
    "newProduct": "新建產品",
    "editName": "編輯產品",
    "DetailSetting": "詳細設定",
    "delete": "刪除",
    "serialNumber": "產品序號",
    "deviceType": "設備類型",
    "RandDtype": "研發類型",
    "subType": "子型號",
    "updateTime": "更新時間",
    "operation": "操作",

    "deviceList": "設備清單(出貨日誌)",
    "parameterManagement": "參數管理",
    "updataManagement": "升級管理",

    "ParentDevice": "父設備",
    "AssociatedPowerStationState": "關聯電站狀態",
    "ImportDeviceList": "導入設備清單",
    "PlantDetails": "電站詳細",
    "parameterName": "參數名稱",

    "OwningPowerStation": "所屬電站",

    "ParameterClass": "參數類別",
    "ParameterSettingType": "參數設定類型",
    "ParameterOperationType": "參數操作類型",
    "ImportParameter": "導入參數",
    "OwningUpperLevelParameterCategory": "所屬上級參數分類",
    "WhetherYoSupportBatchRead": "是否支援批量讀",
    "value": "值",
    "scope": "範圍",
    "unit": "單位",
    "explain": "說明",
    "remark": "備註",

    "fileName": "文件名",
    "fileAddress": "文件地址",
    "versionNumber": "版本號",
    "ProductOfOwnership": "所屬產品",

    "ProductDetailsSettings": "陽台1.0 - 產品詳細設定",
    "QueryResultList": "查詢結果列表",
    "AddFirmware": "新增韌體",
    "NewParameter": "新增參數",

    "datalog": "采集器",
    "Inverter": "逆變器",
    "storage": "儲能",
    "battery": "電池",
    "socket": "插座",

    "DownloadTemplate": "下載模板",
    "nation": "國家",
    "agent": "代理商",
    "expirationDate": "保質期",
    "UpdateTime": "更新時間",
    "LastWeek": "最近一週",
    "LastMonth": "最近一個月",
    "LastThreeMonths": "最近三個月",

    "parameterType": "參數類型",
    "ProtocolAddress": "協議地址",
    "stepSize": "步長",
    "PleaseEnterAParameterName": "請輸入參數名稱",
    "parameterCoding": "參數編碼",
    "PleaseEnterTheParameterCode": "請輸入參數編碼",
    "ParameterDataType": "參數資料類型",
    "PleaseEnterTheAddressInTheParameterProtocol": "請輸入協議中的參數地址",
    "blockEncoding": "區塊編碼",
    "PleaseEnterABlockCode": "請輸入區塊編碼",
    "GroupName": "群組名稱",
    "PleaseEnterAGroupName": "請輸入群組名稱",
    "PleaseEnterTheUnit": "請輸入單位",
    "decimalPoint": "小數點",
    "PleaseEnterTheDecimalPoint": "請輸入小數點",
    "leastValue": "最小值",
    "PleaseEnterTheUnit": "請輸入單位", // 注意：此处重复，应为 "PleaseEnterTheLeastValue"
    "maximumValue": "最大值",
    "PleaseEnterTheMaximumValue": "請輸入最大值",
    "PleaseEnterTheStepSize": "請輸入步長",
    "addEquipment": "新增設備",
    "equipment": "設備",
    "remove": "移除",
    "sort": "排序",
    "PleaseEnterTheOrder": "請輸入排序",
     "PleaseEnterTheParameterSettingType": "請輸入參數設定類型",

     "versionNumber": "版本號",
     "ProductOfOwnership": "所屬產品",
     "PleaseSelectAFolderPath": "請選擇文件夾路徑",
     "ReturnToSuperior": "返回上級",
     "PleaseEnterTheVersionNumber": "請輸入版本號",
     "firmwareSpecification": "韌體說明",
     "PleaseEnterFirmwareDescription": "請輸入韌體說明",
     "FileName": "文件名稱",
     "DeviceType": "設備類型",
     "FileAddress": "文件地址",
     "time": "時間",
     

  },
	"sidebar": {
		"home": "數據概覽",
		"endbenutzer": "用戶管理",
		"plant": "場景管理",
		"plant_plant": "電廠管理",
		"device": "設備管理",
		"datalog": "採集器列表",
		"inverter": "逆變器列表",
		"sotrage": "儲能列表",
		"socket": "插座列表",
		"battery": "電池列表",
		"agent": "代理商管理",
		"systematicSupply": "出貨日誌",
		"firmware": "固件列表",
		"firmware_up": "固件上傳",
		"firmware_log": "升級記錄",
		"system": "系統設置",
		"system_datalog_log": "採集器日誌",
		"system_log": "操作日誌",
		"system_tongxun": "通訊日誌",
		"system_app": "App 版本",
		"personal": "用戶資訊",
		"logout": "退出帳戶",
		"organizationalManagement": "組織管理"
	},
	"login": {
		"email": "郵箱",
		"password": "密碼",
		"remember": "記住郵箱和密碼",
		"enteremail": "請輸入郵箱",
		"enterpassword": "請輸入密碼"
	},
	"home": {
		"echarts1": {
			"title": "註冊用戶",
			"subhead": "今日新增/用戶總數"
		},
		"echarts2": {
			"title": "登錄用戶",
			"subhead": "今日登錄/用戶總數"
		},
		"echarts3": {
			"title": "添加代理商",
			"subhead": "今日登錄/代理商總數"
		},
		"echarts4": {
			"title": "添加場景",
			"subhead": "新增場景/場景總數",
			"title_plant": "添加電站",
			"subhead_plant": "新增電站/電站總數"
		},
		"echarts5": {
			"title": "添加採集器",
			"subhead": "新增採集器/採集器總數"
		},
		"echarts6": {
			"title": "添加設備",
			"subhead": "新增設備/設備總數"
		}
	},
	"End_user": {
		"input_email": "請輸入用戶郵箱",
		"btn_search": "搜索",
		"btn_add": "添加",
		"btn_delete": "刪除",
		"btn_binding": "綁定",
		"add_station": "新增場景",
		"lable_StationName": "場景名稱",
		"lable_StationType": "場景類型",
		"lable_PVCapacity": "太陽電池容量",
		"lable_InstalltionDate": "安裝日期",
		"lable_Country": "國家",
		"lable_City": "城市",
		"lable_Address": "地址",
		"lable_TimeZone": "時區",
		"lable_Energyprice": "能源單價",
		"lable_IncomeUnit": "收入單位",
		"input_StationName": "請輸入場景名稱",
		"input_StationType": "請選擇類型",
		"input_PVCapacity": "請輸入電池容量",
		"input_InstalltionDate": "請選擇安裝日期",
		"input_Country": "請選擇國家",
		"input_City": "請選擇城市",
		"input_Address": "請輸入地址",
		"input_TimeZone": "請選擇時區",
		"input_Energyprice": "請輸入單價",
		"input_IncomeUnit": "選擇收入單位",
		"max_20": "字元最大不能超過20個",
		"max_5": "字元最大不能超過5個",
		"max_8": "字元最大不能超過8個",
		"format": "請輸入正確的格式",
		"close": "關閉",
		"submit": "提交",
		"title_delete": "刪除？",
		"no": "否",
		"yes": "是",
		"Bingding_Agent": "綁定代理商",
		"Agent_Code": "代理商:",
		"please_select": "請選擇"
	},
	"plant": {
		"input_email": "請輸入用戶郵箱",
		"search": "搜索",
		"edit": "編輯",
		"delete": "刪除",
		"edit_plant": "編輯場景",
		"input_station": "請輸入場景名稱",
		"lable_StationName": "場景名稱",
		"lable_StationType": "場景類型",
		"lable_PVCapacity": "太陽電池容量",
		"lable_InstalltionDate": "安裝日期",
		"lable_Country": "國家",
		"lable_City": "城市",
		"lable_Address": "地址",
		"lable_TimeZone": "時區",
		"lable_Energyprice": "能源單價",
		"lable_IncomeUnit": "收入單位",
		"input_StationName": "請輸入場景名稱",
		"input_StationType": "請選擇類型",
		"input_PVCapacity": "請輸入電池容量",
		"input_InstalltionDate": "請選擇安裝日期",
		"input_Country": "請選擇國家",
		"input_City": "請選擇城市",
		"input_Address": "請輸入地址",
		"input_TimeZone": "請選擇時區",
		"input_Energyprice": "請輸入單價",
		"input_IncomeUnit": "選擇收入單位",
		"max_20": "字元最大不能超過20個",
		"max_5": "字元最大不能超過5個",
		"max_8": "字元最大不能超過8個",
		"format": "請輸入正確的格式",
		"close": "關閉",
		"submit": "提交",
		"title_delete": "刪除？",
		"no": "否",
		"yes": "是"
	},
	"datalog": {
		"email": "請輸入郵箱",
		"datalogSn": "請輸入採集器序列號",
		"search": "搜索",
		"Version": "版本號",
		"setting": "設置",
		"upgrade": "升級",
		"delete": "刪除",
		"title_datalog": "採集器設置",
		"span_info": "資訊",
		"span_datalog": "採集器序列號:",
		"span_name": "別名:",
		"span_time": "更新時間:",
		"span_firmware": "信號/固件版本:",
		"span_order": "命令",
		"span_settingdns": "設置域名",
		"span_inputsetting": "請輸入域名",
		"span_upgrade": "升級採集器",
		"span_reset": "重啟採集器",
		"span_setup": "高級設置",
		"span_param": "參數",
		"span_value": "值",
		"btn_setup": "高級設置",
		"btn_setting": "設置",
		"btn_settingdns": "設置域名",
		"btn_upgrade": "升級",
		"btn_reset": "重啟",
		"btn_read": "讀取",
		"btn_close": "取消",
		"methods_version": "檢測版本更新?",
		"no": "否",
		"yes": "是",
		"methods_delete": "刪除?",
		"methods_rereset": "重啟?",
		"methodes_inputDns": "請輸入域名",
		"methodes_DNS": "是否設置此域名"
	},
	"sotrage": {
		"select": "請選擇",
		"email": "請輸入郵箱",
		"datalogSn": "請輸入採集器序列號",
		"deviceSn": "請輸入設備序列號",
		"search": "搜索",
		"sotrage": "儲能設置"
	},
	"device": {
		"select": "請選擇",
		"email": "請輸入郵箱",
		"datalogSn": "請輸入採集器序列號",
		"deviceSn": "請輸入設備序列號",
		"search": "搜索",
		"title": "逆變器設置",
		"setting": "設置",
		"setting_inverte": "設置逆變器參數",
		"input_start_address": "起始寄存器地址",
		"input_ent_address": "終止寄存器地址",
		"input_setting_value": "請輸入設置值",
		"read_data": "讀取寄存器數據:",
		"input_start_read": "起始寄存器地址",
		"input_end_read": "終止寄存器地址",
		"btn_address": "設置地址",
		"btn_value": "設置參數",
		"btn_read": "讀取",
		"btn_close": "返回",
		"selet_data": "選擇日期"
	},
	"battery": {
		"select": "請選擇",
		"email": "請輸入郵箱",
		"datalogSn": "請輸入採集器序列號",
		"deviceSn": "請輸入設備序列號",
		"search": "搜索"
	},
	"socket": {
		"title": "插座設置",
		"setting": "設置",
		"span": "設置了多個寄存器地址，值也要設置多個。 設置值中間要用 , 隔開，示例（1，2，3）",
		"setting_device": "設置逆變器參數：",
		"startaddress": "起始寄存器地址",
		"stopaddress": "終止寄存器地址",
		"value": "請輸入設置值",
		"readdata": "讀取寄存器數據：",
		"setting_address": "設置地址",
		"setting_value": "設置參數",
		"read": "讀取",
		"return": "返回"
	},
	"agent": {
		"close": "取消",
		"submit": "提交",
		"input_email": "請輸入郵箱",
		"search": "搜索",
		"add_agent": "添加代理商",
		"import_device": "導入設備",
		"edit": "編輯",
		"title": "添加代理商",
		"email": "郵箱",
		"password": "密碼",
		"phone": "手機號碼",
		"company": "公司",
		"language": "語言",
		"address": "地址",
		"input_password": "請輸入密碼",
		"input_phone": "請輸入手機號",
		"input_company": "請選擇公司",
		"input_language": "請輸入語言",
		"input_address": "請輸入地址",
		"relus_email": "請輸入有效的電子郵件地址",
		"title_edit": "編輯代理商",
		"title_device": "上傳",
		"span": "示例: Excle 表格",
		"btn_upload": "上傳",
		"delete": "刪除",
		"agent_delete": "刪除代理商?"
	},
	"ship": {
		"starttime": "開始時間",
		"endtime": "結束時間",
		"deviceSn": "請輸入設備序列號",
		"select": "請選擇公司",
		"search": "搜索"
	},
	"Firmware": {
		"file_name": "請輸入檔案名字",
		"search": "搜索",
		"add": "添加",
		"delete": "刪除",
		"title": "固件上傳",
		"select_folder": "選擇資料夾:",
		"select_folder_type": "請選擇資料夾類型",
		"craete_folder": "建立一個新的資料夾",
		"folder_path": "資料夾路徑：",
		"folder_name": "文件名稱:",
		"input_folder_name": "輸入資料夾名稱",
		"create": "建立資料夾",
		"listed_files": "文件列表：",
		"import_folder": "導入文件",
		"uplad_folder": "上傳文件",
		"close": "返回"
	},
	"Upgrade": {
		"starttime": "開始時間",
		"endtime": "結束時間",
		"deviceSn": "請輸入設備序列號",
		"type": "請選擇類型",
		"search": "搜索",
		"datalog": "採集器",
		"Inverter": "逆變器",
		"Control": "控制器"
	},
	"datalogs_logs": {
		"starttime": "開始時間",
		"endtime": "結束時間",
		"deviceSn": "請輸入設備序列號",
		"Version": "請輸入版本",
		"search": "搜索"
	},
	"Logs": {
		"starttime": "開始時間",
		"endtime": "結束時間",
		"deviceSn": "請輸入設備序列號",
		"type": "請選擇類型",
		"search": "搜索"
	},
	"Special": {
		"starttime": "開始時間",
		"endtime": "結束時間",
		"deviceSn": "請輸入設備序列號",
		"datalogSn": "請輸入採集器序號",
		"type": "請選擇類型",
		"search": "搜索",
		"save": "保存",
		"Examples": "範例：(2305210230,2305210139)"
	},
	"App": {
		"System_Config": "系统配置",
		"APP_Version": "App版本發佈",
		"Android_Version": "安卓版本:",
		"IOS_Version": "IOS版本:",
		"APP_Type": "App類型:",
		"New_Version": "新版本",
		"update_content": "更新內容描述:(用“/n”來劃分)",
		"Whether_update": "是否強制更新：",
		"no": "否",
		"yes": "是",
		"submit": "提交"
	},
	"Tips": {
		"app_submit": "确定修改信息?",
		"firmware_delete": "確認刪除?",
		"firmware_deleteCDN": "刪除CDN中的文件?",
		"yes": "是",
		"no": "否"
	},
	"global": {
		"DataDetails": "資料詳情",
		"device": "逆變器設置",
		"device_data": "設置逆變器參數:",
		"storage": "儲能設置",
		"storage_data": "設置儲能參數",
		"battery": "電池設置",
		"battery_data": "設置電池參數",
		"socket": "插座設置",
		"socket_data": "設置插座參數"
	},
	"btn": {
		"setting_address": "設置地址",
		"close": "關閉",
		"submit": "提交",
		"Setting_parameters": "設置參數",
		"Reading": "讀取",
		"search": "搜索",
		"add": "添加",
		"import": "導入",
		"download": "下載示例",
		"edit": "編輯",
		"login": "登錄",
		"export": "匯出"
	},
	"form": {
		"addShip": "添加出貨設備",
		"editShip": "編輯出貨設備",
		"import": "導入設備資訊",
		"shipSN": "出貨序列號",
		"agent": "代理商",
		"networkCall": "是否執行配網回調",
		"warrantyPeriod": "質保時間",
		"shiptime": "出貨時間",
		"shiptype": "出貨類型",
		"classify": "分類",
		"file": "將檔案拖放到此處或點擊上傳",
		"edit_plant": "編輯場景",
		"input_station": "請輸入場景名稱",
		"lable_StationName": "場景名稱",
		"lable_StationType": "場景類型",
		"input_StationName": "請輸入場景名稱",
		"edit_plant_plant": "編輯電廠",
		"input_station_plant": "請輸入電廠名稱",
		"lable_StationName_plant": "電廠名稱",
		"lable_StationType_plant": "電廠類型",
		"input_StationName_plant": "請輸入電廠名稱"
	},
	"placeholder": {
		"select": "請選擇",
		"choiceCountry": "請選擇國家",
		"choiceTimezone": "請選擇時區",
		"enterEmail": "請輸入郵箱",
		"enterPhone": "請輸入手機號",
		"verificationCode": "請輸入驗證碼",
		"enterPassword": "請輸入6-20位密碼",
		"confirmPassword": "請確認密碼",
		"readAndAgree": "我已經閱讀並同意",
		"search": "搜索",
		"searchPlantName": "搜索電站名字",
		"searchDeviceName": "搜索設備名字",
		"searchDataloggerName": "搜索採集器名字",
		"enterPlantName": "請輸入電站名字",
		"enterPlantType": "請輸入電站类型",
		"enterPVCapacity": "請輸入PV容量",
		"choiceInstallationDate": "請選擇安裝日期",
		"enterPlantAddress": "請輸入電站地址",
		"enterOldPassword": "請輸入舊密碼",
		"enterNewPassword": "請輸入新密碼",
		"enterConfirmPassword": "請確認新密碼",
		"enter": "請輸入{scope}",
		"country": "國家",
		"city": "城市",
		"scanCodeAddPlant": "請掃碼添加電站!",
		"currentNoPlant": "當前沒有電站，請點擊添加!",
		"selectRepeatTime": "請選擇重複時間",
		"enterDatalogger": "請輸入採集器序列號",
		"enterDeviceSN": "請輸入設備序列號"
	},
	"setting": {
		"general": {
			"needGreat": "需要大於",
			"needSmall": "需要小於",
			"gapScope": "輸入參數的間隔是",
			"valScope": "輸入參數的范圍是"
		},
		"socket": {
			"switchStatus": "插座開關",
			"repetition": "重復時間",
			"enableOrNot": "是否啟用",
			"monday": "週一",
			"tuesday": "週二",
			"wednesday": "週三",
			"thursday": "週四",
			"friday": "週五",
			"saturday": "週六",
			"sunday": "週日"
		},
		"type3": {
			"dischargeScheduleMode": "定時放電模式",
			"timeDischarge": "定時放電",
			"workingDay": "工作日",
			"weekend": "週末",
			"pureLightControlMode": "純光控模式",
			"stopDischarge": "SOC低端停止放電設定值",
			"chargeTransferDischarge": "SOC充滿轉放電設定值",
			"batterySOCSetting": "電池SOC設置",
			"lowBatteryAlam": "電池 SOC 低端告警設定值",
			"lowBatteryShutdown": "電池 SOC 低端停止放電設定值",
			"systemSetting": "系統設置",
			"systemWorkMode": "系統工作模式",
			"restoreFactory": "恢復出廠設置",
			"loadMode": "負載模式",
			"timingDischarge": "定時放電模式",
			"DCOutputNormallyClosed": "直流輸出常閉模式",
			"DCOutputNormallyOpen": "直流輸出常開模式",
			"ForcedPowerProtection": "強制保電模式",
			"mode": "模式",
			"startTime": "開始時間",
			"stopTime": "結束時間",
			"onlyAddThree": "最多添加21個定時器",
			"startCompareEnd": "定時器的開始時間只能小於結束時間",
			"confirmClose": "你確定要關機嗎？"
		},
		"type6": {
			"countryCode": "國家編號",
			"poweradjppm": "逆變器功率調節速度",
			"enaINVTR": "遠程開關機",
			"enaSysProtIF_selfCheck": "系統保護接口自檢",
			"enaRestoretodefault": "恢復默認值",
			"UV_resopnseTime": "{num}級欠壓響應時間",
			"UF_resopnseTime": "{num}級欠頻響應時間",
			"OV_resopnseTime": "{num}級過壓響應時間",
			"OF_resopnseTime": "{num}級過頻響應時間",
			"open": "開啟",
			"recover": "恢復",
			"noHave": "無",
			"activePowerSwitch": "有功功率率開關",
			"activePowerPermillage": "有功功率千分比"
		},
		"type7": {
			"paramterSetting": "參數設置",
			"batteryVoltage": "蓄電池電壓",
			"batteryCapacity": "蓄電池容量",
			"PVChargingCurrent": "光伏充電電流",
			"overVoltage": "超壓電壓",
			"chargingLimitVoltage": "充電限制電壓",
			"increaseChargeReturnVoltage": "提升充電返回電壓",
			"overDischargeVoltage": "過放電壓",
			"stopchangeCurrent": "停止充電電流",
			"inverter485Address": "485地址",
			"passwordValue": "用戶密碼",
			"inputPassword": "密碼輸入",
			"recordFaultCodes": "記錄故障碼",
			"isOpen": "開關機控制",
			"equalizingChargeCmd": "立即充電均衡",
			"reset": "復位控製",
			"factory": "恢復出廠設置",
			"clearAlarm": "清除當前告警",
			"clearHistory": "清除歷史記錄",
			"generatorSwitchCmd": "發電機切換",
			"nowTimeStr": "當前時間",
			"setVal": {
				"reset": "復位",
				"recover": "恢復",
				"clearAlarm": "清除告警",
				"clearHistory": "清除歷史",
				"toggle": "切換"
			}
		},
		"type8": {
			"systemSwitch": "系統開關"
		},
		"type10": {
			"modeSelect": "模式選擇",
			"standbyMode": "備電模式",
			"PureOffGrid": "純離網啟動模式",
			"energyNet": "能量上網",
			"inverter0": "逆變補能量到本地負載",
			"inverter1": "逆變給電網發電",
			"inverter2": "逆變給本地負載帶CT",
			"inverter3": "逆變給本地負載智能插座或者智能電錶",
			"chargePriority": "充電優先級",
			"PVPriority": "光伏優先",
			"utilityPriority": "市電優先",
			"hybridModel": "混合模式",
			"PVOnly": "僅光伏",
			"batteryLowAlarm": "電池SOC低告警值",
			"batteryLowShutdown": "電池SOC低關機點",
			"batteryFull": "電池充飽SOC判斷值",
			"ACCharging": "AC充電",
			"startTime": "起始時間",
			"endTime": "結束時間",
			"forcedDischarge": "強制放電",
			"phaseGridSide": "相電網側",
			"power": "有功率",
			"current": "電流",
			"gridConnectionStandard": "並網標準",
			"baudRate": "485波特率",
			"disable": "失能",
			"enable": "使能",
			"ApprovalStandards": "認證標準",
			"antiRefluxPower": "防逆流功率",
			"antiRefluxSet": "防逆流使能",
			"batFullSocSet": "電池充飽SOC判斷值(%)",
			"auxSourceSet": "輔源開關",
			"timeSharingGridConnectedDischarge": "分時併網放電",
			"timeSharingGridConnection": "分時併網",
			"outputPower": "輸出功率",
			"onlyAddThree": "最多添加{num}個定時器",
			"productModelNumber": "產品型號",
			"PleaseTurnOnThe_PCS_switchFirst":"請先打開 PCS 開關",
			"ThereIs_a_conflictInTheChargingAndDischargingTime":"充放電時間有衝突"
		},
		"type11": {
			"switchPCS": "PCS開關",
			"functionSwitch": "功能開關",
			"maxChargeVol": "最高充電電壓（V）",
			"onGridMinDischargeVol": "並網最低放電電壓（V）",
			"offGridOutputVol": "離網輸出電壓（V）",
			"maxChargeCur": "最高充電電流(A)",
			"onGridMaxDischargeCur": "並網最高放電電流(A)",
			"offGridMaxDisChargeCur": "離網最高放電電流(A)",
			"minSocBMS": "BMS最小SOC（%）",
			"minSocDischarge": "智能放電最小SOC（%）",
			"maxSocDischarge": "智能放電最大SOC（%）",
			"timeStr": "設備時間",
			"timerSetting": "定時器設置{num}",
			"chargeStartTime1": "起始充電時間1",
			"chargeLongTime1": "充電時長1",
			"chargeTimeMaxSOC1": "充電時段 1 最大SOC（%）",
			"chargeTimeCurrent1": "充電時段 1 充電電流(A)",
			"dischargeStartTime1": "起始放電時間1",
			"dischargeLongTime1": "放電時長1",
			"dischargeTimeMaxSOC1": "放電時段 1 最小SOC（%）",
			"dischargeTimeCurrent1": "放電時段 1 放電電流(A)",
			"chargeStartTime2": "起始充電時間2",
			"chargeLongTime2": "充電時長2",
			"chargeTimeMaxSOC2": "充電時段 2 最大SOC（%）",
			"chargeTimeCurrent2": "充電時段 2 充電電流(A)",
			"dischargeStartTime2": "起始放電時間2",
			"dischargeLongTime2": "放電時長2",
			"dischargeTimeMaxSOC2": "放電時段 2 最小SOC（%）",
			"dischargeTimeCurrent2": "放電時段 2 放電電流(A)",
			"chargeStartTime3": "起始充電時間3",
			"chargeLongTime3": "充電時長3",
			"chargeTimeMaxSOC3": "充電時段 3 最大SOC（%）",
			"chargeTimeCurrent3": "充電時段 3 充電電流(A)",
			"dischargeStartTime3": "起始放電時間3",
			"dischargeLongTime3": "放電時長3",
			"dischargeTimeMaxSOC3": "放電時段 3 最小SOC（%）",
			"dischargeTimeCurrent3": "放電時段 3 放電電流(A)",
			"chargeStartTime4": "起始充電時間4",
			"chargeLongTime4": "充電時長4",
			"chargeTimeMaxSOC4": "充電時段 4 最大SOC（%）",
			"chargeTimeCurrent4": "充電時段 4 充電電流(A)",
			"dischargeStartTime4": "起始放電時間4",
			"dischargeLongTime4": "放電時長4",
			"dischargeTimeMaxSOC4": "放電時段 4 最小SOC（%）",
			"dischargeTimeCurrent4": "放電時段 4 放電電流(A)",
			"functionSwitchObj": {
				"val1": "智能模式",
				"val2": "定時模式",
				"val3": "自發自用模式"
			}
		},
		"type12": {
			"LEDSwitch": "LED開關",
			"LEDSpeed": "LED速度",
			"LEDEffect": "LED效果",
			"LEDBrightness": "LED亮度",
			"dataColor": "數據顏色表示",
			"LED1Color": "LED1 顏色",
			"LED2Color": "LED2 顏色",
			"LED3Color": "LED3 顏色",
			"buzzerAlarm": "蜂鳴器告警",
			"overLoadToBypass": "超載轉旁路",
			"lcdRestartPage": "LCD無操作恢復主界面",
			"overloadReset": "超載重啟",
			"overloadTemperature": "過溫重啟",
			"backLightOn": "LCD無操作背光",
			"inputSourceChangeAlarm": "輸入源變化告警",
			"faultCodeRecord": "通訊軟體故障記錄",
			"solarFeedToGird": "太陽能空閒並網",
			"switchOptions": "開關選項",
			"batteryParam": "電池參數",
			"outputParam": "輸出參數",
			"ratedOutputVol": "額定輸出電壓",
			"ratedOutputFreq": "額定輸出頻率",
			"inputVolRange": "輸入電壓範圍",
			"outPutSourcePriority": "輸出源優先",
			"batType": "電池類型",
			"batUnderVol": "電池截止電壓",
			"batBulkVol": "電池恒充電壓",
			"batFloatVol": "電池浮充電壓",
			"maxChargingCur": "最大充電電流",
			"maxAcChargingCur": "最大交流充電電流",
			"chargeSourcePriority": "充電器源優先順序",
			"batReChargeVol": "恢復充電電壓",
			"batFullRestoreDischargeVol": "恢復放電電壓",
			"solarSupplyPriority": "太陽能供電優先",
			"resetPvStorage": "重置PV統計數據",
			"countryRegulations": "國家法規",
			"acChargeStartTime": "交流充電器工作啟動時間",
			"acChargeEndTime": "交流充電器工作停止時間",
			"acSupplyStartTime": "交流電源的啟動時間",
			"acSupplyEndTime": "交流電源的停止時間",
			"restoreFactorySet": "恢復出廠設置",
			"parallelMode": "並機模式",
			"bulkChargeVol": "CV充電電壓",
			"batEquSet": "電池均充功能",
			"batEquTime": "電池均充時間（min）",
			"batEquDay": "電池均充間隔天數（d）",
			"batEquChargeVol": "電池均充電壓 (V)",
			"batEquTimeout": "電池均充超時時間(min)",
			"equInfoSet": "均衡信息設置",
			"val": {
				"slow": "慢",
				"medium": "中",
				"fast": "快",
				"low": "低",
				"high": "高",
				"powerCycle": "動力循環",
				"powerRotation": "動力輪替",
				"additionalPower": "動力追加",
				"powerUnchanged": "動力不變",
				"solarPowerInput": "太陽能輸入功率",
				"batteryCapacity": "電池容量百分比",
				"percentageLoad": "負載百分比",
				"energySource": "能量源",
				"batteryStatus": "電池充放電狀態",
				"blue": "藍色",
				"green": "綠色",
				"cyan": "青色",
				"pink": "粉色",
				"purple": "紫色",
				"orange": "橙色",
				"yellow": "黃色",
				"white": "白色",
				"inputOvlRange1": "APL 模式（90-280V）",
				"inputOvlRange2": "UPS 模式（170-280V）",
				"outputSource1": "太陽能->公用->電池",
				"outputSource2": "太陽能->電池->公用",
				"chargeSourcePriority1": "光伏優先",
				"chargeSourcePriority2": "光伏和市電",
				"chargeSourcePriority3": "僅光伏",
				"solarSupplyPriority1": "電池-負載-公用",
				"solarSupplyPriority2": "負載-電池-公用",
				"countryRegulations1": "印度",
				"countryRegulations2": "德國",
				"countryRegulations3": "南美洲",
				"parallelMode1": "無並機",
				"parallelMode2": "單相並機",
				"outPutSourcePriority1": "市電优先",
				"outPutSourcePriority2": "太陽能優先",
				"outPutSourcePriority3": "太陽能->電池->市電"
			}
		},
		"ops15": {
			"invSwitch": "逆變器開關",
			"workMode": "工作模式",
			"workMode1": "餽網優先",
			"workMode2": "離網模式",
			"workMode3": "自發自用",
			"invTime": "逆變器時間",
			"batType": "電池類型",
			"batType1": "無電池",
			"batType2": "通用鋰電池",
			"batType3": "鉛酸電池",
			"batType4": "鋰電池",
			"timingTime": "定時時間",
			"offGirdSoc": "過放SOC(%)",
			"optimizeRevenue": "優化收益"
		},
		"type19": {
			"totalActivePower": "有功功率",
			"offgridSettings": "並離網設置",
			"gridConnectedControlMode": "並網控制",
			"offGridControlMode": "離網控制",
			"offgrid": {
				"gridNet": "並網",
				"offNet": "離網"
			}
		},
		"type22": {
			"parameterScope": "參數範圍",
			"ACStartVoltage": "啟動電壓/頻率設置",
			"highVoltage": "AC啟動高壓",
			"lowVoltage": "AC啟動低壓",
			"pvStartLowVol": "PV啟動低壓",
			"acUnderVol1": "AC欠壓一階(V)",
			"acOverVol1": "AC過壓一階(V)",
			"acUnderVolTime1": "AC欠壓一階時間(s)",
			"acOverVolTime1": "AC過壓一階時間(s)",
			"acUnderVol2": "AC欠壓二階(V)",
			"acOverVol2": "AC過壓二階(V)",
			"acUnderVolTime2": "AC欠壓二階時間(s)",
			"acOverVolTime2": "AC過壓二階時間(s)",
			"startDelayTime": "啟動延時時間",
			"restartDelayTime": "重啟延時時間",
			"ACStartFrequency": "過欠壓/頻設置",
			"highFrequency": "AC啟動高頻",
			"lowFrequency": "AC啟動低頻",
			"acUnderFrequency1": "AC欠頻一階(Hz)",
			"acOverFrequency1": "AC過頻一階(Hz)",
			"acUnderFrequencyTime1": "AC欠頻一階時間(s)",
			"acOverFrequencyTime1": "AC過頻一階時間(s)",
			"acUnderFrequency2": "AC欠頻二階(Hz)",
			"acOverFrequency2": "AC過頻二階(Hz)",
			"acUnderFrequencyTime2": "AC欠頻二階時間（s）",
			"acOverFrequencyTime2": "AC過頻二階時間（s）",
			"systemSetting": "設備信息設置",
			"SNset": "序列號設置",
			"reactiveControlMode": "無功控制方式",
			"inputMode": "輸入模式",
			"startSolpe": "啟動斜率",
			"restartSolpe": "重啟斜率",
			"reactivePercentage": "無功百分比",
			"activePercentage": "有功百分比",
			"shutdown": "遠程開關",
			"functionSelect": "功能選擇",
			"manufacturer": "廠商",
			"setFunctionSelect": {
				"val1": "英威騰",
				"val2": "創維"
			},
			"setManufacturer": {
				"val1": "禁止",
				"val2": "使能防逆流"
			},
			"reactiveControlModeChoice": {
				"val1": "功率因數控制",
				"val2": "無功功率百分比控制",
				"val3": "QV曲線控制"
			},
			"inputModeChoice": {
				"val1": "輸入獨立模式",
				"val2": "輸入變臉模式",
				"val3": "DC Source 模式"
			}
		}
	},
	"title": {
		"login": "登錄",
		"vistorLogin": "遊客登錄",
		"createAccount": "創建帳戶",
		"retrievePassword": "找回密碼",
		"userAgreement": "用戶協議",
		"privacyPolicy": "隱私協議",
		"phone": "電話",
		"addPlant": "添加電站",
		"editPlant": "編輯電站",
		"accountInfo": "帳戶資訊",
		"changePassword": "修改密碼",
		"message": "消息",
		"systemMessage": "系統消息",
		"faultMessage": "故障消息",
		"deviceMessage": "設備消息",
		"aboutUs": "關於",
		"device": "設備",
		"datalogger": "採集器",
		"setting": "設置",
		"socketSetting": "插座設置",
		"addSchedule": "添加定時器",
		"editSchedule": "編輯定時器",
		"repetitionTime": "重復時間",
		"addDatalogger": "添加設備",
		"connectWifi": "鏈接路由",
		"distributionNetworkStatus": "配網狀態",
		"language": "語言",
		"selectCountry": "選擇國家",
		"selectCity": "選擇城市"
	},
	"bar": {
		"home": "首頁",
		"energy": "分析",
		"plant": "電站",
		"user": "用戶",
		"endpoint": "終端",
		"mine": "我的",
		"my": "我的"
	},
	"button": {
		"logOut": "退出",
		"rememberMe": "記住賬號密碼",
		"forgetPassword": "忘記密碼",
		"register": "註冊",
		"ok": "確認",
		"send": "發送",
		"auto": "自動",
		"cancle": "取消",
		"yes": "是",
		"close": "關閉",
		"refresh": "刷新",
		"next": "下一步",
		"redistributionNetwork": "重新配網",
		"upload": "上傳",
		"confirm": "確認",
		"edit": "編輯",
		"delete": "刪除",
		"save": "保存",
		"allReady": "全部已讀",
		"selectAll": "全選",
		"deselectAll": "取消全選",
		"unsubscribe": "註銷賬戶",
		"later": "稍後",
		"upgrade": "更新",
		"unfollow": "取消關注",
		"add": "添加"
	},
	"subtitle": {
		"NeedMoreHelp": "更多幫助",
		"VisitSupportCenter": "瀏覽官網獲得更多幫助",
		"energyFlowDiagram": "能流圖",
		"selectTimezone": "選擇時區",
		"selectPlantType": "選擇電站類型",
		"selectInstallationDate": "選擇安裝日期",
		"selectCurrency": "選擇貨幣",
		"selectDateType": "選擇日期類型",
		"selectDeviceType": "選擇設備類型",
		"socketBluetooth": "插座藍牙",
		"log": "日誌",
		"all": "全部",
		"online": "在線",
		"offline": "離線",
		"normal": "正常",
		"abnormal": "異常",
		"on": "開",
		"off": "關",
		"todayEnergy": "今日電量",
		"totalEnergy": "總電量",
		"totalRevenue": "總收入",
		"plantImage": "電站圖片",
		"plantName": "電站名字",
		"plantType": "電站類型",
		"PVCapacity": "PV容量",
		"installationDate": "安裝日期",
		"plantAddress": "電站地址",
		"revenue": "收入",
		"timeZone": "時區",
		"updatePhone": "更新電話",
		"solar": "光伏",
		"grid": "電網",
		"battery": "電池",
		"home": "負載",
		"load": "負載",
		"socket": "插座",
		"today": "日",
		"day": "日",
		"month": "月",
		"year": "年",
		"total": "總",
		"time": "時間",
		"Co2": "二氧化碳",
		"coal": "煤",
		"deforestation": "保護森林",
		"parameter": "參數",
		"schedule": "計時器",
		"countdown": "倒計時",
		"socketSwitch": "插座開關",
		"currency": "貨幣",
		"energyPrice": "電價",
		"storagePlant": "儲能電站",
		"on-GridPlant": "并網電站",
		"photo": "頭像",
		"email": "郵箱",
		"account": "賬戶",
		"version": "版本",
		"officalWebsite": "官網",
		"SN": "序列號",
		"wifiSignal": "WiFi 信號",
		"lastSync": "最後同步",
		"switch": "開關",
		"happening": "發生中",
		"restored": "已恢復",
		"details": "详情",
		"warn": "告警",
		"fault": "故障",
		"status": "狀態",
		"level": "等級",
		"deviceType": "設備類型",
		"alarmStartTime": "告警開始時間",
		"alarmEndTime": "告警結束時間",
		"solution": "解決方法",
		"loginIn": "登錄中……",
		"checkVersion": "檢查更新",
		"versionUpgrade": "版本更新",
		"messageDetail": "消息詳情",
		"todayConsumption": "今日耗電量",
		"totalConsumption": "總耗電量",
		"dataloggerSN": "採集器序列號",
		"configurationGuidance": "配置指導",
		"guideVideo": "指導視頻",
		"manualInstuction": "指導手冊",
		"manualInstuction4G": "4G指導手冊",
		"manualInstuctionMasterControl": "主控指導手冊",
		"masterControlConfiguration": "主控配網",
		"defaultConfiguration": "默認配網",
		"findEmail": "郵箱找回",
		"findPhone": "手機找回",
		"family": "家庭",
		"agent": "代理商",
		"endUsers": "終端用戶",
		"deviceManagement": "設備管理",
		"deviceSN": "設備序列號",
		"firmwareVersion": "固件版本",
		"alias": "別名",
		"information": "信息",
		"domainName": "域名",
		"restartdatalogger": "重啟採集器",
		"value": "值",
		"customizable": "自定義",
		"ready": "讀取",
		"deviceDetails": "設備詳情",
		"startAddress": "起始寄存器地址",
		"endAddress": "終止寄存器地址",
		"settingParam": "設置參數",
		"tariffType": "電價類型",
		"fixed": "固定",
		"timeshare": "分時",
		"addTime": "添加時間",
		"notAllDay": "不是一整天",
		"dealer": "經銷商",
		"dealerNumber": "經銷商編號",
		"toggle": "切換",
		"environmentalBenefit": "環境效益",
		"basicParam": "基本參數",
		"realTimeParam": "實時參數",
		"export": "導出",
		"localDebug": "近端調試",
		"mode": "方式",
		"manuallyAdd": "手動添加"
	},
	"tip": {
		"noData": "暫無數據",
		"base": {
			"maxDistance": "最遠",
			"minDistance": "最近",
			"maxSize": "最大",
			"minSize": "最小"
		},
		"request": {
			"loading": "加載中",
			"netWork": "網絡連接失敗，請檢查網絡",
			"connectServerFail": "無法連接到服務器",
			"requestTimeout": "請求超時"
		},
		"validate": {
			"choiceCountry": "請先選擇一個國家",
			"country": "國家不能為空，請選擇國家",
			"timeZone": "時區不能為空，請選擇時區",
			"email": "郵箱格式錯誤，請輸入一個正確的郵箱",
			"verification": "請先發送驗證碼",
			"verificationError": "驗證碼錯誤，請輸入一個有效的驗證碼",
			"passwordFormTip": "請輸入一個6-20位由字母和數字组成的密碼",
			"passwordError": "密碼格式錯誤，請輸入一個6-20位由字母和數字组成的密碼",
			"passwordError2": "請輸入一個有效的密碼(密碼不應該包括任何特殊字符、符號和空格)",
			"validatePassword": "您輸入的兩個密碼不一致，請檢查後重新輸入",
			"confirmAgreement": "請您先閱讀並同意隱私協議",
			"plantName": "電站名字不能為空，請輸入電站名字",
			"plantType": "電站類型不能為空，請選擇電站類型",
			"pvCapacity": "太陽能電池容量不能為空，請輸入電池容量",
			"installationDate": "安裝日期不能為空，請選擇安裝日期",
			"city": "城市不能為空，請選擇城市",
			"incomeunit": "貨幣不能為空，請選擇貨幣",
			"energyPrice": "電價不能為空，請輸入電價",
			"firstChoiceCountry": "請先選擇一個國家",
			"oldPassword": "舊密碼不能為空，請輸入舊密碼",
			"newPassword": "新密碼不能為空，請輸入新密碼",
			"confirmPassword": "確認密碼不能為空，請確認密碼",
			"passwordNotMatch": "兩次密碼輸入不一致，請檢查確認後提交",
			"SN1": "序列號不能為空",
			"SN2": "序列號必須是10位",
			"noSocketTimer": "當前沒有插座定時器，請點擊添加",
			"cameraPermission": "App需要相機權限，以便用於掃碼添加採集器",
			"searchingState": "正在搜索藍牙中，請勿重複點擊",
			"notInTheScope": "輸入參數不在規定範圍內",
			"enterDecimal": "必須輸入{num}位小數或整數",
			"integer": "必須是整數",
			"phone": "手機號僅支持中國大陸，其它請使用郵箱找回",
			"can`tEmpty": "{param}不能為空",
			"dealerSope": "經銷商編號範圍是6~8位數字",
			"firstChoicePlant": "請先選擇電站"
		},
		"log": {
			"permissionOpen": "確保定位和藍牙權限已開啟",
			"openBluetoothSystem": "打開藍牙設置",
			"openIosBtSystem": "請重啟藍牙開關",
			"openLocationSystem": "打開定位設置",
			"bluetoothAuthorization": "APP藍牙授權未開啟",
			"locationAuthorization": "APP定位授權未開啟",
			"searchSurroundingDevicesPermission": "搜索附近設備未授權",
			"bluetoothNotPermission": "藍牙未開啟",
			"locationNotPermission": "定位未開啟",
			"bluetoothPermission": "藍牙已開啟",
			"locationPermission": "定位已開啟",
			"bluetoothException": "藍牙異常斷開，請確認設備藍牙石已開啟，然後重新連接",
			"listeningFailure": "監聽特征值變化失敗，請重新連接藍牙",
			"communicationException": "藍牙通訊異常，請重新連接藍牙",
			"reconnect": "藍牙重新連接",
			"connectFail": "藍牙連接失敗",
			"connect": "連接藍牙",
			"bluetoothService": "請開啟藍牙服務",
			"locationService": "請開啟定位服務",
			"notsupport": "當前平台支持調用藍牙功能",
			"locationNotTurnOn": "定位未開啟，無法搜索藍牙",
			"init": "藍牙初始化",
			"searching": "正在搜索周圍藍牙",
			"searchFail": "周圍沒有查詢到設備，請確保設備藍牙已開啟，然後點擊刷新",
			"stopSearch": "停止搜索周圍藍牙",
			"stopSearchFail": "停止搜索周圍藍牙失敗",
			"connectSuccess": "藍牙連接成功",
			"setMTUsSuccess": "設置最大傳輸單元成功",
			"setMTUsFail": "設置最大傳輸單元失敗",
			"closeSuccess": "已斷開藍牙連接",
			"closeFail": "斷開藍牙連接失敗",
			"wifiLoadFail": "WiFi列表加載失敗，請手動輸入",
			"bluetoothAbnormal": "藍牙連接異常，正在嘗試重新連接",
			"getBluetoothServiceSuccess": "獲取藍牙設備服務成功",
			"getBluetoothServiceFail": "獲取藍牙設備服務失敗",
			"getFeatureValuesSucess": "已成功獲取藍牙設備指定服務中的所有特征值",
			"getFeatureValuesFail": "獲取藍牙設備指定服務中的所有特征值失敗",
			"subscriptionEigenvalueSuccess": "已啟用低功耗藍牙設備特征值變化時的notify功能，訂閱特征值",
			"subscriptionEigenvalueFail": "啟用低功耗藍牙設備特征值變化時的notify功能，訂閱特征值失敗",
			"monitoringSuccess": "已成功監聽低功耗藍牙設備的特征值變化事件",
			"monitoringFail": "監聽低功耗藍牙設備的特征值變化事件失敗",
			"CRC": "CRC校驗失敗",
			"queryWiFi": "已成功查詢WiFi列表",
			"queryWiFiState": "已成功下發WiFi列表查詢指令",
			"wifiConenct": "正在查詢路由連接狀態",
			"wifiFail": "請檢查路由器賬號和密碼並嘗試重新連接",
			"connectWiFiSuccess": "路由器連接成功",
			"connectWiFiFail": "路由器連接失敗",
			"routerAccount": "路由器賬號錯誤",
			"routerPassword": "路由器密碼錯誤",
			"gettingIP": "正在獲取路由器IP",
			"getIPFail": "獲取路由器IP失敗",
			"queryServerState": "已成功下發查詢設備與服務器交互狀態指令",
			"server03": "有03數據交互",
			"server04": "有04數據交互",
			"heartbeat": "有心跳數據交互",
			"noInteraction": "沒有數據交互",
			"bluetoothCommunication": "已打通藍牙通訊",
			"connectWiFi": "連接WiFi",
			"getWiFiList": "正在查詢WiFi列表",
			"wifiName": "WiFi名字不能為空，請選擇或輸入2.4WiFi",
			"wifiPasword": "WiFi不能為空，請輸入密碼",
			"wifiPasswordError": "WiFi密碼不能包含特殊字符(ǎǐò•♢∝⌒℃℉ etc.)",
			"masterControlId": "主控ID不能為空，請輸入ID"
		},
		"addDatalogger": {
			"noSearchDevice": "無法查詢到設備，請確保設備藍牙已開啟",
			"searchAroundBluetooth": "周圍可配網藍牙",
			"scan": "掃描二維碼或在二維碼下方手動輸入數據記錄儀序列號。",
			"connectBluetoothFail": "藍牙連接失敗，請掃描二維碼或在二維碼下方手動輸入採集器序列號後再次連接。"
		},
		"connectWifi": {
			"tip1": "配置設備加入網絡",
			"tip": "請選擇2.4G WiFi",
			"devicePower": "設備功率",
			"enterSSID": "請輸入路由器賬號",
			"enterPassword": "請輸入路由器密碼",
			"masterId": "請輸入主控Id",
			"commandSetFail": "命令設置失敗，請重新連接採集器"
		},
		"configurationState": {
			"connecting": "連接中",
			"connectFail": "連接失敗",
			"connectSuccess": "連接成功",
			"wait": "請稍等",
			"addDeviceSuccess": "添加設備成功"
		},
		"home": {
			"today": "日發電量",
			"month": "月發電量",
			"year": "年發電量",
			"total": "總發電量",
			"homePower": "家庭總負載",
			"socketPower": "插座負載",
			"off-gridPower": "離網負載",
			"acPower": "市電功率",
			"on-gridPower": "並網有功功率",
			"others": "其它",
			"latestVersion": "已是最新版本",
			"frontEndLoad": "前端負載",
			"backEndLoad": "後端負載"
		},
		"energy": {
			"choiceDate": "當前的數據只到",
			"explain": "說明",
			"tip1": {
				"load": "負載設備所消耗的能源",
				"solar": "將太陽能轉化為電能，用於電池充電或負載供電的能源",
				"grid": "累計給電網出售的能源",
				"battery": "累計給電池充電的電能能源"
			},
			"tip2": "=光伏能源*電價",
			"tip3": {
				"title": "社會貢獻計算標準",
				"title2": "1kWh等於:",
				"Co2": "減少二氧化碳排放量：",
				"coal": "節約使用標准煤：",
				"deforestation": "保護樹木："
			}
		},
		"plant": {
			"comprehensive": "綜合排序",
			"totalEnergyMax": "總電量最高",
			"totalEnergyMin": "總電量最低",
			"realTimePower": "實時功率",
			"pvcapacityMax": "pv容量最高",
			"pvcapacityMin": "pv容量最低",
			"installationDateMax": "裝機日期最遠",
			"installationDateMin": "裝機日期最近",
			"paramChoice": "參數選擇",
			"filter": "篩選",
			"addVisitor": "添加訪客",
			"tip": "提示",
			"visitorTip": "只能分享給已註冊的用戶",
			"name": "名字",
			"permission": "權限",
			"read-write": "讀寫",
			"onlyread": "只讀",
			"visitor": "訪客",
			"unfollow": "你確定要取消對此電站的關注？"
		},
		"device": {
			"power": "功率",
			"type": "類型",
			"updateTime": "更新時間",
			"powerOnTime": "上電時間",
			"todayEnergy": "今日電量",
			"signal": "信號強度"
		},
		"delDevice": "你確定要刪除這個{device}嗎?",
		"currentNoDevice": "當前沒有設備",
		"currentNoDatalog": "當前沒有採集器",
		"messageInfo": {
			"storage": "儲能",
			"inverter": "逆變器",
			"microInverter": "微逆"
		},
		"ops": {
			"setDomainName": "你確定要設置這個域名嗎？",
			"restartDatalogger": "你確定要重啟採集器嗎？"
		},
		"opsLog": {
			"addUser": "添加終端用戶",
			"bindSuccess": "綁定成功",
			"selectDate": "選擇日期",
			"start": "開始",
			"end": "結束",
			"upgrade": "升級",
			"operation": "操作",
			"communication": "通訊",
			"type": "類型",
			"content": "內容",
			"control": "控制器",
			"command": "命令類型",
			"reset": "重置",
			"serverTime": "服務器時間",
			"inverter": "間隔",
			"bindAgent": "綁定經銷商",
			"deleteDeviceSN": "請確認是否刪除？",
			"recordLog": "需要記錄日誌的設備"
		},
		"userLocation": "用戶所在地",
		"setting": {
			"productModelNumberRelevancyOn-gridPower": "併網有功功率不能大於所選產品型號最大功率"
		}
	},
	"productEquipment": {
		"productModelNumber": "產品型號",
		"warnMessage": "警告消息",
		"customInstruction": "自定義指令",
		"firmwareUpdate": "固件升級",
		"historicalData": "歷史數據",
		"linkageInformation": "聯繫信息",
		"batchRead":"批量讀取",
		"batchSetting":"批量設置",
		"protocolDirectives":"協定指令",
		"basicSettings":"基礎設置",
		"otherSettings":"其他設置",
	},
	"systemAuthority": {
		"systemSettings": '系統設置',
		"authorityManagement": '權限管理',
		"accountManagement": "角色管理",
		"Disabled":"禁用",
		"enable":"啓用"
	},
	"org":{
		"ChangeSupplier":'更改供應商'
	}
}
