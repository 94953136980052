import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from "./utils/request";
import * as echarts from 'echarts'

import store from './store'
import ElementUI from 'element-ui'
import md5 from 'md5'
import 'element-ui/lib/theme-chalk/index.css'
// element-ui-table-插件，内容列宽自适应
import AFTableColumn from 'af-table-column'
import adaptive from './assets/el-table/'
// CSS
import "@/assets/fonts/css.css";
// 权限路由拦截
import './permission' // permission control
// 公共样式
import "@/styles/index.scss";
// 引入icon
import './assets/icon/iconfont.css'
// 国际化
import i18n from './Language/index'
// 引入vue-fragment
import Fragment from 'vue-fragment'
// 引入匹配规则
import { yl } from "@/utils/matching.js";
Vue.use(Fragment.Plugin)

Vue.use(AFTableColumn)
Vue.config.productionTip = false
Vue.prototype.$request = request
Vue.prototype.$md5 = md5
Vue.prototype.$echarts = echarts
Vue.use(adaptive)

Vue.use(ElementUI, {
  fallbackLocale: 'en-US', //如果没有找到要显示的语言，则默认显示 ‘en’
  i18n: (key, value) => i18n.t(key, value)
})
/**
 * @description: 全局指令
 */
import directive from "@/directive/index.js";
Vue.use(directive)
/**
 * @description: 全局组件
 */
require('@/utils/component.js')
// 自定义表单组件
require('@/utils/funcform.js')

Vue.use(yl)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
