/*
 * @Author: lqj
 * @Desc: 指令注册
 * @Date: 2024-08-08 16:42:31
 * @LastEditors: lqj
 * @LastEditTime: 2024-08-27 11:39:18
 */
import removeAria from "./moudle/removeAria"
import copy from "./moudle/copy"
import inputNumber from "./moudle/inputNumber"
import addWaterMarker from "./moudle/watermark"
import trim from "./moudle/teim"
import permission from "./moudle/permission"
const directives = {
  removeAria,
  copy,
  inputNumber,
  addWaterMarker,
  trim,
  permission
}
let install = (Vue) => {
  Object.keys(directives).forEach((key) => {
    Vue.directive(key, directives[key]);
  });
}

export default {
  install
};