export default {
	"language": {
		"name": "简体中文"
	},
  product:{
    PleaseEnter:'请输入产品名称',
    PleaseEnterTheProductSerialNumber:'请输入产品序列号',
    PleaseEnterSubmodelNumber:'请输入子型号',
    PleaseenterTheSubmodelSerialNumber:'请输入子型号序列号',
    PleaseSelectTheDeviceType: '请选择设备类型',
    PleaseSelectDevelopmentType: '请选择研发类型',

    management:'产品管理',
    name:'产品名称',
    reset: "重置",
    search: "搜索",
    ApplicationList:'应用列表',
    newProduct:'新建产品',
    editName:'编辑产品',
    DetailSetting:'详情设置',
    delete: "删除",
    serialNumber:'产品序列号',
    deviceType:'设备类型',
    RandDtype:'研发类型',
    subType:'子型号',
    subTypeNumber:'子序列号',
    updateTime:'更新时间',
    operation: "操作",

    deviceList:'设备清单(出货日志)',
    parameterManagement: "参数管理",
    updataManagement: "升级管理",

    ParentDevice:'父设备',
    AssociatedPowerStationState:'关联电站状态',
    ImportDeviceList:'导入设备清单',
    PlantDetails:'电站详情',
    
    OwningPowerStation:'所属电站',
    
    parameterName:'参数名称',
    ParameterClass:'参数类别',
    ParameterSettingType:'参数设置类型',
    ParameterOperationType:'参数操作类型',
    ImportParameter:'导入参数',
    OwningUpperLevelParameterCategory:'所属上级参数分类',
    WhetherYoSupportBatchRead:'是否支持批量读',
    value:'值',
    scope:'范围',
    unit:'单位',
    explain:'说明',
    remark:'备注',

    fileName:'文件名',
    fileAddress:'文件地址',
    versionNumber:'版本号',
    ProductOfOwnership: '所属产品',

    ProductDetailsSettings:'阳台1.0 - 产品详情设置',
    QueryResultList:'查询结果列表',
    AddFirmware:'添加固件',
    NewParameter:'新增参数',

    datalog: "采集器",
    Inverter: "逆变器",
    storage: "储能",
    battery: "电池",
    socket: "插座",

    DownloadTemplate:'下载模板',
    nation:'国家',
    agent:'代理商',
    expirationDate:'保质期',
    UpdateTime:'更新时间',
    LastWeek:'最近一周',
    LastMonth:'最近一个月',
    LastThreeMonths:'最近三个月',

    parameterType:'参数类型',
    ProtocolAddress:'参数协议中的地址',
    stepSize:'步长',
    PleaseEnterAParameterName:'请输入参数名称',
    parameterCoding:'参数编码',
    PleaseEnterTheParameterCode:'请输入参数编码',
    ParameterDataType:'参数数据类型',
    PleaseEnterTheAddressInTheParameterProtocol:'请输入参数协议中的地址',
    blockEncoding:'分组编码',
    PleaseEnterABlockCode:'请输入分组编码',
    GroupName:'分组名称',
    PleaseEnterAGroupName:'请输入分组名称',
    PleaseEnterTheUnit:'请输入单位',
    decimalPoint:'小数点',
    PleaseEnterTheDecimalPoint:'请输入小数点',
    leastValue:'最小值',
    PleaseEnterTheUnit:'请输入最小值',
    maximumValue:'最大值',
    PleaseEnterTheMaximumValue:'请输入最大值',
    PleaseEnterTheStepSize:'请输入步长',
    addEquipment:'新增设备',
    equipment:'设备',
    remove:'移除',
    sort:'排序',
    PleaseEnterTheOrder:'请输入排序',
    PleaseEnterTheParameterSettingType:'请输入参数设置类型',

    versionNumber:'版本号',
    ProductOfOwnership:'所属产品',
    PleaseSelectAFolderPath:'请选择文件夹路径',
    ReturnToSuperior:'返回上级',
    PleaseEnterTheVersionNumber:'请输入版本号',
    firmwareSpecification:'固件说明',
    PleaseEnterFirmwareDescription:'请输入固件说明',
    FileName:'文件名',
    DeviceType:'设备类型',
    FileAddress:'文件地址',
    time:'服务时间',
  },
	"sidebar": {
		"home": "数据概览",
		"endbenutzer": "用户管理",
		"plant": "场景管理",
		"plant_plant": "电站管理",
		"device": "设备管理",
		"datalog": "采集器列表",
		"inverter": "逆变器列表",
		"sotrage": "储能列表",
		"socket": "插座列表",
		"battery": "电池列表",
		"agent": "代理商管理",
		"systematicSupply": "出货日志",
		"firmware": "固件列表",
		"firmware_up": "固件上传",
		"firmware_log": "升级记录",
		"system": "系统设置",
		"system_datalog_log": "采集器日志",
		"system_log": "操作日志",
		"system_tongxun": "通讯日志",
		"system_app": "App 版本",
		"personal": "用户信息",
		"logout": "退出账户",
		"organizationalManagement":"组织管理"
		
	},
	"login": {
		"email": "邮箱",
		"password": "密码",
		"remember": "记住邮箱和密码",
		"enteremail": "请输入邮箱",
		"enterpassword": "请输入密码"
	},
	"home": {
		"echarts1": {
			"title": "注册用户",
			"subhead": "今日新增/用户总数"
		},
		"echarts2": {
			"title": "登录用户",
			"subhead": "今日登录/用户总数"
		},
		"echarts3": {
			"title": "添加代理商",
			"subhead": "今日登录/代理商总数"
		},
		"echarts4": {
			"title": "添加场景",
			"subhead": "新增场景/场景总数",
			"title_plant": "添加电站",
			"subhead_plant": "新增电站/电站总数"
		},
		"echarts5": {
			"title": "添加采集器",
			"subhead": "新增采集器/采集器总数"
		},
		"echarts6": {
			"title": "添加设备",
			"subhead": "新增设备/设备总数"
		}
	},
	"End_user": {
		"input_email": "请输入用户邮箱",
		"btn_search": "搜索",
		"btn_add": "添加",
		"btn_delete": "删除",
		"btn_binding": "绑定",
		"add_station": "添加场景",
		"lable_StationName": "场景名字",
		"lable_StationType": "场景类型",
		"lable_PVCapacity": "太阳电池容量",
		"lable_InstalltionDate": "安装日期",
		"lable_Country": "国家",
		"lable_City": "城市",
		"lable_Address": "地址",
		"lable_TimeZone": "时区",
		"lable_Energyprice": "能源单价",
		"lable_IncomeUnit": "收入单位",
		"input_StationName": "请输入场景名字",
		"input_StationType": "请选择类型",
		"input_PVCapacity": "请输入电池容量",
		"input_InstalltionDate": "请选择安装日期",
		"input_Country": "请选择国家",
		"input_City": "请选择城市",
		"input_Address": "请输入地址",
		"input_TimeZone": "请选择时区",
		"input_Energyprice": "请输入单价",
		"input_IncomeUnit": "选择收入单位",
		"max_20": "字符最大不能超过20个",
		"max_5": "字符最大不能超过5个",
		"max_8": "字符最大不能超过8个",
		"format": "请输入正确的格式",
		"close": "关闭",
		"submit": "提交",
		"title_delete": "删除？",
		"no": "否",
		"yes": "是",
		"Bingding_Agent": "绑定代理商",
		"Agent_Code": "代理商:",
		"please_select": "请选择"
	},
	"plant": {
		"input_email": "请输入用户邮箱",
		"search": "搜索",
		"edit": "编辑",
		"delete": "删除",
		"edit_plant": "编辑场景",
		"input_station": "请输入场景名字",
		"lable_StationName": "场景名字",
		"lable_StationType": "场景类型",
		"lable_PVCapacity": "太阳电池容量",
		"lable_InstalltionDate": "安装日期",
		"lable_Country": "国家",
		"lable_City": "城市",
		"lable_Address": "地址",
		"lable_TimeZone": "时区",
		"lable_Energyprice": "能源单价",
		"lable_IncomeUnit": "收入单位",
		"input_StationName": "请输入场景名字",
		"input_StationType": "请选择类型",
		"input_PVCapacity": "请输入电池容量",
		"input_InstalltionDate": "请选择安装日期",
		"input_Country": "请选择国家",
		"input_City": "请选择城市",
		"input_Address": "请输入地址",
		"input_TimeZone": "请选择时区",
		"input_Energyprice": "请输入单价",
		"input_IncomeUnit": "选择收入单位",
		"max_20": "字符最大不能超过20个",
		"max_5": "字符最大不能超过5个",
		"max_8": "字符最大不能超过8个",
		"format": "请输入正确的格式",
		"close": "关闭",
		"submit": "提交",
		"title_delete": "删除？",
		"no": "否",
		"yes": "是"
	},
	"datalog": {
		"email": "请输入邮箱",
		"datalogSn": "请输入采集器序列号",
		"search": "搜索",
		"Version": "版本号",
		"setting": "设置",
		"upgrade": "升级",
		"delete": "删除",
		"title_datalog": "采集器设置",
		"span_info": "信息",
		"span_datalog": "采集器序列号:",
		"span_name": "别名:",
		"span_time": "更新时间:",
		"span_firmware": "信号/固件版本:",
		"span_order": "命令",
		"span_settingdns": "设置域名",
		"span_inputsetting": "请输入域名",
		"span_upgrade": "升级采集器",
		"span_reset": "重启采集器",
		"span_setup": "高级设置",
		"span_param": "参数",
		"span_value": "值",
		"btn_setup": "高级设置",
		"btn_setting": "设置",
		"btn_settingdns": "设置域名",
		"btn_upgrade": "升级",
		"btn_reset": "重启",
		"btn_read": "读取",
		"btn_close": "取消",
		"methods_version": "检测版本更新?",
		"no": "否",
		"yes": "是",
		"methods_delete": "删除?",
		"methods_rereset": "重启?",
		"methodes_inputDns": "请输入域名",
		"methodes_DNS": "是否设置此域名"
	},
	"sotrage": {
		"select": "请选择",
		"email": "请输入邮箱",
		"datalogSn": "请输入采集器序列号",
		"deviceSn": "请输入设备序列号",
		"search": "搜索",
		"sotrage": "储能设置"
	},
	"device": {
		"select": "请选择",
		"email": "请输入邮箱",
		"datalogSn": "请输入采集器序列号",
		"deviceSn": "请输入设备序列号",
		"search": "搜索",
		"title": "逆变器设置",
		"setting": "设置",
		"setting_inverte": "设置逆变器参数",
		"input_start_address": "起始寄存器地址",
		"input_ent_address": "终止寄存器地址",
		"input_setting_value": "请输入设置值",
		"read_data": "读取寄存器数据:",
		"input_start_read": "起始寄存器地址",
		"input_end_read": "终止寄存器地址",
		"btn_address": "设置地址",
		"btn_value": "设置参数",
		"btn_read": "读取",
		"btn_close": "返回",
		"selet_data": "选择日期"
	},
	"battery": {
		"select": "请选择",
		"email": "请输入邮箱",
		"datalogSn": "请输入采集器序列号",
		"deviceSn": "请输入设备序列号",
		"search": "搜索"
	},
	"socket": {
		"title": "插座设置",
		"setting": "设置",
		"span": "设置了多个寄存器地址，值也要设置多个。 设置值中间要用 , 隔开，示例（1，2，3）",
		"setting_device": "设置逆变器参数：",
		"startaddress": "起始寄存器地址",
		"stopaddress": "终止寄存器地址",
		"value": "请输入设置值",
		"readdata": "读取寄存器数据：",
		"setting_address": "设置地址",
		"setting_value": "设置参数",
		"read": "读取",
		"return": "返回"
	},
	"agent": {
		"close": "取消",
		"submit": "提交",
		"input_email": "请输入邮箱",
		"search": "搜索",
		"add_agent": "添加代理商",
		"import_device": "导入设备",
		"edit": "编辑",
		"title": "添加代理商",
		"email": "邮箱",
		"password": "密码",
		"phone": "手机号码",
		"company": "公司",
		"language": "语言",
		"address": "地址",
		"input_password": "请输入密码",
		"input_phone": "请输入手机号",
		"input_company": "请选择公司",
		"input_language": "请输入语言",
		"input_address": "请输入地址",
		"relus_email": "请输入有效的电子邮件地址",
		"title_edit": "编辑代理商",
		"title_device": "上传",
		"span": "示例: Excle 表格",
		"btn_upload": "上传",
		"delete": "删除",
		"agent_delete": "删除代理商?"
	},
	"ship": {
		"starttime": "开始时间",
		"endtime": "结束时间",
		"deviceSn": "请输入设备序列号",
		"select": "请选择公司",
		"search": "搜索"
	},
	"Firmware": {
		"file_name": "请输入文件名字",
		"search": "搜索",
		"add": "添加",
		"delete": "删除",
		"title": "固件上传",
		"select_folder": "选择文件夹:",
		"select_folder_type": "请选择文件夹类型",
		"craete_folder": "创建一个新的文件夹",
		"folder_path": "文件夹路径：",
		"folder_name": "文件名:",
		"input_folder_name": "输入文件名称",
		"create": "创建文件夹",
		"listed_files": "文件列表：",
		"import_folder": "导入文件",
		"uplad_folder": "上传文件",
		"close": "返回"
	},
	"Upgrade": {
		"starttime": "开始时间",
		"endtime": "结束时间",
		"deviceSn": "请输入设备序列号",
		"type": "请选择类型",
		"search": "搜索",
		"datalog": "采集器",
		"Inverter": "逆变器",
		"Control": "控制器"
	},
	"datalogs_logs": {
		"starttime": "开始时间",
		"endtime": "结束时间",
		"deviceSn": "请输入设备序列号",
		"Version": "请输入版本",
		"search": "搜索"
	},
	"Logs": {
		"starttime": "开始时间",
		"endtime": "结束时间",
		"deviceSn": "请输入设备序列号",
		"type": "请选择类型",
		"search": "搜索"
	},
	"Special": {
		"starttime": "开始时间",
		"endtime": "结束时间",
		"deviceSn": "请输入设备序列号",
		"datalogSn": "请输入采集器序列号",
		"type": "请选择类型",
		"search": "搜索",
		"save": "保存",
		"Examples": "示例：(2305210230,2305210139)"
	},
	"App": {
		"System_Config": "系统配置",
		"APP_Version": "App版本发布",
		"Android_Version": "安卓版本:",
		"IOS_Version": "IOS版本:",
		"APP_Type": "App类型:",
		"New_Version": "新版本",
		"update_content": "更新内容描述:(用“/n”来划分)",
		"Whether_update": "是否强制更新：",
		"no": "否",
		"yes": "是",
		"submit": "提交"
	},
	"Tips": {
		"app_submit": "确定修改信息?",
		"firmware_delete": "确认删除?",
		"firmware_deleteCDN": "删除CDN中的文件?",
		"yes": "是",
		"no": "否"
	},
	"global": {
		"DataDetails": "数据详情",
		"device": "逆变器设置",
		"device_data": "设置逆变器参数:",
		"storage": "储能设置",
		"storage_data": "设置储能参数",
		"battery": "电池设置",
		"battery_data": "设置电池参数",
		"socket": "插座设置",
		"socket_data": "设置插座参数"
	},
	"btn": {
		"setting_address": "设置地址",
		"close": "返回",
		"submit": "提交",
		"Setting_parameters": "设置参数",
		"Reading": "读取",
		"search": "搜索",
		"add": "添加",
		"import": "导入",
		"download": "下载示例",
		"edit": "编辑",
		"login": "登录",
		"export": "导出"
	},
	"form": {
		"addShip": "添加出货设备",
		"editShip": "编辑出货设备",
		"import": "导入设备信息",
		"shipSN": "出货序列号",
		"agent": "代理商",
		"networkCall": "是否执行配网回调",
		"warrantyPeriod": "质保时间",
		"shiptime": "出货时间",
		"shiptype": "出货类型",
		"classify": "分类",
		"file": "将文件拖到此处或者点击上传",
		"edit_plant": "编辑场景",
		"input_station": "请输入场景名字",
		"lable_StationName": "场景名字",
		"lable_StationType": "场景类型",
		"input_StationName": "请输入场景名字",
		"edit_plant_plant": "编辑电站",
		"input_station_plant": "请输入电站名字",
		"lable_StationName_plant": "电站名字",
		"lable_StationType_plant": "电站类型",
		"input_StationName_plant": "请输入电站名字"
	},
	"placeholder": {
		"select": "请选择",
		"choiceCountry": "请选择国家",
		"choiceTimezone": "请选择时区",
		"enterEmail": "请输入邮箱",
		"enterPhone": "请输入手机号",
		"verificationCode": "请输入验证码",
		"enterPassword": "请输入6-20位密码",
		"confirmPassword": "请确认密码",
		"readAndAgree": "我已经阅读并同意",
		"search": "搜索",
		"searchPlantName": "搜索电站名字",
		"searchDeviceName": "搜索设备名字",
		"searchDataloggerName": "搜索采集器名字",
		"enterPlantName": "请输入电站名字",
		"enterPlantType": "请输入电站类型",
		"enterPVCapacity": "请输入PV容量",
		"choiceInstallationDate": "请选择安装日期",
		"enterPlantAddress": "请输入电站地址",
		"enterOldPassword": "请输入旧密码",
		"enterNewPassword": "请输入新密码",
		"enterConfirmPassword": "请确认新密码",
		"enter": "请输入{scope}",
		"country": "国家",
		"city": "城市",
		"scanCodeAddPlant": "请扫码添加电站!",
		"currentNoPlant": "当前没有电站，请点击添加!",
		"selectRepeatTime": "请选择重复时间",
		"enterDatalogger": "请输入采集器序列号",
		"enterDeviceSN": "请输入设备序列号"
	},
	"setting": {
		"general": {
			"needGreat": "需要大于",
			"needSmall": "需要小于",
			"gapScope": "输入参数的间隔是",
			"valScope": "输入参数的范围是"
		},
		"socket": {
			"switchStatus": "插座开关",
			"repetition": "重复时间",
			"enableOrNot": "是否启用",
			"monday": "周一",
			"tuesday": "周二",
			"wednesday": "周三",
			"thursday": "周四",
			"friday": "周五",
			"saturday": "周六",
			"sunday": "周日"
		},
		"type3": {
			"dischargeScheduleMode": "定时放电模式",
			"timeDischarge": "定时放电",
			"workingDay": "工作日",
			"weekend": "周末",
			"pureLightControlMode": "纯光控模式",
			"stopDischarge": "SOC低端停止放电设定值",
			"chargeTransferDischarge": "SOC充满转放电设定值",
			"batterySOCSetting": "电池SOC设置",
			"lowBatteryAlam": "电池 SOC 低端告警设定值",
			"lowBatteryShutdown": "电池 SOC 低端停止放电设定值",
			"systemSetting": "系统设置",
			"systemWorkMode": "系统工作模式",
			"restoreFactory": "恢复出厂设置",
			"loadMode": "负载模式",
			"timingDischarge": "定时放电模式",
			"DCOutputNormallyClosed": "直流输出常闭模式",
			"DCOutputNormallyOpen": "直流输出常开模式",
			"ForcedPowerProtection": "强制保电模式",
			"mode": "模式",
			"startTime": "开始时间",
			"stopTime": "结束时间",
			"onlyAddThree": "最多添加21个定时器",
			"startCompareEnd": "定时器的开始时间只能小于结束时间",
			"confirmClose": "你确定要关机吗？"
		},
		"type6": {
			"countryCode": "国家编号",
			"poweradjppm": "逆变器功率调节速率",
			"enaINVTR": "远程开关机",
			"enaSysProtIF_selfCheck": "系统保护接口自检",
			"enaRestoretodefault": "恢复默认值",
			"UV_resopnseTime": "{num}级欠压响应时间",
			"UF_resopnseTime": "{num}级欠频响应时间",
			"OV_resopnseTime": "{num}级过压响应时间",
			"OF_resopnseTime": "{num}级过频响应时间",
			"open": "开启",
			"recover": "恢复",
			"noHave": "无",
			"activePowerSwitch": "有功功率开关",
			"activePowerPermillage": "有功功率千分比"
		},
		"type7": {
			"paramterSetting": "参数设置",
			"batteryVoltage": "蓄电池电压",
			"batteryCapacity": "蓄电池容量",
			"PVChargingCurrent": "光伏充电电流",
			"overVoltage": "超压电压",
			"chargingLimitVoltage": "充电限制电压",
			"increaseChargeReturnVoltage": "提升充电返回电压",
			"overDischargeVoltage": "过放电压",
			"stopchangeCurrent": "停止充电电流",
			"inverter485Address": "485地址",
			"passwordValue": "用户密码",
			"inputPassword": "密码输入",
			"recordFaultCodes": "记录故障码",
			"isOpen": "开关机控制",
			"equalizingChargeCmd": "立即充电均衡",
			"reset": "复位控制",
			"factory": "恢复出厂设置",
			"clearAlarm": "清除当前告警",
			"clearHistory": "清除历史记录",
			"generatorSwitchCmd": "发电机切换",
			"nowTimeStr": "当前时间",
			"setVal": {
				"reset": "复位",
				"recover": "恢复",
				"clearAlarm": "清除警告",
				"clearHistory": "清除历史",
				"toggle": "切换"
			}
		},
		"type8": {
			"systemSwitch": "系统开关"
		},
		"type10": {
			"modeSelect": "模式选择",
			"standbyMode": "备电模式",
			"PureOffGrid": "纯离网启动模式",
			"energyNet": "能量上网",
			"inverter0": "逆变补能量到本地负载",
			"inverter1": "逆变给电网发电",
			"inverter2": "逆变给本地负载带CT",
			"inverter3": "逆变给本地负载智能插座或者智能电表",
			"chargePriority": "充电优先级",
			"PVPriority": "光伏优先",
			"utilityPriority": "市电优先",
			"hybridModel": "混合模式",
			"PVOnly": "仅光伏",
			"batteryLowAlarm": "电池SOC低告警值",
			"batteryLowShutdown": "电池SOC低关机点",
			"batteryFull": "电池充饱SOC判断值",
			"ACCharging": "AC充电",
			"startTime": "起始时间",
			"endTime": "结束时间",
			"forcedDischarge": "强制放电",
			"phaseGridSide": "电网侧",
			"power": "相有功功率",
			"current": "相电流",
			"gridConnectionStandard": "并网标准",
			"baudRate": "485波特率",
			"disable": "失能",
			"enable": "使能",
			"ApprovalStandards": "认证标准",
			"antiRefluxPower": "防逆流功率",
			"antiRefluxSet": "防逆流使能",
			"batFullSocSet": "电池冲饱和判断值（%）",
			"auxSourceSet": "辅源开关",
			"timeSharingGridConnectedDischarge": "分时并网放电",
			"timeSharingGridConnection": "分时并网",
			"outputPower": "输出功率",
			"onlyAddThree": "最多添加{num}个定时器",
			"productModelNumber": "产品型号",
			"PleaseTurnOnThe_PCS_switchFirst":"请先打开 PCS 开关",
			"ThereIs_a_conflictInTheChargingAndDischargingTime":"充放电时间有冲突"
		},
		"type11": {
			"switchPCS": "PCS开关",
			"functionSwitch": "功能开关",
			"maxChargeVol": "最高充电电压（V）",
			"onGridMinDischargeVol": "并网最低放电电压（V）",
			"offGridOutputVol": "离网输出电压（V）",
			"maxChargeCur": "最高充电电流(A)",
			"onGridMaxDischargeCur": "并网最高放电电流(A)",
			"offGridMaxDisChargeCur": "离网最高放电电流(A)",
			"minSocBMS": "BMS最小SOC（%）",
			"minSocDischarge": "智能放电最小SOC（%）",
			"maxSocDischarge": "智能放电最大SOC（%）",
			"timeStr": "设备时间",
			"timerSetting": "定时器设置{num}",
			"chargeStartTime1": "起始充电时间1",
			"chargeLongTime1": "充电时长1",
			"chargeTimeMaxSOC1": "充电时段 1 最大SOC（%）",
			"chargeTimeCurrent1": "充电时段 1 充电电流(A)",
			"dischargeStartTime1": "起始放电时间1",
			"dischargeLongTime1": "放电时长1",
			"dischargeTimeMaxSOC1": "放电时段 1 最小SOC（%）",
			"dischargeTimeCurrent1": "放电时段 1 放电电流(A)",
			"chargeStartTime2": "起始充电时间2",
			"chargeLongTime2": "充电时长2",
			"chargeTimeMaxSOC2": "充电时段 2 最大SOC（%）",
			"chargeTimeCurrent2": "充电时段 2 充电电流(A)",
			"dischargeStartTime2": "起始放电时间2",
			"dischargeLongTime2": "放电时长2",
			"dischargeTimeMaxSOC2": "放电时段 2 最小SOC（%）",
			"dischargeTimeCurrent2": "放电时段 2 放电电流(A)",
			"chargeStartTime3": "起始充电时间3",
			"chargeLongTime3": "充电时长3",
			"chargeTimeMaxSOC3": "充电时段 3 最大SOC（%）",
			"chargeTimeCurrent3": "充电时段 3 充电电流(A)",
			"dischargeStartTime3": "起始放电时间3",
			"dischargeLongTime3": "放电时长3",
			"dischargeTimeMaxSOC3": "放电时段 3 最小SOC（%）",
			"dischargeTimeCurrent3": "放电时段 3 放电电流(A)",
			"chargeStartTime4": "起始充电时间4",
			"chargeLongTime4": "充电时长4",
			"chargeTimeMaxSOC4": "充电时段 4 最大SOC（%）",
			"chargeTimeCurrent4": "充电时段 4 充电电流(A)",
			"dischargeStartTime4": "起始放电时间4",
			"dischargeLongTime4": "放电时长4",
			"dischargeTimeMaxSOC4": "放电时段 4 最小SOC（%）",
			"dischargeTimeCurrent4": "放电时段 4 放电电流(A)",
			"functionSwitchObj": {
				"val1": "智能模式",
				"val2": "定时模式",
				"val3": "自发自用模式"
			}
		},
		"type12": {
			"LEDSwitch": "LED开关",
			"LEDSpeed": "LED速度",
			"LEDEffect": "LED效果",
			"LEDBrightness": "LED亮度",
			"dataColor": "数据颜色表示",
			"LED1Color": "LED1 颜色",
			"LED2Color": "LED2 颜色",
			"LED3Color": "LED3 颜色",
			"buzzerAlarm": "蜂鸣器告警",
			"overLoadToBypass": "过载转旁路",
			"lcdRestartPage": "LCD无操作恢复主界面",
			"overloadReset": "过载重启",
			"overloadTemperature": "过温重启",
			"backLightOn": "LCD无操作背光",
			"inputSourceChangeAlarm": "输入源变化告警",
			"faultCodeRecord": "通讯软件故障记录",
			"solarFeedToGird": "太阳能空闲并网",
			"switchOptions": "开关选项",
			"batteryParam": "电池参数",
			"outputParam": "输出参数",
			"ratedOutputVol": "额定输出电压",
			"ratedOutputFreq": "额定输出频率",
			"inputVolRange": "输入电压范围",
			"outPutSourcePriority": "输出源优先",
			"batType": "电池类型",
			"batUnderVol": "电池截止电压",
			"batBulkVol": "电池恒充电压",
			"batFloatVol": "电池浮充电压",
			"maxChargingCur": "最大充电电流",
			"maxAcChargingCur": "最大交流充电电流",
			"chargeSourcePriority": "充电器源优先级",
			"batReChargeVol": "恢复充电电压",
			"batFullRestoreDischargeVol": "恢复放电电压",
			"solarSupplyPriority": "太阳能供电优先",
			"resetPvStorage": "重置PV统计数据",
			"countryRegulations": "国家法规",
			"acChargeStartTime": "交流充电器工作启动时间",
			"acChargeEndTime": "交流充电器工作停止时间",
			"acSupplyStartTime": "交流电源的启动时间",
			"acSupplyEndTime": "交流电源的停止时间",
			"restoreFactorySet": "恢复出厂设置",
			"parallelMode": "并机模式",
			"bulkChargeVol": "CV充电电压",
			"batEquSet": "电池均充功能",
			"batEquTime": "电池均充时间（min）",
			"batEquDay": "电池均充间隔天数（d）",
			"batEquChargeVol": "电池均充电压 (V)",
			"batEquTimeout": "电池均充超时时间(min)",
			"equInfoSet": "均衡信息设置",
			"val": {
				"slow": "慢",
				"medium": "中",
				"fast": "快",
				"low": "低",
				"high": "高",
				"powerCycle": "动力循环",
				"powerRotation": "动力轮替",
				"additionalPower": "动力追加",
				"powerUnchanged": "动力不变",
				"solarPowerInput": "太阳能输入功率",
				"batteryCapacity": "电池容量百分比",
				"percentageLoad": "负载百分比",
				"energySource": "能量源",
				"batteryStatus": "电池充放电状态",
				"blue": "蓝色",
				"cyan": "青色",
				"green": "绿色",
				"pink": "粉色",
				"purple": "紫色",
				"orange": "橙色",
				"yellow": "黄色",
				"white": "白色",
				"inputOvlRange1": "APL 模式（90-280V）",
				"inputOvlRange2": "UPS 模式（170-280V）",
				"outputSource1": "太阳能->公用->电池",
				"outputSource2": "太阳能->电池->公用",
				"chargeSourcePriority1": "光伏优先",
				"chargeSourcePriority2": "光伏和市电",
				"chargeSourcePriority3": "仅光伏",
				"solarSupplyPriority1": "电池-负载-公用",
				"solarSupplyPriority2": "负载-电池-公用",
				"countryRegulations1": "印度",
				"countryRegulations2": "德国",
				"countryRegulations3": "南美洲",
				"parallelMode1": "无并机",
				"parallelMode2": "单相并机",
				"outPutSourcePriority1": "市电优先",
				"outPutSourcePriority2": "太阳能优先",
				"outPutSourcePriority3": "太阳能->电池->市电"
			}
		},
		"ops15": {
			"invSwitch": "逆变器开关",
			"workMode": "工作模式",
			"workMode1": "馈网优先",
			"workMode2": "离网模式",
			"workMode3": "自发自用",
			"invTime": "逆变器时间",
			"batType": "电池类型",
			"batType1": "无电池",
			"batType2": "通用锂电池",
			"batType3": "铅酸电池",
			"batType4": "锂电池",
			"timingTime": "定时时间",
			"offGirdSoc": "过放SOC（%）",
			"optimizeRevenue": "优化收益"
		},
		"type19": {
			"totalActivePower": "有功功率",
			"offgridSettings": "并离网设置",
			"gridConnectedControlMode": "并网控制",
			"offGridControlMode": "离网控制",
			"offgrid": {
				"gridNet": "并网",
				"offNet": "离网"
			}
		},
		"type22": {
			"parameterScope": "参数范围",
			"ACStartVoltage": "启动电压/频率设置",
			"highVoltage": "AC启动高压",
			"lowVoltage": "AC启动低压",
			"pvStartLowVol": "PV启动低压",
			"acUnderVol1": "AC欠压一阶(V)",
			"acOverVol1": "AC过压一阶(V)",
			"acUnderVolTime1": "AC欠压一阶时间(s)",
			"acOverVolTime1": "AC过压一阶时间(s)",
			"acUnderVol2": "AC欠压二阶(V)",
			"acOverVol2": "AC过压二阶(V)",
			"acUnderVolTime2": "AC欠压二阶时间(s)",
			"acOverVolTime2": "AC过压二阶时间(s)",
			"startDelayTime": "启动延时时间",
			"restartDelayTime": "重启延时时间",
			"ACStartFrequency": "过欠压/频设置",
			"highFrequency": "AC启动高频",
			"lowFrequency": "AC启动低频",
			"acUnderFrequency1": "AC欠频一阶(Hz)",
			"acOverFrequency1": "AC过频一阶(Hz)",
			"acUnderFrequencyTime1": "AC欠频一阶时间(s)",
			"acOverFrequencyTime1": "AC过频一阶时间(s)",
			"acUnderFrequency2": "AC欠频二阶(Hz)",
			"acOverFrequency2": "AC过频二阶(Hz)",
			"acUnderFrequencyTime2": "AC欠频二阶时间(s)",
			"acOverFrequencyTime2": "AC过频二阶时间(s)",
			"systemSetting": "设备信息设置",
			"SNset": "序列号设置",
			"reactiveControlMode": "无功控制方式",
			"inputMode": "输入模式",
			"startSolpe": "启动斜率",
			"restartSolpe": "重启斜率",
			"reactivePercentage": "无功百分比",
			"activePercentage": "有功百分比",
			"shutdown": "远程开关",
			"functionSelect": "功能选择",
			"manufacturer": "厂商",
			"setFunctionSelect": {
				"val1": "禁止",
				"val2": "使能防逆流"
			},
			"setManufacturer": {
				"val1": "英威腾",
				"val2": "创维"
			},
			"reactiveControlModeChoice": {
				"val1": "功率因数控制",
				"val2": "无功功率百分比控制",
				"val3": "QV曲线控制"
			},
			"inputModeChoice": {
				"val1": "输入独立模式",
				"val2": "输入并联模式",
				"val3": "DC Source 模式"
			}
		}
	},
	"title": {
		"login": "登录",
		"vistorLogin": "游客登录",
		"createAccount": "创建账户",
		"retrievePassword": "找回密码",
		"userAgreement": "用户协议",
		"privacyPolicy": "隐私协议",
		"phone": "电话",
		"addPlant": "添加电站",
		"editPlant": "编辑电站",
		"accountInfo": "账户信息",
		"changePassword": "修改密码",
		"message": "消息",
		"systemMessage": "系统消息",
		"faultMessage": "故障消息",
		"deviceMessage": "设备消息",
		"aboutUs": "关于",
		"device": "设备",
		"datalogger": "采集器",
		"setting": "设置",
		"socketSetting": "插座设置",
		"addSchedule": "添加定时器",
		"editSchedule": "编辑定时器",
		"repetitionTime": "重复时间",
		"addDatalogger": "添加设备",
		"connectWifi": "连接路由",
		"distributionNetworkStatus": "配网状态",
		"language": "语言",
		"selectCountry": "选择国家",
		"selectCity": "选择城市"
	},
	"bar": {
		"home": "首页",
		"energy": "分析",
		"plant": "电站",
		"user": "用户",
		"endpoint": "终端",
		"mine": "我的",
		"my": "我的"
	},
	"button": {
		"logOut": "退出",
		"rememberMe": "记住账号密码",
		"forgetPassword": "忘记密码",
		"register": "注册",
		"ok": "确认",
		"send": "发送",
		"auto": "自动",
		"cancle": "取消",
		"yes": "是",
		"close": "关闭",
		"refresh": "刷新",
		"next": "下一步",
		"redistributionNetwork": "重新配网",
		"upload": "上传",
		"confirm": "确认",
		"edit": "编辑",
		"delete": "删除",
		"save": "保存",
		"allReady": "全部已读",
		"selectAll": "全选",
		"deselectAll": "取消全选",
		"unsubscribe": "注销账户",
		"later": "稍后",
		"upgrade": "更新",
		"unfollow": "取消关注",
		"add": "添加"
	},
	"subtitle": {
		"NeedMoreHelp": "更多帮助",
		"VisitSupportCenter": "浏览官网获得更多帮助",
		"energyFlowDiagram": "能流图",
		"selectTimezone": "选择时区",
		"selectPlantType": "选择电站类型",
		"selectInstallationDate": "选择安装日期",
		"selectCurrency": "选择货币",
		"selectDateType": "选择日期类型",
		"selectDeviceType": "选择设备类型",
		"socketBluetooth": "插座蓝牙",
		"log": "日志",
		"all": "全部",
		"online": "在线",
		"offline": "离线",
		"normal": "正常",
		"abnormal": "异常",
		"on": "开",
		"off": "关",
		"todayEnergy": "今日电量",
		"totalEnergy": "总电量",
		"totalRevenue": "总收入",
		"plantImage": "电站图片",
		"plantName": "电站名字",
		"plantType": "电站类型",
		"PVCapacity": "PV容量",
		"installationDate": "安装日期",
		"plantAddress": "电站地址",
		"revenue": "收入",
		"timeZone": "时区",
		"updatePhone": "更新电话",
		"solar": "光伏",
		"grid": "电网",
		"battery": "电池",
		"home": "负载",
		"load": "负载",
		"socket": "插座",
		"today": "日",
		"day": "日",
		"month": "月",
		"year": "年",
		"total": "总",
		"time": "时间",
		"Co2": "二氧化碳",
		"coal": "煤",
		"deforestation": "保护森林",
		"parameter": "参数",
		"schedule": "定时器",
		"countdown": "倒计时",
		"socketSwitch": "插座开关",
		"currency": "货币",
		"energyPrice": "电价",
		"storagePlant": "储能电站",
		"on-GridPlant": "并网电站",
		"photo": "头像",
		"email": "邮箱",
		"account": "账户",
		"version": "版本",
		"officalWebsite": "官网",
		"SN": "序列号",
		"wifiSignal": "WiFi 信号",
		"lastSync": "最后同步",
		"switch": "开关",
		"happening": "发生中",
		"restored": "已恢复",
		"details": "详情",
		"warn": "告警",
		"fault": "故障",
		"status": "状态",
		"level": "等级",
		"deviceType": "设备类型",
		"alarmStartTime": "告警开始时间",
		"alarmEndTime": "告警结束时间",
		"solution": "解决方法",
		"loginIn": "登录中……",
		"checkVersion": "检查更新",
		"versionUpgrade": "版本更新",
		"messageDetail": "消息详情",
		"todayConsumption": "今日耗电量",
		"totalConsumption": "总耗电量",
		"dataloggerSN": "采集器序列号",
		"configurationGuidance": "配置指导",
		"guideVideo": "指导视频",
		"manualInstuction": "指导手册",
		"manualInstuction4G": "4G指导手册",
		"manualInstuctionMasterControl": "主控指导手册",
		"masterControlConfiguration": "主控配网",
		"defaultConfiguration": "默认配网",
		"findEmail": "邮箱找回",
		"findPhone": "手机找回",
		"family": "家庭",
		"agent": "代理商",
		"endUsers": "终端用户",
		"deviceManagement": "设备管理",
		"deviceSN": "设备序列号",
		"firmwareVersion": "固件版本",
		"alias": "别名",
		"information": "信息",
		"domainName": "域名",
		"restartdatalogger": "重启采集器",
		"value": "值",
		"customizable": "自定义",
		"ready": "读取",
		"deviceDetails": "设备详情",
		"startAddress": "起始寄存器地址",
		"endAddress": "终止寄存器地址",
		"settingParam": "设置参数",
		"tariffType": "电价类型",
		"fixed": "固定",
		"timeshare": "分时",
		"addTime": "添加时间",
		"notAllDay": "不是一整天",
		"dealer": "经销商",
		"dealerNumber": "经销商编号",
		"toggle": "切换",
		"environmentalBenefit": "环境效益",
		"basicParam": "基本参数",
		"realTimeParam": "实时参数",
		"export": "导出",
		"localDebug": "近端调试",
		"mode": "方式",
		"manuallyAdd": "手动添加"
	},
	"tip": {
		"noData": "暂无数据",
		"base": {
			"maxDistance": "最远",
			"minDistance": "最近",
			"maxSize": "最大",
			"minSize": "最小"
		},
		"request": {
			"loading": "加载中",
			"netWork": "网络连接失败，请检查网络",
			"connectServerFail": "无法连接到服务器",
			"requestTimeout": "请求超时"
		},
		"validate": {
			"choiceCountry": "请先选择一个国家",
			"country": "国家不能为空，请选择国家",
			"timeZone": "时区不能为空，请选择时区",
			"email": "邮箱格式错，请输入一个正确的邮箱",
			"verification": "请先发送验证码",
			"verificationError": "验证码错误，请输入一个有效的验证码",
			"passwordFormTip": "请输入一个6-20位由数字和字母组成的密码",
			"passwordError": "密码格式错误，请输入一个6-20位由数字和字母组成的密码",
			"passwordError2": "请输入一个有效的密码(密码不应该包括任何特殊字符、符号和空格)",
			"validatePassword": "您输入的两个密码不一致，请检查后重新输入",
			"confirmAgreement": "请您先阅读并同意隐私协议",
			"plantName": "电站名字不能为空，请输入电站名字",
			"plantType": "电站类型不能为空，请选择电站类型",
			"pvCapacity": "太阳能电池容量不能为空，请输入电池容量",
			"installationDate": "安装日期不能为空，请选择安装日期",
			"city": "城市不能为空，请选择城市",
			"incomeunit": "货币不能为空，请选择货币",
			"energyPrice": "电价不能为空，请输入电价",
			"firstChoiceCountry": "请先选择一个国家",
			"oldPassword": "旧密码不能为空，请输入旧密码",
			"newPassword": "新密码不能为空，请输入新密码",
			"confirmPassword": "确认密码不能为空，请确认密码",
			"passwordNotMatch": "两次密码输入不一致，请检查确认后提交",
			"SN1": "序列号不能为空",
			"SN2": "序列号必须是10位",
			"noSocketTimer": "当前没有插座定时器，请点击添加",
			"cameraPermission": "App需要相机权限，以便用于扫码添加采集器",
			"searchingState": "正在搜索蓝牙中，请勿重复点击",
			"notInTheScope": "输入参数不在规定范围内",
			"enterDecimal": "必须输入{num}位小数或整数",
			"integer": "必须是整数",
			"phone": "手机号仅支持中国大陆，其它请使用邮箱找回",
			"can`tEmpty": "{param}不能为空",
			"dealerSope": "经销商编号范围是6~8位数字",
			"firstChoicePlant": "请先选择电站"
		},
		"log": {
			"permissionOpen": "确保定位和蓝牙权限已开启",
			"openBluetoothSystem": "打开蓝牙设置",
			"openIosBtSystem": "请重启蓝牙开关",
			"openLocationSystem": "打开定位设置",
			"bluetoothAuthorization": "APP蓝牙授权未开启",
			"locationAuthorization": "APP定位授权未开启",
			"searchSurroundingDevicesPermission": "搜索附近设备未授权",
			"bluetoothNotPermission": "蓝牙未开启",
			"locationNotPermission": "定位未开启",
			"bluetoothPermission": "蓝牙已开启",
			"locationPermission": "定位已开启",
			"bluetoothException": "蓝牙异常断开，请确认设备蓝牙已开启，然后重新连接",
			"listeningFailure": "监听特征值变化失败，请重新连接蓝牙",
			"communicationException": "蓝牙通讯异常，请重新连接蓝牙",
			"reconnect": "蓝牙重新连接",
			"connectFail": "蓝牙连接失败",
			"connect": "连接蓝牙",
			"bluetoothService": "请开启蓝牙服务",
			"locationService": "请开启定位服务",
			"notsupport": "当前平台支持调用蓝牙功能",
			"locationNotTurnOn": "定位未开启，无法搜索蓝牙",
			"init": "蓝牙初始化",
			"searching": "正在搜索周围蓝牙",
			"searchFail": "周围没有查询到设备，请确保设备蓝牙已开启，然后点击刷新",
			"stopSearch": "停止搜索周围蓝牙",
			"stopSearchFail": "停止搜索周围蓝牙失败",
			"connectSuccess": "蓝牙连接成功",
			"setMTUsSuccess": "设置最大传输单元成功",
			"setMTUsFail": "设置最大传输单元失败",
			"closeSuccess": "已断开蓝牙连接",
			"closeFail": "断开蓝牙连接失败",
			"wifiLoadFail": "WiFi列表加载失败，请手动输入",
			"bluetoothAbnormal": "蓝牙连接异常，正在尝试重新连接",
			"getBluetoothServiceSuccess": "获取蓝牙设备服务成功",
			"getBluetoothServiceFail": "获取蓝牙设备服务失败",
			"getFeatureValuesSucess": "已成功获取蓝牙设备指定服务中的所有特征值",
			"getFeatureValuesFail": "获取蓝牙设备指定服务中的所有特征值失败",
			"subscriptionEigenvalueSuccess": "已启用低功耗蓝牙设备特征值变化时的notify功能，订阅特征值",
			"subscriptionEigenvalueFail": "启用低功耗蓝牙设备特征值变化时的notify功能，订阅特征值失败",
			"monitoringSuccess": "已成功监听低功耗蓝牙设备的特征值变化事件",
			"monitoringFail": "监听低功耗蓝牙设备的特征值变化事件失败",
			"CRC": "CRC校验失败",
			"queryWiFi": "已成功查询WiFi列表",
			"queryWiFiState": "已成功下发WiFi列表查询指令",
			"wifiConenct": "正在查询路由连接状态",
			"wifiFail": "请检查路由器账号和密码并尝试重新连接",
			"connectWiFiSuccess": "路由器连接成功",
			"connectWiFiFail": "路由器连接失败",
			"routerAccount": "路由器账号错误",
			"routerPassword": "路由器密码错误",
			"gettingIP": "正在获取路由器IP",
			"getIPFail": "获取路由器IP失败",
			"queryServerState": "已成功下发查询设备与服务器交互状态指令",
			"server03": "有03数据交互",
			"server04": "有04数据交互",
			"heartbeat": "有心跳数据交互",
			"noInteraction": "没有数据交互",
			"bluetoothCommunication": "已打通蓝牙通讯",
			"connectWiFi": "连接WiFi",
			"getWiFiList": "正在查询WiFi列表",
			"wifiName": "WiFi名字不能为空，请选择或输入2.4WiFi",
			"wifiPasword": "WiFi不能为空，请输入密码",
			"wifiPasswordError": "WiFi密码不能包含特殊字符(ǎǐò•♢∝⌒℃℉ etc.)",
			"masterControlId": "主控ID不能为空，请输入ID"
		},
		"addDatalogger": {
			"noSearchDevice": "无法查询到设备，请确保设备蓝牙已开启",
			"searchAroundBluetooth": "周围可配网蓝牙",
			"scan": "扫描二维码或在二维码下方手动输入采集器序列号。",
			"connectBluetoothFail": "蓝牙连接失败，请扫描二维码或在二维码下方手动输入采集器序列号后再次连接。"
		},
		"connectWifi": {
			"tip1": "配置设备加入网络",
			"tip": "请选择2.4G WiFi",
			"devicePower": "设备功率",
			"enterSSID": "请输入路由器账号",
			"enterPassword": "请输入路由器密码",
			"masterId": "请输入主控Id",
			"commandSetFail": "命令设置失败，请重新连接采集器"
		},
		"configurationState": {
			"connecting": "连接中",
			"connectFail": "连接失败",
			"connectSuccess": "连接成功",
			"wait": "请稍等",
			"addDeviceSuccess": "添加设备成功"
		},
		"home": {
			"today": "日发电量",
			"month": "月发电量",
			"year": "年发电量",
			"total": "总发电量",
			"homePower": "家庭总负载",
			"socketPower": "插座负载",
			"off-gridPower": "离网负载",
			"acPower": "市电功率",
			"on-gridPower": "并网有功功率",
			"others": "其它",
			"latestVersion": "已是最新版本",
			"frontEndLoad": "前端负载",
			"backEndLoad": "后端负载"
		},
		"energy": {
			"choiceDate": "当前的数据只到",
			"explain": "说明",
			"tip1": {
				"load": "负载所消耗的能源",
				"solar": "将太阳能转换为电能，用于电池充电或负载供电的能源",
				"grid": "累计给电网出售的能源",
				"battery": "累计给电池充电的电能能源"
			},
			"tip2": "=光伏能源*电价",
			"tip3": {
				"title": "社会贡献计算标准",
				"title2": "1kWh等于:",
				"Co2": "减少二氧化碳排放量：",
				"coal": "节约使用标准煤：",
				"deforestation": "保护树木："
			}
		},
		"plant": {
			"comprehensive": "综合排序",
			"totalEnergyMax": "总电量最高",
			"totalEnergyMin": "总电量最低",
			"realTimePower": "实时功率",
			"pvcapacityMax": "pv容量最高",
			"pvcapacityMin": "pv容量最低",
			"installationDateMax": "装机日期最远",
			"installationDateMin": "装机日期最近",
			"paramChoice": "参数选择",
			"filter": "筛选",
			"addVisitor": "添加访客",
			"tip": "提示",
			"visitorTip": "只能分享给已注册的用户",
			"name": "名字",
			"permission": "权限",
			"read-write": "读写",
			"onlyread": "只读",
			"visitor": "访客",
			"unfollow": "你确定要取消对此电站的关注？"
		},
		"device": {
			"power": "功率",
			"type": "类型",
			"updateTime": "更新时间",
			"powerOnTime": "上电时间",
			"todayEnergy": "今日电量",
			"signal": "信号强度"
		},
		"delDevice": "你确定要删除这个{device}吗?",
		"currentNoDevice": "当前没有设备",
		"currentNoDatalog": "当前没有采集器",
		"messageInfo": {
			"storage": "储能",
			"inverter": "逆变器",
			"microInverter": "微逆"
		},
		"ops": {
			"setDomainName": "你确定要设置这个域名吗？",
			"restartDatalogger": "你确定要重启采集器吗？"
		},
		"opsLog": {
			"addUser": "添加终端用户",
			"bindSuccess": "绑定成功",
			"selectDate": "选择日期",
			"start": "开始",
			"end": "结束",
			"upgrade": "升级",
			"operation": "操作",
			"communication": "通讯",
			"type": "类型",
			"content": "内容",
			"control": "控制器",
			"command": "命令类型",
			"reset": "重置",
			"serverTime": "服务器时间",
			"inverter": "间隔",
			"bindAgent": "绑定经销商",
			"deleteDeviceSN": "请确认是否删除？",
			"recordLog": "需要记录日志的设备"
		},
		"userLocation": "用户所在地",
		"setting": {
			"productModelNumberRelevancyOn-gridPower": "并网有功功率不能大于所选产品型号最大功率"
		}
	},
	"productEquipment": {
		"productModelNumber": "产品型号",
		"warnMessage": "告警信息",
		"customInstruction": "自定义指令",
		"firmwareUpdate": "固件升级",
		"historicalData": "历史数据",
		"linkageInformation": "连接信息",
		"batchRead":"批量读取",
		"batchSetting":"批量设置",
		"protocolDirectives":"协议指令",
		"basicSettings":"基础设置",
		"otherSettings":"其他设置",
	},
	"systemAuthority": {
	    "systemSettings": '系统设置',
	    "authorityManagement": '权限管理',
	    "accountManagement": "角色管理",
		"Disabled":"禁用",
		"enable":"启用"
	},
	"org":{
		"ChangeSupplier":'更改供应商',
	}
}
