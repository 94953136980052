<template>
  <div id="app">
    <template v-if="WhiteliRtroutes.includes($route.path)">
      <router-view></router-view>
    </template>
    <template v-else>
      <IndexComponent></IndexComponent>
    </template>
  </div>
</template>

<script>
import IndexComponent from './components/IndexComponent.vue'
export default {
  components: { IndexComponent },
  data() {
    return {
      WhiteliRtroutes: ['/', "/login", "/404"]
    }

  }
}

</script>
<style lang="scss">
body {
  margin: 0px;
  font-size: 16px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  blockquote,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  pre,
  form,
  fieldset,
  legend,
  b utton,
  input,
  textarea,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  ul,
  li {
    list-style: none;
  }

  font-family: "Microsoft YaHei",
  "微软雅黑",
  Arial,
  sans-serif;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #ebeef5;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: rgba(255, 255, 255, 1);
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-dialog__header {
  background-color: #F8F8F8;
}

.el-pager li {
  background: #F1F3F3 !important;
  color: #409EFF;

}

/**
    高亮
  */
.el-pager li:not(.disabled).active {
  // background-color: #be0897 !important;
  color: #409EFF !important;
}
</style>
