/*
 * @Author: lqj
 * @Desc:  全局组件注册
 * @Date: 2024-07-12 10:32:05
 * @LastEditors: lqj
 * @LastEditTime: 2024-08-13 09:53:47
 */
import Vue from "vue"
import sysSelect from "@/components/sysselect.vue"
import customCard from "@/components/customCard"

// 全局方法
import {deepClone} from "@/utils/common"

const commonObject ={
    deepClone
} 

for (let key in commonObject) {
    let item = commonObject[key]
    Vue.prototype["$"+key] =item
}

// 全局组件
const componentObject = {
    sysSelect,
    customCard,
}
for (let key in componentObject) {
    let item = componentObject[key]
    Vue.component(key, item)
}
