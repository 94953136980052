import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import permission from './modules/permissioned'
import user from './modules/user'
import getters from './getters.js'
import device from './modules/device'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    permission,
    user,
    device,
  },
  getters,
  plugins: [createPersistedState({
    storage: window.sessionStorage // 同localStorage相同，只是将vuex的所有值存储到sessionStorage中
  })]
})

export default store
