import router from '@/router'
import store from '@/store'

// permissiom judge function
// eslint-disable-next-line no-unused-vars
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

// 判断权限[1,2,3,4,66,33,88]和[99,0,56,3]的包含关系
// eslint-disable-next-line no-unused-vars
const whiteList = ['/login']// no redirect whitelist

function authentication(menuIds, meta) {
  let flag = false
  if (meta?.menuId) {
    if (menuIds.indexOf(meta.menuId) != -1) {
      flag = true
    } else {
      flag = false
    }
  } else {
    flag = true
  }
  return flag
}
router.beforeEach((to, from, next) => {
  // let userInfo = store.getters["userInfo"]
  const menuIds = store.getters.menuIds
  if (to.path === '/login') {
    next()
  } else {
    if (!menuIds?.length) {
      next({ path: '/login' })
    }
    if (authentication(menuIds, to.meta)) {
      next()
    } else {
      next({ path: '/404' })
    }
  }
})

// router.afterEach(() => {
// })
