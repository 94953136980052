// 在http.js中引入axios
import axios from 'axios'
import QS from 'qs'//引入qs模块，用来序列化post类型的数据
import router from '@/router'

// => 环境的切换
// if (process.env.NODE_ENV == 'development') {    //开发环境
//   axios.defaults.baseURL = ''; 
// } 

axios.defaults.baseURL = '/';
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// 如果需要跨域，可以设置withCredentials为true
axios.defaults.withCredentials = true; // 允许跨域请求时发送cookies


// 创建axios实例
const service = axios.create({
    baseURL: '/api', // api的base_url
    timeout: 10000,// 请求超时时间
    headers: {
        'Access-Control-Allow-Origin': '*',
        'strict-origin-when-cross-origin': '*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': localStorage.getItem('Accept-Language'),
    }
});

// 请求拦截器
service.interceptors.request.use(config => {
    config.headers['nh-token'] = localStorage.getItem('NH_TOKEN') || ""
    return config;
},
    error => {
        return Promise.error(error);
    })
// 响应拦截器
service.interceptors.response.use(
    response => {
        // console.log('Response=>', response.request.responseURL, response  );
        if (response.status === 200) {
            //result  0-正常  1-异常  10000-未登录 4000-系统异常
            if (response.data.result == 10000) {
                // 登录失败，跳转到首页，重新登录
                router.push({ path: '/login' })
                return Promise.resolve(response.data)
            } else {
                // 项目迁移后获取登录token
                // if (response.config.url == "/nahui-ems/auth/agent/verify/login" && response.data.code == 200) {
                //     let token = response.headers["nh-token"]
                //     localStorage.setItem('NH_TOKEN', token);
                // }
                return Promise.resolve(response.data)
            }
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.status) {
            return Promise.reject(error.response);
        }
    })
export default service

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, qr) {
    return new Promise((resolve, reject) => {
        service.post(url, qr ? QS.stringify(params) : params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

// 上传文件函数
export function uploadFile(url, file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file); // 'file' 是后端接收文件的字段名
    return service.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
    });
}
