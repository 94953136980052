<template>
    <div id="Index">
        <el-container>
            <el-header>
                <div class="logo_box_left">
                    <LogoBox :dns="this.dns_ym"></LogoBox>
                </div>
                <div class="navigation_right" :style="{ 'width': logoright }" :collapse="isColllapse">
                    <div class="breadcrumb_box">
                        <!-- <i class="el-icon-s-operation aside_btn" @click="toggleAside()"></i> -->
                        <i class="el-icon-s-operation aside_btn" @click="toggleAside()"></i>
                        <el-dropdown @command="changeLanguage">
                            <span class="el-dropdown-link" style="color: aliceblue;">
                                <i class="el-icon-setting" style="margin-right: 5px;"></i> {{ $t('language.name') }}
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="中文">简体中文</el-dropdown-item>
                                <el-dropdown-item command="繁體">繁體中文</el-dropdown-item>
                                <el-dropdown-item command="English">English</el-dropdown-item>
                                <el-dropdown-item command="German">German</el-dropdown-item>
                                <el-dropdown-item command="Italiano">Italiano</el-dropdown-item>
                            </el-dropdown-menu>
                            <!-- {{  sysMenuIds   }} -->
                        </el-dropdown>
                    </div>

                    <div class="system_name"></div>
                    <div class="headImg">
                        <el-dropdown trigger="click" size="medium" placement="bottom">
                            <div class="user">
                                <el-avatar icon="iconfont icon-touxiang"
                                    style=" border: 3px solid #589fdd;background-color: #113E68;"></el-avatar>
                                <span class="userName">{{ userName }}</span>
                                <i class="el-icon-caret-bottom" style="color: aliceblue;"></i>
                            </div>
                            <el-dropdown-menu slot="dropdown" :style="{ width: '10%', 'text-align': 'center' }">
                                <router-link to="/information" style="text-decoration: none;">
                                    <el-dropdown-item class="noSelect">{{ $t('sidebar.personal') }}</el-dropdown-item>
                                </router-link>
                                <el-dropdown-item @click.capture.native="logout()">
                                    {{ $t('sidebar.logout') }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>

            </el-header>
            <el-container>
                <!-- 垂直导航 -->
                <el-menu class="el-menu-vertical-demo el-nav-menu" @open="handleOpen" @close="handleClose"
                    :collapse="isCollapse" :router="true" :default-active="activeMenu" unique-opened mode="vertical">
                    <MainMenu class="el-menu-vertical-dem" :menuIds="menuIds" :dns_ym="dns_ym"
                        :menuList="permission_routers"></MainMenu>
                </el-menu>
                <!-- 内容区域 -->
                <el-main style="overflow-y: hidden;background-color: rgb(241, 243, 243);height: calc(100vh - 60px);">
                    <transition name="fade-transform" mode="out-in">
                        <router-view></router-view>
                    </transition>
                    <!-- 
                    <section class="app-main" style="min-height: 100%">
                        <transition name="fade-transform" mode="out-in">
                            <keep-alive :include="cachedViews">
                                <router-view :key="key"></router-view>
                            </keep-alive>
                        </transition>
                    </section> -->


                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import LogoBox from '@/components/ODM-logo.vue';
import MainMenu from './MainMenu.vue';
export default {
    components: { LogoBox, MainMenu },
    name: 'IndexComponent',
    data() {
        return {
            isCollapse: false,
            isColllapse: false,          //aside switch
            userName: '',
            userType: '',
            logoWidth: '12%',
            logoright: '86%',
            aside_width: '12%',
            dns_ym: '',


        }
    },
    methods: {
        // 点击切换
        selectLanguage(command) {
            this.selectedLanguage = command;
            // 在这里执行切换语言的逻辑
        },
        changeLanguage(command) {
            if (command === '中文') {
                this.$i18n.locale = 'zh-CN';
            } else if (command === 'English') {
                this.$i18n.locale = 'en-US';
            } else if (command === '繁體') {
                this.$i18n.locale = 'zh-HK';
            } else if (command === 'German') {
                this.$i18n.locale = 'de-DE';
            } else if (command === 'Italiano') {
                this.$i18n.locale = 'it-IT';
            }
            localStorage.setItem('Accept-Language', this.$i18n.locale);
            sessionStorage.setItem('Accept-Language', this.$i18n.locale);
            this.$router.go(0);
        },

        handleOpen() {
            // key, keyPath
        },
        handleClose( ) {
            // key, keyPath
        },
        /**
         * Click toggle menu size
         */
        toggleAside() {
            this.isColllapse = (this.isColllapse == false ? true : false)
            this.aside_width = (this.isColllapse == false ? '12%' : '12%')
            this.logoWidth = (this.isColllapse == false ? '12%' : '12%')
            this.logoright = (this.isColllapse == false ? '86%' : '86%')
            this.isCollapse = (this.isCollapse == false ? true : false)
        },
        logout() {
            this.$request({
                method: 'post',
                url: '/manage/agent/logOut',
                params: {
                    email: this.userName,
                }
            }).then(res => {
                if (res.data.result == 0 || res.data.result == 10000) {


                    // this.$message({
                    //     type: 'success',
                    //     message: res.data.msg
                    // });
                } else {
                    this.$message({
                        type: 'info',
                        message: 'Login out failed',
                    });
                }
            }).catch(( ) => {
            
            })
            this.$store.dispatch('setMenuIds', []).then(() => {
               
                this.$router.push('/login')
            })
        },

    },
    mounted() {

        const domain = window.location.hostname;
        sessionStorage.setItem("DNS", domain)
        this.dns_ym = domain
        let userInfo = this.userInfo || JSON.parse(sessionStorage.getItem('userInfo'))
        this.userName = userInfo.email
        this.userId = userInfo.id
        this.clientId = userInfo.clientId
        this.address = userInfo.address


    },
    computed: {
        ...mapGetters([
            'userInfo',
            'permission_routers',
            'menuIds'
        ]),
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        // cachedViews() {
        //     return this.$store.state.tagsView.cachedViews
        // },
        key() {
            return this.$route.fullPath
        }
    }
}
</script>

<style lang="scss" scoped>
.logo_box_left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#Index {

    .el-container {

        //导航栏
        .el-header {
            width: 100%;
            padding: 0;
            z-index: 99;
            // background-color: #fff;
            background-color: #113E68;
            box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
            display: flex;

            //logo


            .navigation_right {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #113E68;

                //面包屑导航栏
                .breadcrumb_box {
                    // height: 6vh;
                    display: flex;
                    align-items: center;
                    margin-left: 20px;

                    .aside_btn {
                        font-size: 22px;
                        margin-right: 10px;
                        cursor: pointer;
                        // color: #0f0f0f;
                        color: #ffffff;
                        // margin-right: 50px;
                        // margin-left: 30%;
                    }

                    .el-breadcrumb {
                        font-size: 16px;
                        line-height: 6vh;
                        margin-left: 20px;
                    }
                }

                // .system_name {
                //     font-weight: bolder;
                //     font-size: 26px;
                //     font-family: serif;
                // }

                //头像
                .headImg {
                    // width: 200px;

                    line-height: 30px;
                    // margin-left: 38.5px;
                    text-align: center;
                    cursor: pointer;
                    background-color: #113E68;

                    // 113E68
                    .el-dropdown {
                        .user {
                            display: flex;
                            align-items: center;

                            .userName {
                                margin-left: 10px;
                                font-size: 15px;
                                font-weight: 400;
                                // color: #494a4d;
                                color: #ffffff;

                            }
                        }
                    }
                }
            }
        }

        .el-nav-menu {
            overflow-y: auto;
            height: calc(100vh - 60px);
            overflow-x: hidden;

            &::-webkit-scrollbar {
                display: none;
            }
        }


        // 主体
        .el-container {

            // 侧边栏
            .el-aside {
                height: 94vh;
                background-color: #fbfaff;
                border-right: 1px solid #e6ebf5;
                z-index: 1;
                box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);


                .el-menu-item,
                .el-submenu__title {
                    height: 55px;
                    line-height: 55px;
                    list-style: none;
                    overflow: hidden;
                }

                .el-menu {
                    border: 0;
                    /* .el-menu-item{
                        &:focus{
                            background-color: #5e5485 !important;
                        }
                    } */
                }

                &:not(.el-menu--collapse) {
                    width: 10%;
                    min-height: 100%;
                }

                .homeIcon {
                    margin: 0 22px 0 2px;
                }

                .stationIcon {
                    margin: 0 23px 0 4px;
                }

                .ChargerIcon {
                    margin: 0 23px 0 1px;
                }

                .userIcon {
                    margin: 0 24px 0 0px;
                }

                .cardIcon {
                    margin: 0 22px 0 0px;
                }

                .connectorIcon {
                    margin: 0 22px 0 1px;
                }

                .groupIcon {
                    margin: 0 23px 0 0;
                }

                .alarmIcon {
                    margin: 0 25px 0 0;
                }

                .firmwareIcon {
                    margin: 0 25px 0 -1px;
                }

                .logsIcon {
                    margin: 0 23px 0 0;
                }
            }

            // 内容
            .el-main {
                position: relative;
                width: 85%;
                padding: 20px;
                height: calc(100vh - 60px);
                box-sizing: border-box;
            }

            .Icon {
                margin: 0 25px 0 -1px;
            }
        }
    }
}
</style>
