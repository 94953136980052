/*
 * @Author: lqj
 * @Desc:  按钮权限指令校验
 * @Date: 2024-08-12 16:32:26
 * @LastEditors: lqj
 * @LastEditTime: 2024-08-13 11:27:15
 */
 /* <div class="main_area" v-permission="['ading']">  </div> */ 
import store from '@/store'
export default {
  inserted(el, binding, vnode) {
    const { value } = binding;
    const roles = store.getters && store.getters.roles;

    // 检查权限配置
    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value;

      // 检查用户是否有权限
      const hasPermission = roles.some(role => permissionRoles.includes(role));

      // 如果用户没有权限，则移除该元素
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      // 抛出错误提示
      throw new Error(`Invalid role configuration! Expected an array of roles, like: v-permission="['admin','editor']"`);
    }
  }
};

 