// import { Message } from "element-ui";

/**
 * 转为年月日
 */
export let formatDate = function (date) {
    if (date != '' && date != undefined && date != null) {
        date = new Date(date)
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
    } else {
        return ''
    }
};

/**
 * 防抖
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export let debounce = function (fn, delay) {
    let timer = null
    return function () {
        let content = this;
        let args = arguments;
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn.apply(content, args)
        }, delay)
    }
}

/**
 * 日期  ‘04-23’
 * @param {*} d 
 * @returns 
 */
export let monthDay = function (d) {
    let date = new Date(d)
    let month = date.getMonth() + 1
    month = month < 10 ? ('0' + month) : month
    let day = date.getDate()
    day = day < 10 ? ('0' + day) : day
    return month + '-' + day
}

/**
 * ["04-15","04-16","04-17","04-18","04-19","04-20","04-21"]
 * @returns 
 */
export let sevenDay = function () {
    let date = new Date().valueOf() - 604800000
    let arr = []
    for (let i = 0; i < 7; i++) {
        arr.push(monthDay(date += 86400000))
    }
    return arr
}

/**
 * 不允许为空
 * @param {*} obj 
 * @returns 
 */
export let noEmpty = function (obj) {
    for (let i in obj) {
        if (!obj[i]) return i
    }
    return false
}

/**
 * 格式化echarts数据
 * @param {Array} arr
 */
export let formatEchartsData = function (arr) {
    let arrData = []
    for (var i in arr) {
        if (arr[i] != null) {
            let arr2 = []
            arr2.push(i)
            arr2.push(arr[i])
            arrData.push(arr2)
        } else {
            let arr2 = []
            arr2.push(i)
            arr2.push(0)
            arrData.push(arr2)
        }
    }
    return arrData
}


// 函数拷贝
export const deepCopy = (obj = {}) => {
    let newObj = null
    // 判断是否需要继续进行递归
    if (typeof obj == 'object' && obj !== null) {
        newObj = obj instanceof Array ? [] : {}
        // 进行下一层递归克隆
        for (let i in obj) {
            newObj[i] = deepCopy(obj[i])
        }
    } else {
        newObj = obj
    }
    return newObj
}
// 深拷贝
export const deepClone = (obj) => {
    var o;
    // 如果  他是对象object的话  , 因为null,object,array  也是'object';
    if (typeof obj === 'object') {

        // 如果  他是空的话
        if (obj === null) {
            o = null;
        }
        else {
            // 如果  他是数组arr的话
            if (obj instanceof Array) {
                o = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    o.push(deepClone(obj[i]));
                }
            }
            // 如果  他是对象object的话
            else {
                o = {};
                for (var j in obj) {
                    o[j] = deepClone(obj[j]);
                }
            }

        }
    }
    else {
        o = obj;
    }
    return o;
};
