<!--
 * @Author: lqj
 * @Desc:  基于element  el-select 二次封装 select 组件
 * @Date: 2024-07-12 09:49:15
 * @LastEditors: lqj
 * @LastEditTime: 2024-07-19 09:04:51
-->
<template>
    <el-select ref="comp" class="sys_select" v-model="input" :filterable="filterable" @change="change"
        @clear="handleClear" :multiple="multiple" :size="size" :class="{ no_border: noBorder }"
        :popper-class="popperClass" :value="value" :placeholder="placeholder" :clearable="clearable"
        :collapse-tags="collapseTags" :value-key="selectValueKey" :multiple-limit="multipleLimit"
        @visible-change="visibleChange" :noDataText="noDataText" :disabled="disabled" :loading="loading"
        :allow-create="allowCreate">
        <el-option v-for="item in optionsArr" :key="item[idKey] + item[valueKey]" :label="item[valueKey]"
            :disabled="item.disabled" :value="needObject ? item : item[idKey]"
            v-show="item.optionShow === false ? false : true">
        </el-option>
    </el-select>
</template>

<script>
import * as Api from "@/api/api.js";
export default {
    name: 'sysSelect',
    props: {
        value: {
            // 绑定的值
            type: [String , Object ,Boolean , Array,Number],
        },
        options: {
            // 下拉框数据
            type: Array,
            default: () => [],
        },
        placeholder: {
            // 未选择提示语
            type: String,
            default: "请选择",
        },
        filterable: {
            // 是否可搜索
            type: Boolean,
            default: true,
        },
        disabled: {
            // 禁用
            type: Boolean,
            default: false,
        },
        clearable: {
            // 清空单选
            type: Boolean,
            default: false,
        },
        multiple: {
            // 多选
            type: Boolean,
            default: false,
        },
        multipleLimit: {
            // 多选模式下最多选中数量
            type: Number,
            default: 0,
        },
        collapseTags: {
            // 多选下折叠选中项
            type: Boolean,
            default: false,
        },
        size: {
            // 输入框尺寸 medium/small/mini
            type: String,
            default: "",
        },
        needObject: {
            // 绑定值是否启用对象模式
            type: Boolean,
            default: false,
        },
        idKey: {
            // 绑定的id 值
            type: String,
            default: "id",
        },
        valueKey: {
            type: String,
            default: "name",
        },
        code: {
            // 传了 CODE 就代表该下拉框是值级类下拉框，这个时候不需要传 options
            type: String,
            default: "",
        },
        systemId: {
            // 当前为 数据字典下拉框时 必传
            type: String,
            default: "",
        },
        popperClass: {
            // 下拉的class名样式
            type: String,
            default: "",
        },
        noBorder: {
            // 输入框不显示边框
            type: Boolean,
            default: false,
        },
        loading: {
            // 输入框loading
            type: Boolean,
            default: false,
        },
        noDataText: {
            type: String,
            default: "NO DATA",
        },
        busText: {
            // 数据字典需要回显的字段
            type: String,
            default: "",
        },
        // 创建条目
        allowCreate: {
            type: Boolean,
            default: false,
        },
        // 绑定的id 值匹配不上时展示name的值
        name: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            input: this.value,
            optionsArr: [],
            selectValueKey: this.idKey,
            echo: false
        };
    },
    created() {
        if (this.code && this.systemId) this.getListOnce();
    },
    methods: {
        echoData() {
            this.$nextTick(() => {
                // 单选的时候回显操作
                if (Object.keys(this.input).length) this.echo = true
                // 多选且绑定为数组对象形式的回显
                if (
                    this.multiple &&
                    this.input &&
                    Array.isArray(this.input) &&
                    this.input.length &&
                    this.needObject
                ) {
                    if (this.input.length) this.echo = true
                    let arr = [];
                    this.input.forEach((item) => {
                        arr.push({
                            currentLabel: item[this.valueKey],
                            currentValue: item,
                            label: item[this.valueKey],
                            value: item,
                        });
                    });
                    if (!this.options.length) this.optionsArr = this.$deepClone(this.input)
                    if (this.$refs.comp) {
                        this.$refs.comp.value = this.input;
                        this.$refs.comp.cachedOptions.push(...arr);
                        this.$refs.comp.setSelected();
                    }
                }
            });
        },
        handleClear() {
            console.log(this.optionsArr)
        },
        visibleChange(val) {
            this.$emit("visible-change", val);
        },
        change(val) {
            this.$nextTick(() => {
                //异步提交change事件   确保外部值已更新
                this.$emit("change", val);
            })
        },
        // 通过 code sysitemId 获取 字典 数据
        getListOnce() {
            let params = {
                dictCode: this.code,
                systemId: this.systemId,
            };
            Api.listOnce(params).then((res) => {
                const { code, data } = res;
                if (["0", "200"].includes(`${code}`)) {
                    this.optionsArr = data.map((item) => {
                        const { itemCode, itemName } = item;
                        return {
                            id: itemCode,
                            name: itemName,
                        };
                    });
                    /**
                     * 列表查询回显数据字典
                     * @param {string} busText 外界需要赋值的字段
                     * @param {array} data 列表数据
                     */
                    this.$bus.$emit("select options", {
                        busText: this.busText,
                        data: this.optionsArr,
                    });
                }
            });
        },
    },
    watch: {
        code: {
            handler(val) {
                if (!val || !this.systemId) return this.ylWarning("请传入 code 或者 systemId");
                this.getListOnce();
            },
            deep: true,
        },
        options: {
            handler(v) {
                this.optionsArr = v || [];
                if (Array.isArray(this.input)) {
                    this.input.forEach(item => {
                        if (item && item[this.idKey] && item[this.valueKey] && (!this.optionsArr.find(item1 => item1[this.idKey] == item[this.idKey]) || !this.optionsArr.find(item1 => item1[this.valueKey] == item[this.valueKey]))) {
                            let obj = this.$deepClone(item)
                            obj.optionShow = false
                            this.optionsArr.push(obj)
                        }
                    })
                } else if (this.needObject) {
                    this.$nextTick(() => {
                        let obj = this.optionsArr.find(item => item[this.idKey] == this.input[this.idKey])
                        let obj1 = this.optionsArr.find(item1 => item1[this.valueKey] == this.input[this.valueKey])
                        if (this.input && this.input[this.idKey] && this.input[this.valueKey] && (!obj || !obj1)) {
                            let obj = this.$deepClone(this.input)
                            obj.optionShow = false
                            this.optionsArr.push(obj)
                            if (!obj) {
                                this.selectValueKey = this.idKey
                            } else if (!obj1) {
                                this.selectValueKey = this.valueKey
                            }
                        }
                    })
                } else if (this.input && this.name && (!this.optionsArr.find(item => item[this.idKey] == this.input) || !this.optionsArr.find(item => item[this.valueKey] == this.name))) {
                    let obj = { optionShow: false }
                    obj[this.idKey] = this.input
                    obj[this.valueKey] = this.name
                    this.optionsArr.push(obj)
                }
            },
            deep: true,
            immediate: true,
        },
        value: {
            handler(val) {
                if (val && !this.echo) {
                    this.echoData()
                }
                this.input = val;
            },
            deep: true,
        },
        input: {
            handler(val) {
                 
                this.$emit("input", val);
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="less">
.no_border {
    :deep(.el-input__inner) {
        border: none;
    }
}
</style>