export default {
	"language": {
		"name": "German"
	},
  product:{
    "PleaseEnter": "Bitte geben Sie den Produktnamen ein",
    "PleaseEnterTheProductSerialNumber": "Bitte geben Sie die Produkt-Serienummer ein",
    "PleaseEnterSubmodelNumber": "Bitte geben Sie die Untermodellnummer ein",
    "PleaseenterTheSubmodelSerialNumber": "Bitte geben Sie die Seriennummer des Untermodells ein",
    "PleaseSelectTheDeviceType": "Bitte wählen Sie den Gerätetyp",
    "PleaseSelectDevelopmentType": "Bitte wählen Sie die Entwicklungsart",
    "management": "Produktmanagement",
    "name": "Produktname",
    "reset": "Zurücksetzen",
    "search": "Suche",
    "ApplicationList": "Anwendungsliste",
    "newProduct": "Neues Produkt",
    "editName": "Produkt bearbeiten",
    "DetailSetting": "Detaileinstellungen",
    "delete": "Löschen",
    "serialNumber": "Seriennummer",
    "deviceType": "Gerätetyp",
    "RandDtype": "Forschungs- und Entwicklungstyp",
    "subType": "Untertyp",
    "updateTime": "Aktualisierungszeit",
    "operation": "Operation",

    "deviceList": "Geräteliste (Versandprotokoll)",
    "parameterManagement": "Parameterverwaltung",
    "updataManagement": "Aktualisierungsverwaltung",

    "ParentDevice": "Elterngerät",
    "AssociatedPowerStationState": "Zugehöriger Kraftwerkszustand",
    "ImportDeviceList": "Geräteliste importieren",
    "PlantDetails": "Kraftwerksdetails",
    
    "OwningPowerStation": "Zugehöriges Kraftwerk",
    
    "parameterName": "Parametername",
    "ParameterClass": "Parameterklasse",
    "ParameterSettingType": "Parametereinstellungstyp",
    "ParameterOperationType": "Parameterbedienungstyp",
    "ImportParameter": "Parameter importieren",
    "OwningUpperLevelParameterCategory": "Zugehörige obere Parameterkategorisierung",
    "WhetherYoSupportBatchRead": "Ob Batchlesung unterstützt wird",
    "value": "Wert",
    "scope": "Bereich",
    "unit": "Einheit",
    "explain": "Erklärung",
    "remark": "Anmerkung",

    "fileName": "Dateiname",
    "fileAddress": "Dateipfad",
    "versionNumber": "Versionsnummer",
    "ProductOfOwnership": "Produkt des Eigentums",
    
    "ProductDetailsSettings": "Balkon 1.0 - Produkt-Detaileinstellungen",
    "QueryResultList": "Abfrageergebnisliste",
    "AddFirmware": "Firmware hinzufügen",
    "NewParameter": "Neuer Parameter",

    "datalog": "Datensammler",
    "Inverter": "Wechselrichter",
    "storage": "Speicher",
    "battery": "Batterie",
    "socket": "Steckdose",

    "DownloadTemplate": "Vorlage herunterladen",
    "nation": "Nation",
    "agent": "Agent",
    "expirationDate": "Verfallsdatum",
    "UpdateTime": "Aktualisierungszeit",
    "LastWeek": "Letzte Woche",
    "LastMonth": "Letzter Monat",
    "LastThreeMonths": "Letzte drei Monate",

    "parameterType": "Parametertyp",
    "ProtocolAddress": "Protokolladresse",
    "stepSize": "Schrittgröße",
    "PleaseEnterAParameterName": "Bitte geben Sie einen Parameternamen ein",
    "parameterCoding": "Parametercodierung",
    "PleaseEnterTheParameterCode": "Bitte geben Sie den Parametercode ein",
    "ParameterDataType": "Parameterdatentyp",
    "PleaseEnterTheAddressInTheParameterProtocol": "Bitte geben Sie die Adresse im Parameterprotokoll ein",
    "blockEncoding": "Blockcodierung",
    "PleaseEnterABlockCode": "Bitte geben Sie einen Blockcode ein",
    "GroupName": "Gruppenname",
    "PleaseEnterAGroupName": "Bitte geben Sie einen Gruppennamen ein",
    "PleaseEnterTheUnit": "Bitte geben Sie die Einheit ein",
    "decimalPoint": "Dezimalpunkt",
    "PleaseEnterTheDecimalPoint": "Bitte geben Sie den Dezimalpunkt ein",
    "leastValue": "Kleinstwert",
    "PleaseEnterTheUnit": "Bitte geben Sie den kleinsten Wert ein", // 注意：此处修正为 "Please enter the least value"
    "maximumValue": "Maximalwert",
    "PleaseEnterTheMaximumValue": "Bitte geben Sie den Maximalwert ein",
    "PleaseEnterTheStepSize": "Bitte geben Sie die Schrittgröße ein",
    "addEquipment": "Gerät hinzufügen",
    "equipment": "Gerät",
    "remove": "Entfernen",
    "sort": "Sortieren",
    "PleaseEnterTheOrder": "Bitte geben Sie die Reihenfolge ein",
    "PleaseEnterTheParameterSettingType": "Bitte geben Sie den Parametereinstellungstyp ein",

    "versionNumber": "Versionsnummer",
    "ProductOfOwnership": "zugehöriges Produkt",
    "PleaseSelectAFolderPath": "Bitte wählen Sie einen Ordnerpfad",
    "ReturnToSuperior": "zurück zum Vorgesetzten",
    "PleaseEnterTheVersionNumber": "Bitte geben Sie die Versionsnummer ein",
    "firmwareSpecification": "Firmware-Spezifikation",
    "PleaseEnterFirmwareDescription": "Bitte geben Sie die Firmwarebeschreibung ein",
    
    "FileName": "Dateiname",
    "DeviceType": "Gerätetyp",
    "FileAddress": "Dateiadresse",
    "time": "Zeit",
  },
	"sidebar": {
		"home": "Datenübersicht",
		"endbenutzer": "EBVerw",
		"plant": "Szenen",
		"plant_plant": "Kraftwerk",
		"device": "Geräteverw",
		"datalog": "Collector-Liste",
		"inverter": "Wechselrichterliste",
		"sotrage": "Energiespeicherliste",
		"socket": "Socket-Liste",
		"battery": "Batterieliste",
		"agent": "Agent-Verwaltung",
		"systematicSupply": "Versandprotokoll",
		"firmware": "Firmware-Liste",
		"firmware_up": "Firmware-Upload",
		"firmware_log": "Upgrade-Datensatz",
		"system": "System",
		"system_datalog_log": "Collector-Protokoll",
		"system_log": "Ops-Protokoll",
		"system_tongxun": "Kom.-Protokoll",
		"system_app": "App-Version",
		"personal": "Benutzerinformationen",
		"logout": "Vom Konto abmelden",
		"organizationalManagement":"Organisationsmanagement"
	},
	"login": {
		"email": "E-Mail",
		"password": "Passwort",
		"remember": "E-Mail und Passwort merken",
		"enteremail": "Bitte geben Sie Ihre E-Mail ein",
		"enterpassword": "Bitte geben Sie Ihr Passwort ein"
	},
	"home": {
		"echarts1": {
			"title": "Registrierte Benutzer",
			"subhead": "Neue Benutzer heute/Gesamtzahl der Benutzer"
		},
		"echarts2": {
			"title": "Angemeldete Benutzer",
			"subhead": "Heute angemeldete Benutzer/Gesamtzahl der Benutzer"
		},
		"echarts3": {
			"title": "Agentur hinzufügen",
			"subhead": "Heute angemeldete Agenturen/Gesamtzahl der Agenturen"
		},
		"echarts4": {
			"title": "Szene hinzufügen",
			"subhead": "Neue Szenen/Gesamtzahl der Szenen",
			"title_plant": "Anlage hinzufügen",
			"subhead_plant": "Neue Anlagen/Gesamtanzahl an Anlagen"
		},
		"echarts5": {
			"title": "Datenlogger hinzufügen",
			"subhead": "Neue Datenlogger/Gesamtzahl der Datenlogger"
		},
		"echarts6": {
			"title": "Gerät hinzufügen",
			"subhead": "Neue Geräte/Gesamtzahl der Geräte"
		}
	},
	"End_user": {
		"input_email": "Bitte geben Sie die Benutzer-E-Mail-Adresse ein",
		"btn_search": "Suchen",
		"btn_add": "Hinzufügen",
		"btn_delete": "Löschen",
		"btn_binding": "Binden",
		"add_station": "Szenario hinzufügen",
		"lable_StationName": "Szenenname",
		"lable_StationType": "Szenentyp",
		"lable_PVCapacity": "Photovoltaik-Kapazität",
		"lable_InstalltionDate": "Installationsdatum",
		"lable_Country": "Land",
		"lable_City": "Stadt",
		"lable_Address": "Adresse",
		"lable_TimeZone": "Zeitzone",
		"lable_Energyprice": "Energiepreis",
		"lable_IncomeUnit": "Einkommenseinheit",
		"input_StationName": "Bitte geben Sie den Szenennamen ein",
		"input_StationType": "Bitte wählen Sie einen Typ",
		"input_PVCapacity": "Bitte PV-Kapazität eingeben",
		"input_InstalltionDate": "Bitte Datum wählen",
		"input_Country": "Bitte wählen Sie ein Land",
		"input_City": "Bitte wählen Sie eine Stadt",
		"input_Address": "Bitte geben Sie die Adresse ein",
		"input_TimeZone": "Bitte Zeitzone wählen",
		"input_Energyprice": "Bitte Preis eingeben",
		"input_IncomeUnit": "Wählen Sie die Einkommenseinheit",
		"max_20": "Maximal 20 Zeichen",
		"max_5": "Maximal 5 Zeichen",
		"max_8": "Maximal 8 Zeichen",
		"format": "Bitte geben Sie das richtige Format ein",
		"close": "Schließen",
		"submit": "Senden",
		"title_delete": "Löschen?",
		"no": "Nein",
		"yes": "Ja",
		"Bingding_Agent": "Agentur binden",
		"Agent_Code": "Agentur:",
		"please_select": "Bitte auswählen"
	},
	"plant": {
		"input_email": "Bitte geben Sie die Benutzer-E-Mail-Adresse ein",
		"search": "Suchen",
		"edit": "Bearbeiten",
		"delete": "Löschen",
		"edit_plant": "Szene bearbeiten",
		"input_station": "Bitte geben Sie den Szenennamen ein",
		"lable_StationName": "Szenenname",
		"lable_StationType": "Szenentyp",
		"lable_PVCapacity": "Photovoltaik-Kapazität",
		"lable_InstalltionDate": "Installationsdatum",
		"lable_Country": "Land",
		"lable_City": "Stadt",
		"lable_Address": "Adresse",
		"lable_TimeZone": "Zeitzone",
		"lable_Energyprice": "Energiepreis",
		"lable_IncomeUnit": "Einkommenseinheit",
		"input_StationName": "Szenenname",
		"input_StationType": "Bitte wählen Sie einen Typ",
		"input_PVCapacity": "Bitte PV-Kapazität eingeben",
		"input_InstalltionDate": "Bitte Datum wählen",
		"input_Country": "Bitte wählen Sie ein Land",
		"input_City": "Bitte wählen Sie eine Stadt",
		"input_Address": "Bitte geben Sie die Adresse ein",
		"input_TimeZone": "Bitte Zeitzone wählen",
		"input_Energyprice": "Bitte Preis eingeben",
		"input_IncomeUnit": "Wählen Sie die Einkommenseinheit",
		"max_20": "Maximal 20 Zeichen",
		"max_5": "Maximal 5 Zeichen",
		"max_8": "Maximal 8 Zeichen",
		"format": "Bitte geben Sie das richtige Format ein",
		"close": "Schließen",
		"submit": "Senden",
		"title_delete": "Löschen?",
		"no": "Nein",
		"yes": "Ja"
	},
	"datalog": {
		"email": "E-Mail-Adresse",
		"datalogSn": "Datenlogger Seriennummer",
		"search": "Suchen",
		"Version": "Version",
		"setting": "Einstellungen",
		"upgrade": "Aktualisieren",
		"delete": "Löschen",
		"title_datalog": "Datenlogger-Einstellungen",
		"span_info": "Informationen",
		"span_datalog": "Datenlogger-Seriennummer:",
		"span_name": "Alias:",
		"span_time": "Aktualisierungszeit:",
		"span_firmware": "Signal/Firmware-Version:",
		"span_order": "Befehl",
		"span_settingdns": "Domainnamen einstellen",
		"span_inputsetting": "Bitte geben Sie den Domainnamen ein",
		"span_upgrade": "Datenlogger aktualisieren",
		"span_reset": "Datenlogger neu starten",
		"span_setup": "Erweiterte Einstellungen",
		"span_param": "Parameter",
		"span_value": "Wert",
		"btn_setup": "Erweiterte Einstellungen",
		"btn_setting": "Einstellen",
		"btn_settingdns": "Domainnamen einstellen",
		"btn_upgrade": "Aktualisieren",
		"btn_reset": "Neu starten",
		"btn_read": "Lesen",
		"btn_close": "Abbrechen",
		"methods_version": "Version aktualisieren?",
		"no": "Nein",
		"yes": "Ja",
		"methods_delete": "Löschen?",
		"methods_rereset": "Neustarten?",
		"methodes_inputDns": "Bitte geben Sie den Domainnamen ein",
		"methodes_DNS": "Diesen Domainnamen einstellen?"
	},
	"sotrage": {
		"select": "Bitte auswählen",
		"email": "Bitte E-Mail eingeben",
		"datalogSn": "Datenlogger Seriennummer",
		"deviceSn": "Geräteseriennummer",
		"search": "Suchen",
		"sotrage": "Energiespeichereinstellungen"
	},
	"device": {
		"select": "Bitte auswählen",
		"email": "Bitte E-Mail eingeben",
		"datalogSn": "Datenlogger Seriennummer",
		"deviceSn": "Geräteseriennummer",
		"search": "Suchen",
		"title": "Wechselrichtereinstellungen",
		"setting": "Einstellungen",
		"setting_inverte": "Wechselrichterparameter einstellen:",
		"input_start_address": "Startadressregister",
		"input_ent_address": "Endadressregister",
		"input_setting_value": "Bitte Einstellwert eingeben",
		"read_data": "Registerdaten lesen:",
		"input_start_read": "Startadressregister",
		"input_end_read": "Endadressregister",
		"btn_address": "Adresse setzen",
		"btn_value": "Parameter setzen",
		"btn_read": "Lesen",
		"btn_close": "Zurück",
		"selet_data": "Datum auswählen"
	},
	"battery": {
		"select": "Bitte auswählen",
		"email": "Bitte gib deine E-Mail ein",
		"datalogSn": "Bitte Datenlogger Seriennummer eingeben",
		"deviceSn": "Bitte Seriennummer eingeben",
		"search": "Suchen"
	},
	"socket": {
		"title": "Steckdosenkonfiguration",
		"setting": "Einstellung",
		"span": "Es wurden mehrere Registeradressen festgelegt, daher müssen auch mehrere Werte eingestellt werden. Die Werte müssen durch Kommas getrennt werden, zum Beispiel (1, 2, 3).",
		"setting_device": "Inverter-Parameter einstellen:",
		"startaddress": "Startregisteradresse",
		"stopaddress": "Stopregisteradresse",
		"value": "Bitte gib den Einstellwert ein",
		"readdata": "Registerdaten lesen:",
		"setting_address": "Adresse einstellen",
		"setting_value": "Parameter einstellen",
		"read": "Lesen",
		"return": "Zurück"
	},
	"agent": {
		"close": "Schließen",
		"submit": "Senden",
		"input_email": "Bitte gib deine E-Mail ein",
		"search": "Suchen",
		"add_agent": "Agent hinzufügen",
		"import_device": "Gerät importieren",
		"edit": "Bearbeiten",
		"title": "Agent hinzufügen",
		"email": "E-Mail",
		"password": "Passwort",
		"phone": "Telefonnummer",
		"company": "Firma",
		"language": "Sprache",
		"address": "Adresse",
		"input_password": "Bitte gib dein Passwort ein",
		"input_phone": "Bitte gib deine Telefonnummer ein",
		"input_company": "Bitte wähle eine Firma aus",
		"input_language": "Bitte gib deine Sprache ein",
		"input_address": "Bitte gib deine Adresse ein",
		"relus_email": "Bitte gib eine gültige E-Mail-Adresse ein",
		"title_edit": "Agent bearbeiten",
		"title_device": "Hochladen",
		"span": "Beispiel: Excel-Tabelle",
		"btn_upload": "Hochladen",
		"delete": "Löschen",
		"agent_delete": "Agent löschen?"
	},
	"ship": {
		"starttime": "Startzeit",
		"endtime": "Endzeit",
		"deviceSn": "Bitte gib die Datenlogger Seriennummer ein",
		"select": "Bitte wähle eine Firma aus",
		"search": "Suchen"
	},
	"Firmware": {
		"file_name": "Bitte gib den Dateinamen ein",
		"search": "Suchen",
		"add": "Hinzufügen",
		"delete": "Löschen",
		"title": "Firmware-Upload",
		"select_folder": "Ordner auswählen:",
		"select_folder_type": "Bitte wähle den Ordner-Typ aus",
		"craete_folder": "Neuen Ordner erstellen",
		"folder_path": "Ordnerpfad:",
		"folder_name": "Dateiname:",
		"input_folder_name": "Gib den Dateinamen ein",
		"create": "Ordner erstellen",
		"listed_files": "Dateiliste:",
		"import_folder": "Ordner importieren",
		"uplad_folder": "Ordner hochladen",
		"close": "Schließen"
	},
	"Upgrade": {
		"starttime": "Startzeit",
		"endtime": "Endzeit",
		"deviceSn": "Bitte gib die Datenlogger Seriennummer ein",
		"type": "Bitte wähle einen Typ aus",
		"search": "Suchen",
		"datalog": "Datenlogger",
		"Inverter": "Wechselrichter",
		"Control": "Steuerung"
	},
	"datalogs_logs": {
		"starttime": "Startzeit",
		"endtime": "Endzeit",
		"deviceSn": "Bitte gib die Datenlogger Seriennummer ein",
		"Version": "Bitte gib die Version ein",
		"search": "Suchen"
	},
	"Logs": {
		"starttime": "Startzeit",
		"endtime": "Endzeit",
		"deviceSn": "Bitte geben Sie die Datenlogger Seriennummer ein",
		"type": "Bitte wählen Sie den Typ aus",
		"search": "Suchen"
	},
	"Special": {
		"starttime": "Startzeit",
		"endtime": "Endzeit",
		"deviceSn": "Bitte geben Sie die Datenlogger Seriennummer ein",
		"datalogSn": "Bitte geben Sie die Datensammler-Seriennummer ein",
		"type": "Bitte wählen Sie den Typ aus",
		"search": "Suchen",
		"save": "Speichern",
		"Examples": "Beispiele: (2305210230, 2305210139)"
	},
	"App": {
		"System_Config": "Systemkonfiguration",
		"APP_Version": "App-Version veröffentlichen",
		"Android_Version": "Android-Version:",
		"IOS_Version": "iOS-Version:",
		"APP_Type": "App-Typ:",
		"New_Version": "Neue Version",
		"update_content": "Aktualisierungsinhalte (mit '/n' trennen):",
		"Whether_update": "Update erzwingen:",
		"no": "Nein",
		"yes": "Ja",
		"submit": "Senden"
	},
	"Tips": {
		"app_submit": "Informationen ändern bestätigen?",
		"firmware_delete": "Löschen bestätigen?",
		"firmware_deleteCDN": "Dateien im CDN löschen?",
		"yes": "Ja",
		"no": "Nein"
	},
	"global": {
		"DataDetails": "Datendetails",
		"device": "Wechselrichtereinstellungen",
		"device_data": "Wechselrichterparam. einstellen",
		"storage": "Energiespeichereinstellungen",
		"storage_data": "Energiespeicherparameter einstellen",
		"battery": "Batterieeinstellungen",
		"battery_data": "Batterieparameter einstellen",
		"socket": "Steckdosen-Einstellungen",
		"socket_data": "Steckdosenparameter einstellen"
	},
	"btn": {
		"setting_address": "Einstellungsadresse",
		"close": "Schließen",
		"submit": "Einreichen",
		"Setting_parameters": "Parameter einstellen",
		"Reading": "Lesen",
		"search": "Suchen",
		"add": "Hinzufügen",
		"import": "Importieren",
		"download": "Beispiel herunterladen",
		"edit": "Bearbeiten",
		"login": "Anmelden",
		"export": "Exportieren"
	},
	"form": {
		"addShip": "Gerät hinzufügen",
		"editShip": "Gerät bearbeiten",
		"import": "Geräteinformationen importieren",
		"shipSN": "Liefer-Seriennummer",
		"agent": "Vertreiber",
		"networkCall": "Netzwerk-Rückruf ausführen",
		"warrantyPeriod": "Garantiezeit",
		"shiptime": "Lieferzeit",
		"shiptype": "Lieferart",
		"classify": "Klassifizierung",
		"file": "Datei hier ablegen oder klicken, um sie hochzuladen",
		"edit_plant": "Szenario bearbeiten",
		"input_station": "Geben Sie den Szenarionamen ein",
		"lable_StationName": "Szenarioname",
		"lable_StationType": "Szenariotyp",
		"input_StationName": "Geben Sie den Szenarionamen ein",
		"edit_plant_plant": "Bearb. Kraftwerk",
		"input_station_plant": "Geben Sie Kraftwerksname ein",
		"lable_StationName_plant": "Kraftwerksname",
		"lable_StationType_plant": "Kraftwerkstyp",
		"input_StationName_plant": "Geben Sie Kraftwerksname ein"
	},
	"placeholder": {
		"select": "Auswählen",
		"choiceCountry": "Bitte wählen Sie ein Land",
		"choiceTimezone": "Bitte Zeitzone wählen",
		"enterEmail": "Bitte Briefkasten eingeben",
		"enterPhone": "Bitte geben Sie die Telefonnummer ein",
		"verificationCode": "Bitte geben Sie den Code ein",
		"enterPassword": "Bitte geben Sie ein 6-20-stelliges Passwort ein",
		"confirmPassword": "Bitte bestätigen Sie Ihr Passwort",
		"readAndAgree": "Ich habe gelesen und bin einverstanden",
		"search": "Suche",
		"searchPlantName": "Suche nach Anlagenname",
		"searchDeviceName": "Suche nach Gerätename",
		"searchDataloggerName": "Suche nach Kollektorname",
		"enterPlantName": "Anlagennamen eingeben",
		"enterPlantType": "Analgentyp eingeben",
		"enterPVCapacity": "PV-Leistung eingeben",
		"choiceInstallationDate": "Bitte wählen Sie das Installationsdatum",
		"enterPlantAddress": "Bitte geben Sie die Adresse der Anlage ein",
		"enterOldPassword": "Geben Sie das alte Passwort ein",
		"enterNewPassword": "Geben Sie das neue Passwort ein",
		"enterConfirmPassword": "Bestätigen Sie das neue Passwort",
		"enter": "Bitte geben Sie {scope} ein",
		"country": "Land",
		"city": "Stadt",
		"scanCodeAddPlant": "Bitte Scannen Sie das Kraftwerk hinzufügen!",
		"currentNoPlant": "Es gibt derzeit keine Kraftwerke, bitte hinzufügen!",
		"selectRepeatTime": "Bitte eine Wiederholungszeit auswählen",
		"enterDatalogger": "Bitte geben Sie die Seriennummer des Sammlers ein",
		"enterDeviceSN": "Bitte geben Sie die Seriennummer des Geräts ein"
	},
	"setting": {
		"general": {
			"needGreat": "soll größer sein als",
			"needSmall": "knapp",
			"gapScope": "Der eingabeabstand ist beträgt",
			"valScope": "Der eingabebereich ist hier"
		},
		"socket": {
			"switchStatus": "Steckdosenschalter",
			"repetition": "Wiederholung",
			"enableOrNot": "Aktiviert oder nicht",
			"monday": "Montag",
			"tuesday": "Dienstag",
			"wednesday": "Mittwoch",
			"thursday": "Donnerstag",
			"friday": "Freitag",
			"saturday": "Samstag",
			"sunday": "Sonntag"
		},
		"type3": {
			"dischargeScheduleMode": "Zeitgesteuerter Entladungsmodus",
			"timeDischarge": "Zeitgesteuerte Entladung",
			"workingDay": "Wochentag",
			"weekend": "Wochenende",
			"pureLightControlMode": "Reiner Lichtsteuerungsmodus",
			"stopDischarge": "SOC Low end stop discharging Einstellwert",
			"chargeTransferDischarge": "SOC Full-to-discharge-Einstellwert",
			"batterySOCSetting": "Batterie-SOC-Einstellung",
			"lowBatteryAlam": "Batterie SOC unteres Ende Alarm Einstellwert",
			"lowBatteryShutdown": "Batterie SOC unteres Ende Entladestopp Einstellwert",
			"systemSetting": "System Einstellung",
			"systemWorkMode": "Arbeitsmodus des Systems",
			"restoreFactory": "Werkseinstellung wiederherstellen",
			"loadMode": "Lademodus",
			"timingDischarge": "Zeitgesteuerter Entlademodus",
			"DCOutputNormallyClosed": "DC-Ausgang normal geschlossener Modus",
			"DCOutputNormallyOpen": "DC-Ausgang Normal Offen Modus",
			"ForcedPowerProtection": "Erzwungener Stromerhaltungsmodus",
			"mode": "Modus",
			"startTime": "Startzeit",
			"stopTime": "Endzeit",
			"onlyAddThree": "Hinzufügen von bis zu 21 Timer",
			"startCompareEnd": "Die Startzeit des Timers kann nur kleiner als die Endzeit sein.",
			"confirmClose": "Siete sicure di spegnere la macchina?"
		},
		"type6": {
			"countryCode": "Ländercode",
			"poweradjppm": "Geschwindigkeit der Leistungsregelung des Wechselrichters",
			"enaINVTR": "Fernschalter ein/aus",
			"enaSysProtIF_selfCheck": "Selbsttest der Systemschutzschnittstelle",
			"enaRestoretodefault": "Standardwert wiederherstellen",
			"UV_resopnseTime": "{num} Stufe Unterspannungsreaktionszeit",
			"UF_resopnseTime": "{num} Stufe Unterfrequenz-Ansprechzeit",
			"OV_resopnseTime": "{num} Stufe Überspannung-Ansprechzeit",
			"OF_resopnseTime": "{num} Stufe Übertaktung-Ansprechzeit",
			"open": "Aktivieren",
			"recover": "Wiederherstellen",
			"noHave": "Keine",
			"activePowerSwitch": "Schalter für Wirkleistung",
			"activePowerPermillage": "Tausendstel-Verhältnis der Wirkleistung"
		},
		"type7": {
			"paramterSetting": "Einstellung der Parameter",
			"batteryVoltage": "Batteriespannung",
			"batteryCapacity": "Kapazität der Batterie",
			"PVChargingCurrent": "Fotovoltaik-Ladestrom",
			"overVoltage": "Überspannung",
			"chargingLimitVoltage": "Ladungsgrenzspannung",
			"increaseChargeReturnVoltage": "Boost-Ladung Rücklaufspannung",
			"overDischargeVoltage": "Über Entladespannung",
			"stopchangeCurrent": "Ladestrom stoppen",
			"inverter485Address": "485 Adresse",
			"passwordValue": "Benutzer-Passwort",
			"inputPassword": "Passwort-Eingabe",
			"recordFaultCodes": "Aufzeichnung Störungscode",
			"isOpen": "Steuerung schalten",
			"equalizingChargeCmd": "Sofortiger Ladeausgleich",
			"reset": "Steuerung zurücksetzen",
			"factory": "Werkseinstellungen wiederherstellen",
			"clearAlarm": "Aktuellen Alarm löschen",
			"clearHistory": "Historie löschen",
			"generatorSwitchCmd": "Generatorumschaltung",
			"nowTimeStr": "Aktuelle Uhrzeit",
			"setVal": {
				"reset": "Zurücksetzen",
				"recover": "Wiederherstellen",
				"clearAlarm": "Warnung löschen",
				"clearHistory": "Historie löschen",
				"toggle": "Schalter"
			}
		},
		"type8": {
			"systemSwitch": "System-Schalter"
		},
		"type10": {
			"modeSelect": "Wahl des Modus",
			"standbyMode": "Standby-Modus",
			"PureOffGrid": "Rein netzunabhängiger Startmodus",
			"energyNet": "Energiezufuhr",
			"inverter0": "Wechselrichter zu lokaler Last",
			"inverter1": "Wechselrichter zur Netzeinspeisung",
			"inverter2": "Wechselrichter an lokaler Last mit Stromwandler",
			"inverter3": "Wechselrichter zu lokaler Last mit intelligenter Steckdose oder intelligentem Zähler",
			"chargePriority": "Priorität der Aufladung",
			"PVPriority": "Priorität Photovoltaik",
			"utilityPriority": "Priorität des Stromnetzes",
			"hybridModel": "Hybrid-Modus",
			"PVOnly": "Nur Fotovoltaik",
			"batteryLowAlarm": "Batterie SOC niedrig Alarmwert",
			"batteryLowShutdown": "Batterie SOC niedrig Abschaltpunkt",
			"batteryFull": "Beurteilungswert für vollen SOC der Batterie",
			"ACCharging": "AC-Laden",
			"startTime": "Startzeit",
			"endTime": "Endzeit",
			"forcedDischarge": "Erzwungene Entladung",
			"phaseGridSide": "Phase netzseitig",
			"power": "Mit Leistung",
			"current": "Strom",
			"gridConnectionStandard": "Netz standard",
			"baudRate": "485 porter",
			"disable": "Deaktivieren",
			"enable": "Aktivieren",
			"ApprovalStandards": "Zertifizierte Normen",
			"antiRefluxPower": "Potenza antivegetativa ",
			"antiRefluxSet": "L’antiflusso consente di produrre energia",
			"batFullSocSet": "SOC-Beurteilungswert der Batteriesättigung (%)",
			"auxSourceSet": "Schalter für Hilfsquelle",
			"timeSharingGridConnectedDischarge": "Wie sie ganz sicher Wissen",
			"timeSharingGridConnection": "Wie sie es sich wünschen",
			"outputPower": "Leistung",
			"onlyAddThree": "A maximum of {num} timers can be added",
			"productModelNumber": "Modell nach modell.",
			"PleaseTurnOnThe_PCS_switchFirst":"Bitte schalten Sie zuerst den PCS-Schalter ein",
			"ThereIs_a_conflictInTheChargingAndDischargingTime":"Es gibt einen Konflikt in der Lade- und Entladezeit"
		},
		"type11": {
			"switchPCS": "PCS-Schalter",
			"functionSwitch": "Funktionsschalter",
			"maxChargeVol": "Maximale Ladespannung (V)",
			"onGridMinDischargeVol": "Netzgekoppelte Mindestentladespannung (V)",
			"offGridOutputVol": "Netzunabhängige Ausgangsspannung (V)",
			"maxChargeCur": "Maximaler Ladestrom (A)",
			"onGridMaxDischargeCur": "Maximaler Entladestrom im Netzbetrieb (A)",
			"offGridMaxDisChargeCur": "Netzunabhängiger maximaler Entladestrom (A)",
			"minSocBMS": "BMS Minimaler SOC (%)",
			"minSocDischarge": "Intelligente Entladung Minimaler SOC (%)",
			"maxSocDischarge": "Intelligente Entladung Maximaler SOC (%)",
			"timeStr": "Gerät Zeit",
			"timerSetting": "Timer-Einstellung {num}",
			"chargeStartTime1": "Beginn der Ladezeit1",
			"chargeLongTime1": "Ladedauer 1",
			"chargeTimeMaxSOC1": "Ladezeit 1 Maximaler SOC (%)",
			"chargeTimeCurrent1": "Ladedauer 1 Ladestrom (A)",
			"dischargeStartTime1": "Start Entladezeit1",
			"dischargeLongTime1": "Entladedauer1",
			"dischargeTimeMaxSOC1": "Entladezeit 1 Minimaler SOC (%)",
			"dischargeTimeCurrent1": "Entladedauer 1 Entladestrom (A)",
			"chargeStartTime2": "Beginn der Ladezeit 2",
			"chargeLongTime2": "Ladedauer 2",
			"chargeTimeMaxSOC2": "Ladedauer 2 Maximaler SOC (%)",
			"chargeTimeCurrent2": "Ladedauer 2 Ladestrom (A)",
			"dischargeStartTime2": "Entladestartzeit 2",
			"dischargeLongTime2": "Entladedauer2",
			"dischargeTimeMaxSOC2": "Entladedauer 2 Minimaler SOC (%)",
			"dischargeTimeCurrent2": "Entladedauer 2 Entladestrom (A)",
			"chargeStartTime3": "Beginn der Ladezeit 3",
			"chargeLongTime3": "Ladedauer 3",
			"chargeTimeMaxSOC3": "Ladedauer 3 Maximaler SOC (%)",
			"chargeTimeCurrent3": "Aufladedauer 3 Aufladestrom (A)",
			"dischargeStartTime3": "Entladestartzeit 3",
			"dischargeLongTime3": "Entladedauer 3 Minimaler SOC (%)",
			"dischargeTimeMaxSOC3": "Entladedauer 3 Minimaler SOC (%)",
			"dischargeTimeCurrent3": "Entladedauer 3 Entladestrom (A)",
			"chargeStartTime4": "Beginn der Ladezeit 4",
			"chargeLongTime4": "Ladedauer 4",
			"chargeTimeMaxSOC4": "Ladedauer 4 Maximaler SOC (%)",
			"chargeTimeCurrent4": "Aufladedauer 4 Aufladestrom (A)",
			"dischargeStartTime4": "Entladestartzeit 4",
			"dischargeLongTime4": "Entladedauer 4",
			"dischargeTimeMaxSOC4": "Entladedauer 4 Minimaler SOC (%)",
			"dischargeTimeCurrent4": "Entladedauer 4 Entladestrom (A)",
			"functionSwitchObj": {
				"val1": "Intelligenter Modus",
				"val2": "Timer-Betrieb",
				"val3": "Selbsterzeugungs- und Selbstverbrauchsmodus"
			}
		},
		"type12": {
			"LEDSwitch": "LED-Schalter",
			"LEDSpeed": "LED-Geschwindigkeit",
			"LEDEffect": "LED-Effekt",
			"LEDBrightness": "LED-Helligkeit",
			"dataColor": "Daten Farbdarstellung",
			"LED1Color": "LED1 Farbe",
			"LED2Color": "LED2 Farbe",
			"LED3Color": "LED3 Farbe",
			"buzzerAlarm": "Warnsummer",
			"overLoadToBypass": "Überlast zum Bypass",
			"lcdRestartPage": "LCD kein Betrieb Wiederherstellung der Hauptschnittstelle",
			"overloadReset": "Wiederanlauf bei Überlast",
			"overloadTemperature": "Wiederanlauf bei Übertemperatur",
			"backLightOn": "LCD-Hintergrundbeleuchtung nicht in Betrieb",
			"inputSourceChangeAlarm": "Alarm bei Änderung der Eingangsquelle",
			"faultCodeRecord": "Aufzeichnung von Kommunikations-Softwarefehlern",
			"solarFeedToGird": "Solar Leerlauf Netzanschluss",
			"switchOptions": "Schalter-Optionen",
			"batteryParam": "Batterie-Parameter",
			"outputParam": "Ausgangsparameter",
			"ratedOutputVol": "Nennausgangsspannung",
			"ratedOutputFreq": "Nennausgangsfrequenz",
			"inputVolRange": "Eingangsspannungsbereich",
			"outPutSourcePriority": "Priorität der Ausgangsquelle",
			"batType": "Batterie-Typ",
			"batUnderVol": "Abschaltspannung der Batterie",
			"batBulkVol": "Batterie-Konstantladespannung",
			"batFloatVol": "Erhaltungsspannung der Batterie",
			"maxChargingCur": "Maximaler Ladestrom",
			"maxAcChargingCur": "Maximaler AC-Ladestrom",
			"chargeSourcePriority": "Priorität der Ladegerätquelle",
			"batReChargeVol": "Wiederherstellungs-Ladespannung",
			"batFullRestoreDischargeVol": "Entladespannung wiederherstellen",
			"solarSupplyPriority": "Solarenergie Vorrang",
			"resetPvStorage": "PV-Statistikdaten zurücksetzen",
			"countryRegulations": "Nationale Vorschriften",
			"acChargeStartTime": "Betriebsstartzeit des AC-Ladegeräts",
			"acChargeEndTime": "Betriebsstoppzeit des AC-Ladegeräts",
			"acSupplyStartTime": "Startzeit der AC-Stromversorgung",
			"acSupplyEndTime": "Anhaltezeit der AC-Stromversorgung",
			"restoreFactorySet": "Wiederherstellung der Werkseinstellungen",
			"parallelMode": "Paralleler Modus",
			"bulkChargeVol": "CV-Ladespannung",
			"batEquSet": "Batterieausgleichsfunktion",
			"batEquTime": "Batterieausgleichszeit (min)",
			"batEquDay": "Batterieladeintervall Tage (d)",
			"batEquChargeVol": "Batterieladespannung (V)",
			"batEquTimeout": "Batterieladung Zeitüberschreitung (min)",
			"equInfoSet": "Ausgleichsinformationen Einstellung",
			"val": {
				"slow": "Langsam",
				"medium": "Mittel",
				"fast": "Schnell",
				"low": "Niedrig",
				"high": "Hoch",
				"powerCycle": "Leistungszyklus",
				"powerRotation": "Leistung Rotation",
				"additionalPower": "Zusätzliche Leistung",
				"powerUnchanged": "Unveränderte Leistung",
				"solarPowerInput": "Solare Leistungsaufnahme",
				"batteryCapacity": "Batteriekapazität in Prozent",
				"percentageLoad": "Prozentualer Anteil der Last",
				"energySource": "Energiequelle",
				"batteryStatus": "Lade-/Entladezustand der Batterie",
				"blue": "Blau",
				"cyan": "Cyan",
				"green": "Grüner",
				"pink": "Rosa",
				"purple": "Lila",
				"orange": "Orange",
				"yellow": "Gelb",
				"white": "Weiß",
				"inputOvlRange1": "APL-Modus（90-280V）",
				"inputOvlRange2": "APL-Modus（170-280V）",
				"outputSource1": "Solar->Utilityl->Batterie",
				"outputSource2": "Solar->Battery->Utility",
				"chargeSourcePriority1": "Vorrang für PV",
				"chargeSourcePriority2": "PV und Energieversorger",
				"chargeSourcePriority3": "Nur PV",
				"solarSupplyPriority1": "Batterie-Last-Gemeinsam",
				"solarSupplyPriority2": "Last-Batterie-Gemeinsam",
				"countryRegulations1": "Indien",
				"countryRegulations2": "Deutschland",
				"countryRegulations3": "Südamerika",
				"parallelMode1": "Keine Parallelschaltung",
				"parallelMode2": "Einphasig parallel",
				"outPutSourcePriority1": "Priorità alle utenze",
				"outPutSourcePriority2": "Priorità all’energia solare",
				"outPutSourcePriority3": "Energia solare -> pile -> Utilità"
			}
		},
		"ops15": {
			"invSwitch": "Wechselrichter Schaltung",
			"workMode": "Modus",
			"workMode1": "Einspeisepriorität",
			"workMode2": "Netzunabhängiger Betrieb",
			"workMode3": "Selbsterzeugung und Eigenverbrauch",
			"invTime": "Wechselrichter Zeit",
			"batType": "Batterie Typ",
			"batType1": "Keine Batterie",
			"batType2": "Universal-Lithium-Batterie",
			"batType3": "Blei-Säure-Batterie",
			"batType4": "Lithium-Batterie",
			"timingTime": "Zeitmessung Zeit",
			"offGirdSoc": "Überentladung SOC (%)",
			"optimizeRevenue": "Gewinn optimieren"
		},
		"type19": {
			"totalActivePower": "Überlegene macht",
			"offgridSettings": "Und entwöhnen das netz",
			"gridConnectedControlMode": "E posizionate fuori rete",
			"offGridControlMode": "і автономні налаштування",
			"offgrid": {
				"gridNet": "Im Netz",
				"offNet": "Off-grid"
			}
		},
		"type22": {
			"parameterScope": "Parameterbereich",
			"ACStartVoltage": "Einstellung der Startspannung/Frequenz",
			"highVoltage": "AC-Start hohe Spannung",
			"lowVoltage": "AC-Start Niederspannung",
			"pvStartLowVol": "PV-Start Niederspannung",
			"acUnderVol1": "AC-Unterspannung erster Ordnung (V)",
			"acOverVol1": "AC-Überspannung erster Ordnung (V)",
			"acUnderVolTime1": "AC Unterspannung erster Ordnung Zeit (s)",
			"acOverVolTime1": "AC Überspannung erste Ordnung Zeit (s)",
			"acUnderVol2": "AC Unterspannung zweiter Ordnung (V)",
			"acOverVol2": "AC Überspannung zweiter Ordnung (V)",
			"acUnderVolTime2": "AC Unterspannung zweite Ordnung Zeit(s)",
			"acOverVolTime2": "AC-Überspannung zweite Ordnung Zeit (s)",
			"startDelayTime": "Einschaltverzögerungszeit",
			"restartDelayTime": "Wiedereinschaltverzögerungszeit",
			"ACStartFrequency": "Überspannung/Frequenz Einstellung",
			"highFrequency": "AC-Start hohe Frequenz",
			"lowFrequency": "AC-Start niedrige Frequenz",
			"acUnderFrequency1": "AC-Unterfrequenz erster Ordnung (Hz)",
			"acOverFrequency1": "AC-Überfrequenz erster Ordnung (Hz)",
			"acUnderFrequencyTime1": "AC Unterfrequenz erste Ordnung Zeit (s)",
			"acOverFrequencyTime1": "AC Überfrequenz erste Ordnung Zeit (s)",
			"acUnderFrequency2": "AC Untertaktung 2. Ordnung(Hz)",
			"acOverFrequency2": "AC Übertaktung zweite Ordnung (Hz)",
			"acUnderFrequencyTime2": "AC Untertaktung zweite Ordnung Zeit(s)",
			"acOverFrequencyTime2": "AC-Übertaktung zweite Ordnung Zeit(s)",
			"systemSetting": "Einstellung der Geräteinformationen",
			"SNset": "Einstellung der Seriennummer",
			"reactiveControlMode": "Blindleistungsregelungsmodus",
			"inputMode": "Eingangsmodus",
			"startSolpe": "Anfahrrampe",
			"restartSolpe": "Wiederanlauframpe",
			"reactivePercentage": "Blindleistung in Prozent",
			"activePercentage": "Aktiver Prozentsatz",
			"shutdown": "Fernschalter",
			"functionSelect": "Funktion Auswahl",
			"manufacturer": "Hersteller",
			"setFunctionSelect": {
				"val1": "Deaktivieren",
				"val2": "Anti-Rückstrom aktivieren"
			},
			"setManufacturer": {
				"val1": "INVISION",
				"val2": "Skyworth"
			},
			"reactiveControlModeChoice": {
				"val1": "Steuerung des Leistungsfaktors",
				"val2": "Steuerung des Blindleistungsanteils",
				"val3": "QV-Kurvensteuerung"
			},
			"inputModeChoice": {
				"val1": "Eingangsunabhängiger Modus",
				"val2": "Eingangs-Parallelbetrieb",
				"val3": "DC-Quellen-Modus"
			}
		}
	},
	"title": {
		"login": "Anmelden",
		"vistorLogin": "Anmeldung für Besucher",
		"createAccount": "Konto erstellen",
		"retrievePassword": "Passwort abrufen",
		"userAgreement": "Benutzervereinbarung",
		"privacyPolicy": "Datenschutzrichtlinien",
		"phone": "Telefon",
		"addPlant": "Solaranlage hinzufügen",
		"editPlant": "Kraftwerk bearbeiten",
		"accountInfo": "Konto-Informationen",
		"changePassword": "Passwort ändern",
		"message": "Meldungen",
		"systemMessage": "System-Meldungen",
		"faultMessage": "Fehlermeldungen",
		"deviceMessage": "Geräte-Meldungen",
		"aboutUs": "Über",
		"device": "Gerät",
		"datalogger": "Kollektor",
		"setting": "Einstellungen",
		"socketSetting": "Steckdosen-Einstellungen",
		"addSchedule": "Timer hinzufügen",
		"editSchedule": "Timer bearbeiten",
		"repetitionTime": "Zeit wiederholen",
		"addDatalogger": "Zusätzlich ausrüstung",
		"connectWifi": "Routing der Verbindung",
		"distributionNetworkStatus": "Status der Verteilung",
		"language": "Sprache",
		"selectCountry": "Land auswählen",
		"selectCity": "Stadt auswählen"
	},
	"bar": {
		"home": "Startseite",
		"energy": "Energie",
		"plant": "Solaranlage",
		"user": "Benutzer",
		"endpoint": "Endpunkt",
		"mine": "Meine",
		"my": "Konto"
	},
	"button": {
		"logOut": "Ausloggen",
		"rememberMe": "Passwort speichern",
		"forgetPassword": "Passwort vergessen",
		"register": "Registrieren",
		"ok": "OK",
		"send": "Senden",
		"auto": "Automatisch",
		"cancle": "Abbrechen",
		"yes": "Ja",
		"close": "Schließen",
		"refresh": "Aktualisieren",
		"next": "Weiter",
		"redistributionNetwork": "Neuverteilung des Netzwerks",
		"upload": "Hochladen",
		"confirm": "Bestätigen",
		"edit": "Editieren",
		"delete": "Löschen",
		"save": "Speichern",
		"allReady": "Alles gelesen",
		"selectAll": "Alles auswählen",
		"deselectAll": "Auswahl aufheben",
		"unsubscribe": "Konto löschen",
		"later": "Später",
		"upgrade": "Aktualisieren",
		"unfollow": "Unfollow",
		"add": "Zugesetzt"
	},
	"subtitle": {
		"NeedMoreHelp": "Brauchen Sie weitere Hilfe?",
		"VisitSupportCenter": "Support Center besuchen",
		"energyFlowDiagram": "Am fluss der energie",
		"selectTimezone": "Zeitzone wählen",
		"selectPlantType": "Anlagentyp wählen",
		"selectInstallationDate": "Installationsdatum wählen",
		"selectCurrency": "Währung wählen",
		"selectDateType": "Datumsart wählen",
		"selectDeviceType": "Gerätetyp wählen",
		"socketBluetooth": "Steckdose Bluetooth",
		"log": "Protokolle",
		"all": "Alle",
		"online": "Online",
		"offline": "Offline",
		"normal": "Normal",
		"abnormal": "Ausnahmen",
		"on": "Ein",
		"off": "Aus",
		"todayEnergy": "Heutige energie",
		"totalEnergy": "Gesamte energie",
		"totalRevenue": "Gesamt Einkommen",
		"plantImage": "Bild von Solaranlage",
		"plantName": "Name der Solaranlage",
		"plantType": "Anlagentyp",
		"PVCapacity": "PV-Kapazität",
		"installationDate": "Installationsdatum",
		"plantAddress": "Adresse der Anlage",
		"revenue": "Erlös",
		"timeZone": "Zeitzone",
		"updatePhone": "Update Telefon",
		"solar": "Solar",
		"grid": "Netz",
		"battery": "Batterie",
		"home": "Last",
		"load": "Last",
		"socket": "Steckdose",
		"today": "Tag",
		"day": "Tag",
		"month": "Monat",
		"year": "Jahr",
		"total": "Gesamt",
		"time": "Zeit",
		"Co2": "Co2",
		"coal": "Kohle",
		"deforestation": "Geschützte Bäume",
		"parameter": "Parameter",
		"schedule": "Zeitschaltuhr",
		"countdown": "Countdown",
		"socketSwitch": "Steckdosenschalter",
		"currency": "Währung",
		"energyPrice": "Tarif",
		"storagePlant": "Energiespeicher Kraftwerke",
		"on-GridPlant": "Netzgekoppelte Kraftwerke",
		"photo": "Profilbild",
		"email": "E-Mail",
		"account": "Konto",
		"version": "Versionen",
		"officalWebsite": "Offizielle Website",
		"SN": "Seriennummer",
		"wifiSignal": "WiFi-Signal",
		"lastSync": "Letzte Synchronisierung",
		"switch": "Umschalten",
		"happening": "Geschehen",
		"restored": "Wiederhergestellt",
		"details": "Einzelheiten",
		"warn": "Alarm",
		"fault": "Störung",
		"status": "Status",
		"level": "Grad",
		"deviceType": "Gerätetyp",
		"alarmStartTime": "Alarm-Startzeit",
		"alarmEndTime": "Alarm-Endzeit",
		"solution": "Lösung",
		"loginIn": "Anmeldung bei ......",
		"checkVersion": "Nach Updates suchen",
		"versionUpgrade": "Version aktualisieren",
		"messageDetail": "Details zur nachricht",
		"todayConsumption": "Heutiger Verbrauch",
		"totalConsumption": "Gesamtstromverbrauch",
		"dataloggerSN": "Seriennummer des Kollektors",
		"configurationGuidance": "Konfigurationsrichtlinien",
		"guideVideo": "Lehrreiches Video",
		"manualInstuction": "Reiseführer",
		"manualInstuction4G": "4G-Leitfaden",
		"manualInstuctionMasterControl": "Master Control Betriebsanleitung",
		"masterControlConfiguration": "Hauptvertriebsnetz",
		"defaultConfiguration": "Standardvertriebsnetz",
		"findEmail": "Abruf von Mailboxen",
		"findPhone": "Handy-Wiederherstellung",
		"family": "family",
		"agent": "Agenten",
		"endUsers": "Endbenutzer",
		"deviceManagement": "Geräte-Management",
		"deviceSN": "Anlage f. SN",
		"firmwareVersion": "Firmware-Version",
		"alias": "Alias",
		"information": "Informationen",
		"domainName": "Domänenname",
		"restartdatalogger": "Sammler neu starten",
		"value": "Werte",
		"customizable": "Benutzerdefiniert ",
		"ready": "Abruf",
		"deviceDetails": "Ausstattung Details",
		"startAddress": "Start-Register-Adresse",
		"endAddress": "End-Register-Adresse",
		"settingParam": "Einstellung der Parameter",
		"tariffType": "Tarif Typ",
		"fixed": "Festgelegt",
		"timeshare": "Timesharing",
		"addTime": "Zeit hinzufügen",
		"notAllDay": "Nicht den ganzen Tag",
		"dealer": "Händler",
		"dealerNumber": "händlernummer",
		"toggle": "Aus.",
		"basicParam": "Grundlegenden param",
		"realTimeParam": "Die echtzeit param",
		"export": "Herleiten",
		"localDebug": "Lokale Fehlersuche",
		"environmentalBenefit": "Gewinne für die umwelt",
		"mode": "Modus",
		"manuallyAdd": "Manuell hinzufügen"
	},
	"tip": {
		"noData": "Derzeit liegen keine Daten vor",
		"base": {
			"maxDistance": "Farthest",
			"minDistance": "Kürzlich",
			"maxSize": "Max",
			"minSize": "Min"
		},
		"request": {
			"loading": "Laden",
			"netWork": "Netzwerkverbindung fehlgeschlagen, bitte überprüfen Sie das Netzwerk",
			"connectServerFail": "Verbindung zum Server nicht möglich",
			"requestTimeout": "Zeitüberschreitung anfordern"
		},
		"validate": {
			"choiceCountry": "Bitte wählen Sie zuerst ein Land.",
			"country": "Land kann nicht leer sein, bitte wählen Sie ein Land aus.",
			"timeZone": "Zeitzone kann nicht leer sein, bitte wählen Sie die Zeitzone aus.",
			"email": "Das Email-Format ist falsch, bitte geben Sie eine korrekte Email-Adresse ein.",
			"verification": "Bitte senden Sie zuerst den Verifizierungscode.",
			"verificationError": "Der Verifizierungscode ist falsch, bitte geben Sie einen gültigen Verifizierungscode ein.",
			"passwordFormTip": "Bitte geben Sie ein 6-20-stelliges Passwort ein, das aus Zahlen und Buchstaben besteht.",
			"passwordError": "Das Passwortformat ist falsch, bitte geben Sie ein 6-20-stelliges Passwort ein, das aus Zahlen und Buchstaben besteht.",
			"passwordError2": "Bitte geben Sie ein gültiges Passwort ein (das Passwort darf keine Sonderzeichen, Symbole und Leerzeichen enthalten).",
			"validatePassword": "Die beiden von Ihnen eingegebenen Passwörter stimmen nicht überein, bitte überprüfen Sie sie und geben Sie sie erneut ein.",
			"confirmAgreement": "Bitte lesen und akzeptieren Sie zuerst die Datenschutzbestimmungen.",
			"plantName": "Der Name des Kraftwerks darf nicht leer sein, bitte geben Sie den Namen des Kraftwerks ein.",
			"plantType": "Der Kraftwerkstyp kann nicht leer sein, bitte wählen Sie den Kraftwerkstyp.",
			"pvCapacity": "Solarbatteriekapazität kann nicht leer sein, bitte geben Sie die Batteriekapazität ein.",
			"installationDate": "Anlagedatum kann nicht leer sein, bitte wählen Sie das Anlagedatum aus.",
			"city": "Stadt kann nicht leer sein, bitte wählen Sie die Stadt aus.",
			"incomeunit": "Währung kann nicht leer sein, bitte wählen Sie die Währung.",
			"energyPrice": "Strompreis kann nicht leer sein, bitte geben Sie den Strompreis ein.",
			"firstChoiceCountry": "Bitte wählen Sie zuerst ein Land aus.",
			"oldPassword": "Altes Passwort kann nicht leer sein, bitte geben Sie das alte Passwort ein.",
			"newPassword": "Neues Passwort kann nicht leer sein, bitte geben Sie das neue Passwort ein.",
			"confirmPassword": "Passwort bestätigen kann nicht leer sein, bitte bestätigen Sie das Passwort.",
			"passwordNotMatch": "Die beiden Passwörter sind nicht identisch, bitte überprüfen und bestätigen Sie sie vor dem Absenden.",
			"SN1": "Die Seriennummer darf nicht leer sein.",
			"SN2": "Die Seriennummer muss aus 10 Ziffern bestehen.",
			"noSocketTimer": "Es gibt keinen Steckdosen-Zeitschalter. Klicken Sie hier, um ihn hinzuzufügen. ",
			"cameraPermission": "Die APP benötigt die Erlaubnis der Kamera, um den Kollektor durch Scannen des Codes hinzuzufügen.",
			"searchingState": "Suche nach Bluetooth, bitte nicht wiederholt klicken.",
			"notInTheScope": "Eingabeparameter liegt nicht innerhalb des angegebenen Bereichs.",
			"enterDecimal": "Muss {num} Dezimal- oder Integer-Ziffern eingeben.",
			"integer": "Muss eine ganze Zahl sein.",
			"phone": "Handynummern unterstützen nur das chinesische Festland, andere nutzen bitte die Mailbox zum Abruf",
			"can`tEmpty": "{param} kann nicht leer sein",
			"dealerSope": "Der Bereich der Händlernummern ist 6-8-stellig",
			"firstChoicePlant": "Bitte wählen Sie zuerst das Kraftwerk"
		},
		"log": {
			"permissionOpen": "Vergewissern Sie sich, dass die Standort- und Bluetooth-Berechtigungen aktiviert sind.",
			"openBluetoothSystem": "Bluetooth Einstellungen öffnen",
			"openIosBtSystem": "Bitte beginnen sie mit dem bluetooth",
			"openLocationSystem": "Positionierungseinstellungen öffnen",
			"bluetoothAuthorization": "APP Bluetooth-Autorisierung nicht aktiviert",
			"locationAuthorization": "APP-Standortautorisierung nicht aktiviert",
			"searchSurroundingDevicesPermission": "Sichert das gelände",
			"bluetoothNotPermission": "Bluetooth ist nicht aktiviert",
			"locationNotPermission": "Die Positionsbestimmung ist nicht aktiviert",
			"bluetoothPermission": "Bluetooth ist eingeschaltet",
			"locationPermission": "Ortung ist eingeschaltet",
			"bluetoothException": "Bluetooth ist offline. Vergewissern sie sich, dass das gerät eingeschaltet ist und dass es wieder angeschlossen ist",
			"listeningFailure": "Änderungen im identitätsmuster der überwachung sind fehlgeschlagen. Bitte verbinden sie bluetooth",
			"communicationException": "Bluetooth ist defekt. Bitte verbinden sie das bluetooth wieder",
			"reconnect": "Bluetooth-Verbindung wiederherstellen",
			"connectFail": "Bluetooth-Verbindung fehlgeschlagen",
			"connect": "Bluetooth-Verbindung",
			"bluetoothService": "Bitte aktivieren Sie den Bluetooth-Dienst",
			"locationService": "Bitte aktivieren Sie die Ortungsdienste",
			"notsupport": "Die aktuelle Plattform unterstützt den Aufruf der Bluetooth-Funktion",
			"locationNotTurnOn": "Ortung ist nicht eingeschaltet, kann nicht nach Bluetooth suchen",
			"init": "Bluetooth-Initialisierung",
			"searching": "Suche nach Bluetooth in der Umgebung",
			"searchFail": "Es wurde kein Gerät gefunden. Aktivieren Sie Bluetooth und klicken Sie auf Aktualisieren.",
			"stopSearch": "Suche nach Bluetooth in der Umgebung beenden",
			"stopSearchFail": "Die Suche nach Bluetooth in der Umgebung konnte nicht beendet werden",
			"connectSuccess": "Bluetooth-Verbindung erfolgreich",
			"setMTUsSuccess": "Einstellung der maximalen Übertragungseinheit erfolgreich",
			"setMTUsFail": "Einstellung der maximalen Übertragungseinheit fehlgeschlagen",
			"closeSuccess": "Getrennte Bluetooth-Verbindung",
			"closeFail": "Trennen der Bluetooth-Verbindung fehlgeschlagen",
			"wifiLoadFail": "Laden der WiFi-Liste fehlgeschlagen, bitte manuell eingeben",
			"bluetoothAbnormal": "Die Bluetooth-Verbindung ist gestört, versuchen Sie erneut, die Verbindung herzustellen",
			"getBluetoothServiceSuccess": "Bluetooth-Gerätedienst konnte nicht abgerufen werden",
			"getBluetoothServiceFail": "Abruf des Bluetooth-Gerätedienstes fehlgeschlagen",
			"getFeatureValuesSucess": "Es konnten nicht alle Funktionswerte im angegebenen Dienst eines Bluetooth-Geräts abgerufen werden.",
			"getFeatureValuesFail": "Es konnten nicht alle Funktionswerte des angegebenen Dienstes eines Bluetooth-Geräts abgerufen werden.",
			"subscriptionEigenvalueSuccess": "Aktivieren der Benachrichtigung bei Änderung von Funktionswerten für Bluetooth-Geräte mit niedrigem Stromverbrauch, Abonnieren von Funktionswerten",
			"subscriptionEigenvalueFail": "Aktivieren der Benachrichtigung bei Änderung von Funktionswerten für Bluetooth-Geräte mit geringer Leistungsaufnahme, Abonnieren von Funktionswerten fehlgeschlagen",
			"monitoringSuccess": "Bluetooth-Geräte mit niedrigem Stromverbrauch wurden erfolgreich auf Ereignisse zur Änderung von Funktionswerten überwacht.",
			"monitoringFail": "Das Ereignis zur Änderung des Funktionswerts eines Bluetooth-Geräts mit niedrigem Stromverbrauch konnte nicht abgehört werden.",
			"CRC": "CRC konnte nicht verifiziert werden.",
			"queryWiFi": "Erfolgreiche Abfrage der WiFi-Liste",
			"queryWiFiState": "Der Befehl zur Abfrage der WiFi-Liste wurde erfolgreich ausgeführt.",
			"wifiConenct": "Abfrage des Routenverbindungsstatus",
			"wifiFail": "Bitte überprüfen Sie das Routerkonto und das Passwort und versuchen Sie, die Verbindung erneut herzustellen.",
			"connectWiFiSuccess": "Router-Verbindung erfolgreich",
			"connectWiFiFail": "Router-Verbindung fehlgeschlagen",
			"routerAccount": "Router-Kontofehler",
			"routerPassword": "Router-Passwort-Fehler",
			"gettingIP": "Erfassen der Router-IP",
			"getIPFail": "Router-IP kann nicht abgerufen werden",
			"queryServerState": "Der Befehl zur Abfrage des Interaktionsstatus zwischen dem Gerät und dem Server wurde erfolgreich ausgeführt.",
			"server03": "Es gibt 03 Dateninteraktionen",
			"server04": "Es gibt 04 Dateninteraktionen",
			"heartbeat": "Heartbeat-Dateninteraktion",
			"noInteraction": "Keine Dateninteraktion",
			"bluetoothCommunication": "Die Bluetooth-Kommunikation wurde hergestellt",
			"connectWiFi": "Verbinden mit WiFi",
			"getWiFiList": "Überprüfen der WiFi-Liste",
			"wifiName": "Der WiFi-Name kann nicht leer sein, bitte wählen Sie oder geben Sie 2.4 WiFi ein",
			"wifiPasword": "WiFi-Name darf nicht leer sein, bitte geben Sie das Passwort ein",
			"wifiPasswordError": "Das WiFi-Passwort darf keine Sonderzeichen enthalten (ǎǐò-♢∝⌒℃℉ usw.).",
			"masterControlId": "Master-ID kann nicht leer sein, bitte ID eingeben"
		},
		"addDatalogger": {
			"noSearchDevice": "Das gerät lässt sich nicht ermitteln. Bitte vergewissern sie sich, dass das gerät bluetooth eingeschaltet ist",
			"searchAroundBluetooth": "Bluetooth-Umgebung",
			"scan": "Scannen Sie den QR-Code oder geben Sie die Datenlogger-Seriennummer manuell unterhalb des QR-Codes ein.",
			"connectBluetoothFail": "Bluetooth-Verbindung fehlgeschlagen, bitte scannen Sie den QR-Code oder geben Sie die Seriennummer des Kollektors manuell unter dem QR-Code ein und verbinden Sie sich erneut."
		},
		"connectWifi": {
			"tip1": "Konfigurieren Sie das Gerät, um dem Netzwerk beizutreten",
			"tip": "Bitte wählen Sie 2.4G WiFi",
			"devicePower": "Geräteleistung",
			"enterSSID": "Bitte Router-Konto eingeben",
			"enterPassword": "Bitte Router-Passwort eingeben",
			"masterId": "Bitte geben Sie die Master Control Id ein",
			"commandSetFail": "Befehlseinrichtung fehlgeschlagen, bitte verbinden Sie den Kollektor erneut"
		},
		"configurationState": {
			"connecting": "Verbindung herstellen",
			"connectFail": "Verbindung fehlgeschlagen",
			"connectSuccess": "Verbindung erfolgreich",
			"wait": "Bitte warten",
			"addDeviceSuccess": "Gerät erfolgreich hinzugefügt"
		},
		"home": {
			"today": "Tägliche Stromerzeugung",
			"month": "Monatliche Stromerzeugung",
			"year": "Jährliche Erzeugungskapazität",
			"total": "Gesamtstromerzeugung",
			"homePower": "Gesamtlast",
			"socketPower": "Steckdosenlast",
			"off-gridPower": "Netzunabhängige Last",
			"acPower": "Energieversorger",
			"on-gridPower": "Netzgekoppelte Wirkleistung",
			"others": "andere als",
			"latestVersion": "Dies ist die aktuellste Version.",
			"frontEndLoad": "Frontend",
			"backEndLoad": "Backend-Last"
		},
		"energy": {
			"choiceDate": "Die aktuellen Daten gehen nur bis zu",
			"explain": "Beschreibung",
			"tip1": {
				"load": "Von der Lastanlage verbrauchte Energie",
				"solar": "Energie, die Sonnenenergie in Strom umwandelt, um Batterien aufzuladen oder Lasten zu versorgen",
				"grid": "Kumulierte ins Netz verkaufte Energie",
				"battery": "Die angesammelte elektrische Energie wird zum Laden der Batterie verwendet"
			},
			"tip2": "=PV-Energie*Strompreis",
			"tip3": {
				"title": "Kriterien für die Berechnung des Sozialbeitrags",
				"title2": "1 kWh gleich：",
				"Co2": "Verringerung der Kohlendioxidemissionen:",
				"coal": "Einsparung des Einsatzes von Standardkohle:",
				"deforestation": "Erhaltung der Bäume:"
			}
		},
		"plant": {
			"comprehensive": "Umfassende Sortierung",
			"totalEnergyMax": "Die höchste Gesamtleistung",
			"totalEnergyMin": "Die Gesamtleistung ist am niedrigsten",
			"realTimePower": "Echtzeit-Leistung",
			"pvcapacityMax": "Höchste PV-Leistung",
			"pvcapacityMin": "Die PV-Leistung ist am niedrigsten",
			"installationDateMax": "Das späteste Installationsdatum",
			"installationDateMin": "Das am weitesten entfernte Installationsdatum",
			"paramChoice": "Präferenzen",
			"filter": "Filter",
			"addVisitor": "Gast hinzufügen",
			"tip": "Tipp",
			"visitorTip": "Kann nur mit registrierten Benutzern geteilt werden",
			"name": "Name",
			"permission": "Berechtigungen",
			"read-write": "Lesen/Schreiben",
			"onlyread": "Schreibgeschützt",
			"visitor": "Anrufer",
			"unfollow": "Wollen Sie diesem Kraftwerk wirklich nicht mehr folgen?"
		},
		"device": {
			"power": "Leistung ",
			"type": "Typus",
			"updateTime": "Updatezeit ",
			"powerOnTime": "Elektrizitätszeit ",
			"todayEnergy": "Heutigen elektrischen ",
			"signal": "Signalstärke "
		},
		"delDevice": "Sind Sie sicher, dass Sie dieses {device} löschen möchten",
		"currentNoDevice": "Derzeit gibt es keine Gerät",
		"currentNoDatalog": "Zurzeit gibt es keine Kollektore",
		"messageInfo": {
			"storage": "Energiespeicher",
			"inverter": "Wechselrichter",
			"microInverter": "Mikro-Wechselrichter"
		},
		"userLocation": "Benutzerstandort",
		"ops": {
			"setDomainName": "Sind Sie sicher, dass Sie diese Domäne einrichten wollen?",
			"restartDatalogger": "Sind Sie sicher, dass Sie den Collector neu starten wollen?"
		},
		"opsLog": {
			"addUser": "Endbenutzer hinzufügen",
			"bindSuccess": "Bindung erfolgreich",
			"selectDate": "Date Auswahl",
			"start": "Beginn",
			"end": "Ende",
			"upgrade": "Upgrade",
			"operation": "Betrieb",
			"communication": "Nachricht",
			"type": "Typ",
			"content": "Inhalt",
			"control": "Steuerung",
			"command": "Befehl Typ",
			"reset": "Zurücksetzen",
			"serverTime": "Server-Zeit",
			"inverter": "Intervall",
			"bindAgent": "Ein netz Von händlern",
			"deleteDeviceSN": "Bitte vergewissern sie sich, dass sie gelöscht werden？",
			"recordLog": "Einträge werden protokolliert"
		},
		"setting": {
			"productModelNumberRelevancyOn-gridPower": "Und dass die leistung eines netzes nicht größer sein kann als bei der ausgewählten produktionseinheit"
		}
	},
	"productEquipment": {
		"productModelNumber": "Modell nach modell.",
		"warnMessage": "Eine warnung",
		"customInstruction": "Benutzerdefiniert definiert",
		"firmwareUpdate": "Firmware upgrade.",
		"historicalData": "Daten aus der vergangenheit.",
		"linkageInformation": "Kontaktinformationen erhalten.",
		"batchRead":"Batch-Lesen",
		"batchSetting":"Batch-Einstellung",
		"protocolDirectives":"Protokoll-Richtlinien",
		"basicSettings":"Grundeinstellungen",
		"otherSettings":"Weitere Einstellungen",
	},
	"systemAuthority": {
	    "systemSettings": 'Systemeinstellungen',
	    "authorityManagement": 'Verwaltung von Befugnissen',
	    "accountManagement": "Kontoführung",
		"Disabled":"Arbeitsunfähig",
		"enable":"ermöglichen"
	},
	"org":{
		"ChangeSupplier":'Lieferant wechseln'
	}
}
