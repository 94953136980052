
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const RouterView = () => import('@/views/index')
const Login = () => import('@/views/LoginView.vue');//登录
const Home = () => import('@/views/HomeView.vue');//首页

const UseCenter = () => import('@/views/InformationView.vue')//个人中心
const EndUser = () => import('@/views/MemberHome/EndUserView.vue')    // 终端用户
const PowerStation = () => import('@/views/Monitoring/PlanView.vue') //电站列表
const PowerStationInfo = () => import('@/views/Monitoring/plantInfo/plantInfo.vue')//电站详情
const DeviceInfo = () => import('@/views/device/deviceInfo/deviceInfo.vue') //设备详情

// 设备列表
const Collector = () => import('@/views/Monitoring/DatalogView.vue')// 采集器列表
const Inverter = () => import('@/views/list/deviceView') //逆变器列表
const StoredEnergy = () => import('@/views/list/sotrageView') //储能列表
const Battery = () => import('@/views/list/batteryView') // 电池列表
const PowerSocket = () => import('@/views/list/socketView') // 插座列表

const Agent = () => import('@/views/MemberHome/AgentView.vue')//代理商列表
const ShipmentLog = () => import('@/views/MemberHome/ShipmentlogView.vue') // 出货日志
const FirmwareUpload = () => import('@/views/FirmwareView.vue') // 固件上传
const UpGradeRecord = () => import('@/views/Upgraderecord.vue') //升级记录
const CollectorLog = () => import('@/views/System/datalog_logsView.vue') //采集器日志
const OperationLog = () => import('@/views/System/logView.vue') //操作日志
const CommunicationLog = () => import('@/views/System/Specialsetting.vue') //通讯日志
const AppVersions = () => import('@/views/System/AppreleaseView.vue') //app版本
const AuthorityManagement = () => import('@/views/Limits/limits.vue')//权限管理
const MenuBar = () => import('@/views/Limits/menu.vue')//菜单栏
const DeviceList = () => import('@/views/device/deviceList.vue');//设备日列表

// const OrgManagement = () => import('@/views/orgManagement/orgManagement.vue');//组织管理
const productManagements = () => import('@/views/productManagement/productList.vue');//产品管理
const productDetail = () => import('@/views/productManagement/productDetail.vue');//产品管理

const OrgManagement = () => import('@/views/orgManagement/orgManagement.vue');//组织管理
const deviceSeting = () => import('@/views/device/seting/deviceSeting.vue');//设备日列表


// import index from '@/views/index.vue'
export const routes = [
  { path: '/login', name: 'login', component: Login, hidden: true, meta: { title: '登录', icon: '', }, },
  { path: '/', redirect: '/login', hidden: true, },
  { path: '/home', name: "home", component: Home, meta: { title: 'sidebar.home', icon: 'icon-shuju', menuId: 9, }, },
  { path: '/information', name: "information", component: UseCenter, hidden: true, meta: { title: '个人中心', icon: '', }, },
  { path: '/enduser', name: "enduser", component: EndUser, meta: { title: 'sidebar.endbenutzer', icon: 'icon-yonghuxinxi', menuId: 10, }, },
  { path: '/device/seting/:deviceType/:deviceSn', name: "deviceSeting", component: deviceSeting, hidden: true, meta: { title: '设备设置', icon: '', }, },
  // 电站管理
  { path: '/powerStation', name: 'powerStation', component: PowerStation, meta: { title: 'sidebar.plant_plant', icon: 'icon-dianzhangaikuang', menuId: 11, }, },
  { path: '/powerStation/powerStationInfo/:id', name: 'powerStationInfo', component: PowerStationInfo, hidden: true, meta: { title: '电站详情', activeMenu: '/powerStation' } },
  { path: '/device/deviceInfo/:deviceTypeCode/:deviceSn', name: 'deviceInfo', component: DeviceInfo, hidden: true, meta: { title: '设备详情', activeMenu: '/powerStation' } },
  // 设备
  {
    path: '/deviceList', name: 'deviceList', icon: 'icon-dianzhangaikuang', component: DeviceList, meta: { title: 'sidebar.device', icon: 'icon-dianzhangaikuang', menuId: 12, },
  },
  // 产品管理
  {path: '/productManagements', name: 'productManagements', icon: 'icon-dianzhangaikuang', component: productManagements, meta: { title: 'product.management', icon: 'icon-dianzhangaikuang'},},
  // 产品管理详情
  {path: '/productDetail/:dtc', name: 'productDetail', icon: 'icon-dianzhangaikuang', component: productDetail, meta: { title: '产品详情', icon: 'icon-dianzhangaikuang'},hidden: true },


  {
    path: '/device', name: "device", component: RouterView, meta: {
      title: 'sidebar.device', icon: 'icon-yingjian',/** menuId: 12,  */   menuId: 88888,
    }, redirect: '/device/collector',
    children: [
      { path: '/device/collector', name: 'collector', component: Collector, meta: { title: 'sidebar.datalog', menuId: 14, } },
      { path: '/device/inverter', name: "inverter", component: Inverter, meta: { title: 'sidebar.inverter', menuId: 16, } },
      { path: '/device/storedEnergy', name: 'storedEnergy', component: StoredEnergy, meta: { title: 'sidebar.sotrage', menuId: 17, } },
      { path: '/device/battery', name: 'battery', component: Battery, meta: { title: 'sidebar.battery', menuId: 18, } },
      { path: '/device/powerSocket', name: 'powerSocket', component: PowerSocket, meta: { title: 'sidebar.socket', menuId: 19, } },
    ],
  },
  { path: '/agent', name: "agent", component: Agent, meta: { title: 'sidebar.agent', icon: 'icon-user-sec', menuId: 13, } },
  { path: '/shipmentLog', name: 'shipmentLog', component: ShipmentLog, meta: { title: 'sidebar.systematicSupply', icon: 'icon-liuchengtu-cheliangwancheng', menuId: 20, } },
  {
    path: '/firmware', component: RouterView, redirect: '/firmware/firmwareUpload', meta: { title: 'sidebar.firmware', icon: 'icon-a-shangchuan1', menuId: 21, },
    children: [
      { path: '/firmware/firmwareUpload', name: "firmwareUpload", component: FirmwareUpload, meta: { title: 'sidebar.firmware_up', icon: '', menuId: 24, }, },
      { path: '/firmware/upGradeRecord', name: 'upGradeRecord', component: UpGradeRecord, meta: { title: 'sidebar.firmware_log', icon: '', menuId: 25, }, },
    ]
  },
  {
    path: '/org', name: 'org', component: RouterView, meta: { title: '组织管理', icon: 'icon-nav-system-set', noCache: true }, redirect: '/org/orgManagement',
    children: [
      { // 组织管理
        path: '/org/orgManagement', name: 'orgManagement', component: OrgManagement, meta: { title: 'sidebar.organizationalManagement', icon: '', noCache: true }
      },
    ]
  },
  {
    path: '/system', title: 'sidebar.system', component: RouterView, redirect: '/system/datalog_logs', meta: { title: 'sidebar.system', icon: 'icon-nav-system-set', menuId: 22, },
    children: [
      { path: '/system/collectorLog', name: 'collectorLog', component: CollectorLog, meta: { title: 'sidebar.system_datalog_log', }, menuId: 26, },
      { path: '/system/operationLog', name: "operationLog", component: OperationLog, meta: { title: 'sidebar.system_log', menuId: 27, }, },
      { path: '/system/communicationLog', name: "communicationLog", component: CommunicationLog, meta: { title: 'sidebar.system_tongxun', menuId: 28, }, },
      { path: '/system/appVersions', name: 'appVersions', component: AppVersions, meta: { title: 'sidebar.system_app', menuId: 29, }, },
    ]
  },
  {
    path: '/authorityManagement', name: "authorityManagement", component: AuthorityManagement, meta: { title: '权限管理', icon: 'icon-dianzhangaikuang', menuId: 23, },
    TOutput: true//是否不编译输出原文
  },
  {
    path: '/menuBar', name: 'menuBar', icon: 'icon-dianzhangaikuang', component: MenuBar, meta: { title: '菜单栏', icon: 'icon-dianzhangaikuang', menuId: 58, },
    TOutput: true//是否不编译输出原文
  },


  { path: '/404', component: () => import('@/views/errorPage/404.vue'), hidden: true },
  { path: '*', redirect: '/404', hidden: true },
  // 未完成// 组织管理

]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
