/**
 * @description: 输入框限制输入数字和浮点数
 * @author:  
 * @param {type}
 * @empty 整数 - 输入框加v-inputNumber， 小数 v-inputNumber.float=“num”   数后取几位 取决于“num”
 * 整数输入：当没有传入 .float 参数时，默认为整数输入。
 * 小数输入：传入 .float="2" 参数时，允许用户输入最多两位小数。
 * 限制小数位数：通过传入的参数 binding.value 来限制小数的最大位数。
 * 空字符串处理：如果用户清空了输入框，确保输入框的值为空字符串。
 * 防抖优化：通过引入 lodash 的 debounce 函数来限制事件处理的频率，提高性能。
 * 清理机制：在 unbind 钩子中移除事件监听器，防止内存泄漏。
*/
// 引入 lodash 的 debounce 函数来防抖
import { debounce } from 'lodash';


export default {
    bind(element, binding) {
        // 设置输入框类型为number
        //   el.type = 'number';
        let el = getInput(element)

        // 获取小数位数，默认为 0 表示整数
        const decimalPlaces = binding.value ? parseInt(binding.value, 10) : 0;

        // 监听input事件以实时限制输入
        const handleInput = debounce(() => {
            let value = el.value;

            // 使用正则表达式来移除非数字字符，并且只保留一个有效的小数点
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

            // 确保小数点前有数字
            if (value.startsWith('.')) {
                value = '0' + value;
            }

            // 确保小数点后有数字
            if (value.endsWith('.') || value === '.') {
                value = value.slice(0, -1);
            }

            // 限制小数位数
            if (decimalPlaces > 0 && value.includes('.')) {
                const [integerPart, decimalPart] = value.split('.');
                value = `${integerPart}.${decimalPart.substring(0, decimalPlaces)}`;
            } else if (decimalPlaces === 0 && value.includes('.')) {
                value = value.split('.')[0];
            }

            // 如果值变化了，则更新输入框的值
            if (el.value !== value) {
                el.value = value;
            }
        }, 350); // 调整时间间隔以适应需求

        el.addEventListener('input', handleInput);

        // 清理事件监听器
        el._cleanup = () => {
            el.removeEventListener('input', handleInput);
        };
    },

    unbind(el) {
        // 移除事件监听器
        if (el._cleanup) {
            el._cleanup();
        }
    }
}
function getInput(el) {
    let inputEle;
    if (el.tagName !== 'INPUT') {
        inputEle = el.querySelector('input');
    } else {
        inputEle = el;
    }
    return inputEle;
}