export default {
	"language": {
		"name": "English"
	},
  product:{
    "PleaseEnter": "Please enter the product name",
    "PleaseEnterTheProductSerialNumber": "Please enter the product serial number",
    "PleaseEnterSubmodelNumber": "Please enter submodel",
    "PleaseenterTheSubmodelSerialNumber": "Please enter the submodel serial number",
    "PleaseSelectTheDeviceType": "Please select the device type",
    "PleaseSelectDevelopmentType": "Please select development type",
    "management": "product management",
    "name": "product name",
    "reset": "reset",
    "search": "search",
    "ApplicationList": "application list",
    "newProduct": "new product",
    "editName": "edit name",
    "DetailSetting": "detail setting",
    "delete": "delete",
    "serialNumber": "serial number",
    "deviceType": "device type",
    "RandDtype": "R&D type",
    "subType": "sub-type",
    "updateTime": "update time",
    "operation": "operation",

    "deviceList": "Device List (Shipping Log)",
    "parameterManagement": "Parameter Management",
    "updataManagement": "Update Management",

    "ParentDevice": "Parent Device",
    "AssociatedPowerStationState": "Associated Power Station State",
    "ImportDeviceList": "Import Device List",
    "PlantDetails": "Power Plant Details",
    "parameterName": "Parameter Name",

    "OwningPowerStation": "Owning Power Station",

    "ParameterClass": "Parameter Class",
    "ParameterSettingType": "Parameter Setting Type",
    "ParameterOperationType": "Parameter Operation Type",
    "ImportParameter": "Import Parameter",
    "OwningUpperLevelParameterCategory": "Owning Upper Level Parameter Category",
    "WhetherYoSupportBatchRead": "Whether to Support Batch Read",
    "value": "value",
    "scope": "scope",
    "unit": "unit",
    "explain": "explain",
    "remark": "remark",

    "fileName": "File Name",
    "fileAddress": "File Address",
    "versionNumber": "Version Number",
    "ProductOfOwnership": "Product of Ownership",
    
    "ProductDetailsSettings": "Balcony 1.0 - Product Details Settings",
    "QueryResultList": "Query Result List",
    "AddFirmware": "Add Firmware",
    "NewParameter": "New Parameter",

    "datalog": "Data Logger",
    "Inverter": "Inverter",
    "storage": "Storage",
    "battery": "Battery",
    "socket": "Socket",

    "DownloadTemplate": "Download Template",
    "nation": "nation",
    "agent": "agent",
    "expirationDate": "expiration date",
    "UpdateTime": "Update Time",
    "LastWeek": "Last Week",
    "LastMonth": "Last Month",
    "LastThreeMonths": "Last Three Months",

    "parameterType": "parameter type",
    "ProtocolAddress": "protocol address",
    "stepSize": "step size",
    "PleaseEnterAParameterName": "Please enter a parameter name",
    "parameterCoding": "parameter coding",
    "PleaseEnterTheParameterCode": "Please enter the parameter code",
    "ParameterDataType": "parameter data type",
    "PleaseEnterTheAddressInTheParameterProtocol": "Please enter the address in the parameter protocol",
    "blockEncoding": "block encoding",
    "PleaseEnterABlockCode": "Please enter a block code",
    "GroupName": "group name",
    "PleaseEnterAGroupName": "Please enter a group name",
    "PleaseEnterTheUnit": "Please enter the unit",
    "decimalPoint": "decimal point",
    "PleaseEnterTheDecimalPoint": "Please enter the decimal point",
    "leastValue": "least value",
    "PleaseEnterTheUnit": "Please enter the unit", // 注意：此处重复，应为 "Please enter the least value"
    "maximumValue": "maximum value",
    "PleaseEnterTheMaximumValue": "Please enter the maximum value",
    "PleaseEnterTheStepSize": "Please enter the step size",
    "addEquipment": "add equipment",
    "equipment": "equipment",
    "remove": "remove",
    "sort": "sort",
    "PleaseEnterTheOrder": "Please enter the order",
    "PleaseEnterTheParameterSettingType": "Please enter the parameter setting type",

    "versionNumber": "version number",
    "ProductOfOwnership": "product of ownership",
    "PleaseSelectAFolderPath": "please select a folder path",
    "ReturnToSuperior": "return to superior",
    "PleaseEnterTheVersionNumber": "please enter the version number",
    "firmwareSpecification": "firmware specification",
    "PleaseEnterFirmwareDescription": "please enter firmware description",
    
    "FileName": "file name",
    "DeviceType": "device type",
    "FileAddress": "file address",
    "time": "time",

  },
	"sidebar": {
		"home": "Data Overview",
		"endbenutzer": "User List",
		"plant": "Scene",
		"plant_plant": "Plant",
		"device": "Device List",
		"datalog": "Datalog ",
		"inverter": "Inverter ",
		"sotrage": "Sotrage ",
		"battery": "Battery ",
		"socket": "Socket",
		"agent": "Agent ",
		"systematicSupply": "Delivery Log",
		"firmware": "Firmware ",
		"firmware_up": "Firmware Upload",
		"firmware_log": "Upgrade Records",
		"system": "Settings",
		"system_datalog_log": "Datalog Logs",
		"system_log": "Operational Logs",
		"system_tongxun": "Newsletter Logs",
		"system_app": "App Version",
		"personal": "User Information",
		"logout": "logout",
		"organizationalManagement":"Organizational management"
	},
	"login": {
		"email": "Email",
		"password": "Password",
		"remember": "Remember Email and password",
		"enteremail": "Please enter email",
		"enterpassword": "Please enter password"
	},
	"home": {
		"echarts1": {
			"title": "Registe User",
			"subhead": "Total number of new/users added today"
		},
		"echarts2": {
			"title": "Login User",
			"subhead": "Total number of logins/users today"
		},
		"echarts3": {
			"title": "Login Agent",
			"subhead": "Today login/total number of agents"
		},
		"echarts4": {
			"title": "Add Scene",
			"subhead": "New Scenes/Total Number of Scenes",
			"title_plant": "Add Plant",
			"subhead_plant": "New Plants/Total Plants"
		},
		"echarts5": {
			"title": "Add Datalog",
			"subhead": "The total number of new datalog/Datalog"
		},
		"echarts6": {
			"title": "Add Device",
			"subhead": "New Device/total number of device"
		}
	},
	"End_user": {
		"input_email": "Please enter the user email",
		"btn_search": "Search",
		"btn_add": "Add",
		"btn_delete": "Delete",
		"btn_binding": "Binding",
		"add_station": "Add Scene",
		"lable_StationName": "Scene Name",
		"lable_StationType": "Scene Type",
		"lable_PVCapacity": "PV Capacity",
		"lable_InstalltionDate": "Installtion Date",
		"lable_Country": "Country",
		"lable_City": "City",
		"lable_Address": "Address",
		"lable_TimeZone": "Time Zone",
		"lable_Energyprice": "Energy price",
		"lable_IncomeUnit": "Income Unit",
		"input_StationName": "Please enter Scene name",
		"input_StationType": "Please select type",
		"input_PVCapacity": "Please enter capacity",
		"input_InstalltionDate": "Please select date",
		"input_Country": "Please select country",
		"input_City": "Please select city",
		"input_Address": "Please enter address",
		"input_TimeZone": "Please select time zone",
		"input_Energyprice": "Please enter energy price",
		"input_IncomeUnit": "Please select unit",
		"max_20": "The maximum size is 20 digits",
		"max_5": "The maximum size is 5 digits",
		"max_8": "The maximum size is 8 digits",
		"format": "Please enter the correct format",
		"close": "Close",
		"submit": "Submit",
		"title_delete": "Delete？",
		"no": "No",
		"yes": "Yes",
		"Bingding_Agent": "Bingding Agent",
		"Agent_Code": "Agent Code:",
		"please_select": "please select"
	},
	"plant": {
		"input_email": "Please enter the user email",
		"search": "Search",
		"edit": "Edit",
		"delete": "Delete",
		"edit_plant": "Edit scene",
		"input_station": "Please enter scene name",
		"lable_StationName": "Scene name",
		"lable_StationType": "Scene type",
		"lable_PVCapacity": "PV Capacity",
		"lable_InstalltionDate": "Installtion Date",
		"lable_Country": "Country",
		"lable_City": "City",
		"lable_Address": "Address",
		"lable_TimeZone": "Time Zone",
		"lable_Energyprice": "Energy price",
		"lable_IncomeUnit": "Income Unit",
		"input_StationName": "Please enter Scene name",
		"input_StationType": "Please select type",
		"input_PVCapacity": "Please enter capacity",
		"input_InstalltionDate": "Please select date",
		"input_Country": "Please select country",
		"input_City": "Please select city",
		"input_Address": "Please enter address",
		"input_TimeZone": "Please select time zone",
		"input_Energyprice": "Please enter energy price",
		"input_IncomeUnit": "Please select unit",
		"max_20": "The maximum size is 20 digits",
		"max_5": "The maximum size is 5 digits",
		"max_8": "The maximum size is 8 digits",
		"format": "Please enter the correct format",
		"close": "Close",
		"submit": "Submit",
		"title_delete": "Delete？",
		"no": "No",
		"yes": "Yes"
	},
	"datalog": {
		"email": "Please enter the user email",
		"datalogSn": "Please enter the WiFi SN",
		"search": "Search",
		"Version": "Version",
		"setting": "Setting",
		"upgrade": "Upgrade",
		"delete": "Delete",
		"title_datalog": "Datalog setting",
		"span_info": "Information",
		"span_datalog": "Datalog SN",
		"span_name": "Another name",
		"span_time": "Update time",
		"span_firmware": "Signal/firmware version",
		"span_order": "Order",
		"span_settingdns": "DNS Setting",
		"span_inputsetting": "Please enter DSN",
		"span_upgrade": "Upgrade Datalog",
		"span_reset": "Reset Datalog",
		"span_setup": "Advanced Setup",
		"span_param": "Param No",
		"span_value": "Value",
		"btn_setup": "Advanced Setup",
		"btn_setting": "Setting",
		"btn_settingdns": "DNS Setting",
		"btn_upgrade": "Upgrade",
		"btn_reset": "Reset",
		"btn_read": "Read",
		"btn_close": "Close",
		"methods_version": "Detect version update ?",
		"no": "NO",
		"yes": "YES",
		"methods_delete": "Delete?",
		"methods_rereset": "Reset?",
		"methodes_inputDns": "Please enter a domain name",
		"methodes_DNS": "Whether this domain name is set"
	},
	"sotrage": {
		"select": "Please select",
		"email": "Please enter the user email",
		"datalogSn": "Please enter the datalog SN",
		"deviceSn": "Please enter the device SN",
		"search": "Search"
	},
	"device": {
		"select": "Please select",
		"email": "Please enter the user email",
		"datalogSn": "Please enter the datalog SN",
		"deviceSn": "Please enter the device SN",
		"search": "Search",
		"title": "Inverter Setting",
		"setting": "Setting",
		"setting_inverte": "Setting Inverter Param:",
		"input_start_address": "Start Address",
		"input_ent_address": "Stop Address",
		"input_setting_value": "Please enter the setting value",
		"read_data": "Read register data:",
		"input_start_read": "Start register address",
		"input_end_read": "Address of the termination register",
		"btn_address": "Setting address ",
		"btn_value": "set up parameters",
		"btn_read": "Read",
		"btn_close": "Close",
		"selet_data": "Select Date"
	},
	"battery": {
		"select": "Please select",
		"email": "Please enter the user email",
		"datalogSn": "Please enter the datalog SN",
		"deviceSn": "Please enter the device SN",
		"search": "Search"
	},
	"socket": {
		"title": "socket setting",
		"setting": "setting",
		"span": " If multiple register addresses are set, multiple values are set. Set values separated by, example (1,2,3)",
		"setting_device": "Set parameters:",
		"startaddress": "start address",
		"stopaddress": "Stop address",
		"value": "Value",
		"readdata": "Read the register data:",
		"setting_address": "Setting address",
		"setting_value": "setup",
		"read": "Read",
		"return": "return"
	},
	"agent": {
		"close": "Close",
		"submit": "Submit",
		"input_email": "Please enter password",
		"search": "Search",
		"import_device": "import Device",
		"edit": "Edit",
		"add_agent": "Add Agent",
		"title": "Add Agent",
		"email": "Email",
		"password": "Password",
		"phone": "Phone",
		"company": "Company",
		"language": "Language",
		"address": "Address",
		"input_password": "Please enter password",
		"input_phone": "Please enter phone",
		"input_company": "Please select company",
		"input_language": "Please enter language",
		"input_address": "Please enter address",
		"relus_email": "Please enter a valid email address",
		"title_edit": "Edit Agent",
		"title_device": "Upload",
		"span": "Example: Excle table ",
		"btn_upload": "Upload",
		"delete": "Delete",
		"agent_delete": "Delete agent ?"
	},
	"ship": {
		"starttime": "Start time",
		"endtime": "End time",
		"deviceSn": "Please enter the device SN",
		"select": "Please select company",
		"search": "Search"
	},
	"Firmware": {
		"file_name": "Please enter file name",
		"search": "Search",
		"add": "Add",
		"delete": "Delete",
		"title": "Upload File",
		"select_folder": "Select folder:",
		"select_folder_type": "Select a folder type",
		"folder_name": "Folder name:",
		"input_folder_name": "Please enter file name",
		"craete_folder": "Create a new folder",
		"folder_path": "Folder path：",
		"create": "Create Folder",
		"listed_files": "Listed files：",
		"import_folder": "Import file",
		"uplad_folder": "Upload files",
		"close": "Close"
	},
	"Upgrade": {
		"starttime": "Start time",
		"endtime": "End time",
		"deviceSn": "Please enter the device SN",
		"type": "Please select type",
		"search": "Search",
		"datalog": "Datalog",
		"Inverter": "Inverter",
		"Control": "Control"
	},
	"datalogs_logs": {
		"starttime": "Start time",
		"endtime": "End time",
		"deviceSn": "Please enter the device SN",
		"Version": "Please enter the version",
		"search": "Search"
	},
	"Logs": {
		"starttime": "Start time",
		"endtime": "End time",
		"deviceSn": "Please enter the device SN",
		"type": "Please select command type",
		"search": "Search"
	},
	"Special": {
		"starttime": "Start time",
		"endtime": "End time",
		"deviceSn": "Please enter the device SN",
		"datalogSn": "Please enter the datalog SN",
		"type": "Please select command type",
		"search": "Search",
		"save": "Save",
		"Examples": "Examples：(2305210230,2305210139)"
	},
	"App": {
		"System_Config": "System Config",
		"APP_Version": "APP Version Release",
		"Android_Version": "Android Version:",
		"IOS_Version": "IOS Version:",
		"APP_Type": "APP Type:",
		"New_Version": "New Version:",
		"update_content": "Update Content Description:(use ‘ /n ’ divide each point )",
		"Whether_update": "Whether to force update:",
		"no": "NO",
		"yes": "YES",
		"submit": "Submit"
	},
	"Tips": {
		"app_submit": "Make sure to modify the information?",
		"firmware_delete": "Confirm deletion?",
		"firmware_deleteCDN": "Delete files in CDN?",
		"yes": "Yes",
		"no": "No"
	},
	"global": {
		"DataDetails": "Data Details",
		"device": "Inverter Settings",
		"device_data": "Set Inverter Parameters",
		"storage": "Energy Storage Settings",
		"storage_data": "Set Energy Storage Parameters",
		"battery": "Battery Settings",
		"battery_data": "Set Battery Parameters",
		"socket": "Socket Settings",
		"socket_data": "Set Socket Parameters"
	},
	"btn": {
		"setting_address": "Set Address",
		"close": "Close",
		"submit": "Submit",
		"Setting_parameters": "Setting Parameters",
		"Reading": "Reading",
		"search": "Search",
		"add": "Add",
		"import": "Import",
		"download": "Sample Documentation",
		"edit": "Edit",
		"login": "Login",
		"export": "export"
	},
	"form": {
		"addShip": "Add Shipping Device",
		"editShip": "Edit Shipping Device",
		"import": "Import device information",
		"shipSN": "Shipping Serial Number",
		"agent": "Agent",
		"networkCall": "Execute Network Callback",
		"warrantyPeriod": "Warranty Period",
		"shiptime": "Shipping Time",
		"shiptype": "Shipping Type",
		"classify": "Classification",
		"file": "Drag file here or click to upload",
		"edit_plant": "Edit Scenario",
		"input_station": "Enter Scenario Name",
		"lable_StationName": "Scenario Name",
		"lable_StationType": "Scenario Type",
		"input_StationName": "Enter Scenario Name",
		"edit_plant_plant": "Edit Power Plant",
		"input_station_plant": "Enter Power Plant Name",
		"lable_StationName_plant": "Power Plant Name",
		"lable_StationType_plant": "Power Plant Type",
		"input_StationName_plant": "Enter Power Plant Name"
	},
	"placeholder": {
		"select": "Please select",
		"choiceCountry": "Please choice country",
		"choiceTimezone": "Please choice time zone",
		"enterEmail": "Please enter email",
		"enterPhone": "Please enter phone number",
		"verificationCode": "Please enter verification code",
		"enterPassword": "Please enter a 6-20 digit password",
		"confirmPassword": "Please confirm password",
		"readAndAgree": "I have read and agree",
		"search": "Search",
		"searchPlantName": "Search plant name",
		"searchDeviceName": "Search device name",
		"searchDataloggerName": "Search datalogger name",
		"enterPlantName": "Please enter plant name",
		"enterPlantType": "Please enter plant type",
		"enterPVCapacity": "Please enter PV capacity",
		"choiceInstallationDate": "Please choice installation date",
		"enterPlantAddress": "Please enter plant address",
		"enterOldPassword": "Please enter old password",
		"enterNewPassword": "Please enter new password",
		"enterConfirmPassword": "Please enter confirm password",
		"enter": "Please enter {scope}",
		"country": "Country",
		"city": "City",
		"scanCodeAddPlant": "Please scan the code to add!",
		"currentNoPlant": "Current no plant, please click add!",
		"selectRepeatTime": "Please select a repeat time",
		"enterDatalogger": "Please enter datalogger",
		"enterDeviceSN": "Please enter device SN"
	},
	"setting": {
		"general": {
			"needGreat": "need to be greater than",
			"needSmall": "needs to be less than",
			"gapScope": "The interval of the input values is",
			"valScope": "The range of input values is"
		},
		"socket": {
			"switchStatus": "Socket switch",
			"repetition": "Repetition",
			"enableOrNot": "Enable or not",
			"monday": "Monday",
			"tuesday": "Tuesday",
			"wednesday": "Wednesday",
			"thursday": "Thursday",
			"friday": "Friday",
			"saturday": "Saturday",
			"sunday": "Sunday"
		},
		"type3": {
			"dischargeScheduleMode": "Discharge schedule mode",
			"timeDischarge": "Timed discharge",
			"workingDay": "Working day",
			"weekend": "Weekend",
			"pureLightControlMode": "Pure light control mode",
			"stopDischarge": "SOC Low end stop discharging setting value",
			"chargeTransferDischarge": "SOC Full-to-discharge setting value",
			"batterySOCSetting": "Battery SOC setting",
			"lowBatteryAlam": "Battery SOC low end alarm setting",
			"lowBatteryShutdown": "Battery SOC low end stop discharge setting",
			"systemSetting": "System setting",
			"systemWorkMode": "System work mode",
			"restoreFactory": "Restore factory",
			"loadMode": "Load mode",
			"timingDischarge": "Timing discharge",
			"DCOutputNormallyClosed": "DC output normally closed",
			"DCOutputNormallyOpen": "DC output normally open",
			"ForcedPowerProtection": "Forced power protection",
			"mode": "Mode",
			"startTime": "Start time",
			"stopTime": "Stop time",
			"onlyAddThree": "A maximum of 21 timers can be added",
			"startCompareEnd": "The start time of a timer can only be less than the end time",
			"confirmClose": "Are you sure you want to turn it off?"
		},
		"type6": {
			"countryCode": "Country code",
			"poweradjppm": "Inverter power to adjust the speed",
			"enaINVTR": "Remote switch",
			"enaSysProtIF_selfCheck": "System protection interface self-check",
			"enaRestoretodefault": "Restore default",
			"UV_resopnseTime": "{num} level UV response time",
			"UF_resopnseTime": "{num} level UF response time",
			"OV_resopnseTime": "{num} level OV response time",
			"OF_resopnseTime": "{num} level OF response time",
			"open": "Open",
			"recover": "Recover",
			"noHave": "None",
			"activePowerSwitch": "Active power switch",
			"activePowerPermillage": "Active Power Thousandths Ratio"
		},
		"type7": {
			"paramterSetting": "Paramter setting",
			"batteryVoltage": "Battery voltage",
			"PVChargingCurrent": "PV charging current",
			"batteryCapacity": "Battery capacity",
			"overVoltage": "Over voltage",
			"chargingLimitVoltage": "Charging limit voltage",
			"increaseChargeReturnVoltage": "Increase charge return voltage",
			"overDischargeVoltage": "Over discharge voltage",
			"stopchangeCurrent": "Stopchange current",
			"inverter485Address": "Inverter 485 address",
			"passwordValue": "Password value",
			"inputPassword": "Input password",
			"recordFaultCodes": "Record fault codes",
			"isOpen": "Is open",
			"equalizingChargeCmd": "Equalizing charge cmd",
			"reset": "Reset",
			"factory": "Factory",
			"clearAlarm": "Clear alarm",
			"clearHistory": "Clear history",
			"generatorSwitchCmd": "Generator switch cmd",
			"nowTimeStr": "Current time",
			"setVal": {
				"reset": "Reset",
				"recover": "Recover",
				"clearAlarm": "Clear Alarm",
				"clearHistory": "Clear History",
				"toggle": "Toggle"
			}
		},
		"type8": {
			"systemSwitch": "System Switch"
		},
		"type10": {
			"modeSelect": "Mode Selection",
			"standbyMode": "backup mode",
			"PureOffGrid": "Pure off-grid startup mode",
			"energyNet": "Energy net",
			"inverter0": "Inverter to local load",
			"inverter1": "Inverter to Grid Generation",
			"inverter2": "Inverter to local load with CT",
			"inverter3": "Inverter to local load smart socket or smart meter",
			"chargePriority": "Charge Priority",
			"PVPriority": "PV Priority",
			"utilityPriority": "Utility Priority",
			"hybridModel": "Hybrid Model",
			"PVOnly": "PV only",
			"batteryLowAlarm": "Battery SOC low alarm value",
			"batteryLowShutdown": "Battery SOC low shutdown point",
			"batteryFull": "Battery full SOC judgment value",
			"ACCharging": "AC charging",
			"startTime": "Start time",
			"endTime": "End time",
			"forcedDischarge": "Forced Discharge",
			"phaseGridSide": "Phase grid side",
			"power": "Power",
			"current": "Current",
			"gridConnectionStandard": "Grid connection standard",
			"baudRate": "485 baud rate",
			"disable": "Disable",
			"enable": "Enable",
			"ApprovalStandards": "approval standards",
			"antiRefluxPower": "Anti-reverse current power ",
			"antiRefluxSet": "Anti-countercurrent enable",
			"batFullSocSet": "Battery saturation SOC judgment value (%)",
			"auxSourceSet": "Auxiliary Source Switch",
			"timeSharingGridConnectedDischarge": "Time-sharing grid-connected discharge",
			"timeSharingGridConnection": "Time-sharing grid connection",
			"outputPower": "output power",
			"onlyAddThree": "A maximum of {num} timers can be added ",
			"productModelNumber": "product model number",
			"PleaseTurnOnThe_PCS_switchFirst":"Please turn on the PCS switch first",
			"ThereIs_a_conflictInTheChargingAndDischargingTime":"There is a conflict in the charging and discharging time"
		},
		"type11": {
			"switchPCS": "PCS switch",
			"functionSwitch": "Function switch",
			"maxChargeVol": "Maximum charging voltage（V）",
			"onGridMinDischargeVol": "Minimum discharge voltage grid（V）",
			"offGridOutputVol": "Off-grid output voltage（V）",
			"maxChargeCur": "Maximum charging current(A)",
			"onGridMaxDischargeCur": "Maximum grid-connected discharge current(A)",
			"offGridMaxDisChargeCur": "Maximum off-grid discharge current(A)",
			"minSocBMS": "BMS Minimum SOC（%）",
			"minSocDischarge": "Minimum SOC for intelligent discharge（%）",
			"maxSocDischarge": "Maximum SOC for intelligent discharge（%）",
			"timeStr": "Device time",
			"timerSetting": "Timer setting{num}",
			"chargeStartTime1": "Charge Start Time 1",
			"chargeLongTime1": "Charge Long Time 1",
			"chargeTimeMaxSOC1": "Charging period 1 Max SOC（%）",
			"chargeTimeCurrent1": "Charge period 1 Charge current(A)",
			"dischargeStartTime1": "Discharge Start Time 1",
			"dischargeLongTime1": "Discharge Long Time 1",
			"dischargeTimeMaxSOC1": "Discharge time 1 min SOC（%）",
			"dischargeTimeCurrent1": "Discharge period 1 Discharge current (A)",
			"chargeStartTime2": "Charge Start Time 2",
			"chargeLongTime2": "Charge Long Time 2",
			"chargeTimeMaxSOC2": "Charging time 2 max SOC（%）",
			"chargeTimeCurrent2": "Charge period 2 Charge current(A)",
			"dischargeStartTime2": "Discharge Start Time 2",
			"dischargeLongTime2": "Discharge Long Time 2",
			"dischargeTimeMaxSOC2": "Discharge time 2 min SOC（%）",
			"dischargeTimeCurrent2": "Discharge period 2 Discharge current(A)",
			"chargeStartTime3": "Charge Start Time 3",
			"chargeLongTime3": "Charge Long Time 3",
			"chargeTimeMaxSOC3": "Charging time 3 max SOC（%）",
			"chargeTimeCurrent3": "Charge period 3 Charge current(A)",
			"dischargeStartTime3": "Discharge Start Time 3",
			"dischargeLongTime3": "Discharge Long Time 3",
			"dischargeTimeMaxSOC3": "Discharge time 3 min SOC（%）",
			"dischargeTimeCurrent3": "Discharge period 3 Discharge current(A)",
			"chargeStartTime4": "Charge Start Time 4",
			"chargeLongTime4": "Charge Long Time 4",
			"chargeTimeMaxSOC4": "Charging period 4 Max SOC（%）",
			"chargeTimeCurrent4": "Charge period 4 Charge current(A)",
			"dischargeStartTime4": "Discharge Start Time 4",
			"dischargeLongTime4": "Discharge Long Time 4",
			"dischargeTimeMaxSOC4": "Discharge time 4 min SOC（%）",
			"dischargeTimeCurrent4": "Discharge period 4 Discharge current(A)",
			"functionSwitchObj": {
				"val1": "Intelligent mode",
				"val2": "Timing mode",
				"val3": "Self-Use mode"
			}
		},
		"type12": {
			"LEDSwitch": "LED Switch",
			"LEDSpeed": "LED Speed",
			"LEDEffect": "LED Effect",
			"LEDBrightness": "LED Brightness",
			"dataColor": "Data Color Representation",
			"LED1Color": "LED1 Color",
			"LED2Color": "LED2 Color",
			"LED3Color": "LED3 Color",
			"buzzerAlarm": "Buzzer alarm",
			"overLoadToBypass": "Overload to bypass",
			"lcdRestartPage": "LCD no operation restore main interface",
			"overloadReset": "Overload restart",
			"overloadTemperature": "Over temperature restart",
			"backLightOn": "LCD no operation backlight",
			"inputSourceChangeAlarm": "Input source change alarm",
			"faultCodeRecord": "Communication Software Failure Record",
			"solarFeedToGird": "Solar Idle Grid Connection",
			"switchOptions": "Switch Options",
			"batteryParam": "Battery Parameters",
			"outputParam": "Output Parameters",
			"ratedOutputVol": "Rated Output Voltage",
			"ratedOutputFreq": "Rated Output Frequency",
			"inputVolRange": "Input Voltage Range",
			"outPutSourcePriority": "Output Source Priority",
			"batType": "Battery Type",
			"batUnderVol": "Battery Cutoff Voltage",
			"batBulkVol": "Battery Constant Charge Voltage",
			"batFloatVol": "Battery Float Voltage",
			"maxChargingCur": "Maximum Charge Current",
			"maxAcChargingCur": "Maximum AC Charge Current",
			"chargeSourcePriority": "Charger Source Priority",
			"batReChargeVol": "Recovery Charging Voltage",
			"batFullRestoreDischargeVol": "Resume Discharge Voltage",
			"solarSupplyPriority": "Solar Power Priority",
			"resetPvStorage": "Reset PV statistics data",
			"countryRegulations": "National Regulations",
			"acChargeStartTime": "AC charger operating start time",
			"acChargeEndTime": "AC charger working stop time",
			"acSupplyStartTime": "AC Power Supply Start Time",
			"acSupplyEndTime": "Stopping time of AC power supply",
			"restoreFactorySet": "Restore Factory Settings",
			"parallelMode": "Parallel mode",
			"bulkChargeVol": "CV charging voltage",
			"batEquSet": "Battery equalization function",
			"batEquTime": "Battery equalizing time (min)",
			"batEquDay": "Battery charging interval days (d)",
			"batEquChargeVol": "Battery charging voltage (V)",
			"batEquTimeout": "Battery charging timeout (min)",
			"equInfoSet": "Equalizing information settings",
			"val": {
				"slow": "Slow",
				"medium": "Medium",
				"fast": "Fast",
				"low": "Low",
				"high": "High",
				"powerCycle": "Power Cycle",
				"powerRotation": "Power Rotation",
				"additionalPower": "Additional power",
				"powerUnchanged": "Power unchanged",
				"solarPowerInput": "Solar power input",
				"batteryCapacity": "Battery Capacity Percentage",
				"percentageLoad": "Percentage of Load",
				"energySource": "Energy Source",
				"batteryStatus": "Battery charge/discharge status",
				"blue": "Blue",
				"cyan": "Cyan",
				"green": "Green",
				"pink": "Pink",
				"purple": "Purple",
				"orange": "Orange",
				"yellow": "Yellow",
				"white": "White",
				"inputOvlRange1": "APL Mode（90-280V）",
				"inputOvlRange2": "UPS Mode（170-280V）",
				"outputSource1": "Solar->Utility->Battery",
				"outputSource2": "Solar->Battery->Utility",
				"chargeSourcePriority1": "PV Priority",
				"chargeSourcePriority2": "PV and Utility",
				"chargeSourcePriority3": "PV only",
				"solarSupplyPriority1": "Battery-Load-Common",
				"solarSupplyPriority2": "Load-Battery-Common",
				"countryRegulations1": "India",
				"countryRegulations2": "Germany",
				"countryRegulations3": "South America",
				"parallelMode1": "No Parallel",
				"parallelMode2": "Single-phase parallel",
				"outPutSourcePriority1": "Utility Priority",
				"outPutSourcePriority2": "Solar energy first",
				"outPutSourcePriority3": "Solar -> Battery -> Mains"
			}
		},
		"ops15": {
			"invSwitch": "Inverter switch",
			"workMode": "Work mode",
			"workMode1": "Feeder priority",
			"workMode2": "Off-grid mode",
			"workMode3": "Generation and self-consumption",
			"invTime": "Inverter Time",
			"batType": "Battery Type",
			"batType1": "No Battery",
			"batType2": "Universal lithium battery",
			"batType3": "Lead-acid battery",
			"batType4": "Lithium battery",
			"timingTime": "Timing Time",
			"offGirdSoc": "Overdischarge SOC (%)",
			"optimizeRevenue": "Optimizing revenue"
		},
		"type19": {
			"totalActivePower": "Active power",
			"offgridSettings": "And off-network Settings",
			"gridConnectedControlMode": "Grid-connected control",
			"offGridControlMode": "Off-grid control",
			"offgrid": {
				"gridNet": "Grid-network",
				"offNet": "Off-network"
			}
		},
		"type22": {
			"parameterScope": "Paramter Scope",
			"ACStartVoltage": "Start Vol/Freq Set",
			"highVoltage": "AC start high vol",
			"lowVoltage": "AC start low vol",
			"pvStartLowVol": "PV start low vol",
			"acUnderVol1": "AC UV vol I (V)",
			"acOverVol1": "AC OV vol I (V)",
			"acUnderVolTime1": "AC UV vol I Time (s)",
			"acOverVolTime1": "AC OV vol I Time (s)",
			"acUnderVol2": "AC UV vol II (V)",
			"acOverVol2": "AC OV vol II (V)",
			"acUnderVolTime2": "AC UV vol II Time (s)",
			"acOverVolTime2": "AC OV vol II Time (s)",
			"startDelayTime": "Start delay time",
			"restartDelayTime": "Restart delay time",
			"ACStartFrequency": "OV/UV vol/Freq Set",
			"highFrequency": "AC start high freq",
			"lowFrequency": "AC start low freq",
			"acUnderFrequency1": "AC UV Freq I (Hz)",
			"acOverFrequency1": "AC OV Freq I (Hz)",
			"acUnderFrequencyTime1": "AC UV Freq I Time (s)",
			"acOverFrequencyTime1": "AC OV Freq I Time (s)",
			"acUnderFrequency2": "AC UV Freq II (Hz)",
			"acOverFrequency2": "AC OV Freq II (Hz)",
			"acUnderFrequencyTime2": "AC UV Freq II Time（s）",
			"acOverFrequencyTime2": "AC OV Freq II Time（s）",
			"systemSetting": "Device info Set",
			"SNset": "SN Set",
			"reactiveControlMode": "Reactive control mode",
			"inputMode": "Input Mode",
			"startSolpe": "Start slope",
			"restartSolpe": "Restart slope",
			"reactivePercentage": "Reactive percentage",
			"activePercentage": "Active percentage",
			"shutdown": "Remote switch",
			"functionSelect": "Function selection",
			"manufacturer": "manufacturer",
			"setFunctionSelect": {
				"val1": "Inwiton",
				"val2": "Skyworth"
			},
			"setManufacturer": {
				"val1": "prohibit",
				"val2": "Enable anti-reflux"
			},
			"reactiveControlModeChoice": {
				"val1": "Power factor configuration",
				"val2": "Reactive power percentage set",
				"val3": "QV curve control"
			},
			"inputModeChoice": {
				"val1": "Independent mode",
				"val2": "Parallel mode",
				"val3": "Dc source mode"
			}
		}
	},
	"title": {
		"login": "Login",
		"vistorLogin": "Visitor login",
		"createAccount": "Create account",
		"retrievePassword": "Retrieve password",
		"userAgreement": "User agreement",
		"privacyPolicy": "Privacy agreement",
		"phone": "Phone",
		"addPlant": "Add plant",
		"editPlant": "Edit plant",
		"accountInfo": "Account",
		"changePassword": "Change password",
		"message": "Message",
		"systemMessage": "System message",
		"faultMessage": "Fault message",
		"deviceMessage": "Device message",
		"aboutUs": "About",
		"device": "Device",
		"datalogger": "Datalogger",
		"setting": "Setting",
		"socketSetting": "Socket setting",
		"addSchedule": "Add schedule",
		"editSchedule": "Edit schedule",
		"repetitionTime": "Repetition time",
		"addDatalogger": "Add device",
		"connectWifi": "Connect WiFi",
		"distributionNetworkStatus": "Configuration State",
		"language": "Language",
		"selectCountry": "Select Country",
		"selectCity": "Select City"
	},
	"bar": {
		"home": "Home",
		"energy": "Energy",
		"plant": "Plant",
		"user": "User",
		"endpoint": "Endpoint",
		"mine": "Mine",
		"my": "My"
	},
	"button": {
		"logOut": "LOG OUT",
		"rememberMe": "Remember me",
		"forgetPassword": "Forget password",
		"register": "Register",
		"ok": "OK",
		"send": "Send",
		"auto": "Auto",
		"cancle": "Cancel",
		"yes": "Yes",
		"close": "Close",
		"refresh": "Refresh",
		"next": "Next",
		"redistributionNetwork": "Redistributing network",
		"upload": "Upload",
		"confirm": "Confirm",
		"edit": "Edit",
		"delete": "Delete",
		"save": "Save",
		"allReady": "All ready",
		"selectAll": "Select All",
		"deselectAll": "Desert All",
		"unsubscribe": "Delete Account",
		"later": "Later",
		"upgrade": "Upgrade",
		"unfollow": "Unfollow",
		"add": "Add"
	},
	"subtitle": {
		"NeedMoreHelp": "Need more help?",
		"VisitSupportCenter": "Visit Support Center",
		"energyFlowDiagram": "Energy flow diagram",
		"selectTimezone": "Select timezone",
		"selectPlantType": "Select plant type",
		"selectInstallationDate": "Select installation date",
		"selectCurrency": "Select currency",
		"selectDateType": "Select date type",
		"selectDeviceType": "Select device type",
		"socketBluetooth": "Socket Bluetooth",
		"log": "Log",
		"all": "All",
		"online": "Online",
		"offline": "Offline",
		"normal": "Normal",
		"abnormal": "Abnormal",
		"on": "On",
		"off": "Off",
		"todayEnergy": "Today energy",
		"totalEnergy": "Total energy",
		"totalRevenue": "Total revenue",
		"plantImage": "Plant image",
		"plantName": "Plant name",
		"plantType": "Plant type",
		"PVCapacity": "PV Capacity",
		"installationDate": "Installation date",
		"plantAddress": "Plant address",
		"revenue": "Revenue",
		"timeZone": "Time zone",
		"updatePhone": "Update phone",
		"solar": "Solar",
		"grid": "Grid",
		"battery": "Battery",
		"home": "Home",
		"load": "Load",
		"socket": "Socket",
		"today": "Today",
		"day": "Day",
		"month": "Month",
		"year": "Year",
		"total": "Total",
		"time": "Time",
		"Co2": "Co2",
		"coal": "Coal",
		"deforestation": "Preserve forest",
		"parameter": "Parameter",
		"schedule": "Schedule",
		"countdown": "Countdown",
		"socketSwitch": "Socket switch",
		"currency": "Currency",
		"energyPrice": "Energy price",
		"storagePlant": "Storage Plant",
		"on-GridPlant": "On-grid Plant",
		"photo": "Photo",
		"email": "Email",
		"account": "account",
		"version": "Version",
		"officalWebsite": "Offical website",
		"SN": "SN",
		"wifiSignal": "WiFi Signal",
		"lastSync": "Last Sync",
		"switch": "Switch",
		"happening": "Happening",
		"restored": "Restored",
		"details": "Details",
		"warn": "Warn",
		"fault": "Fault",
		"status": "Status",
		"level": "Level",
		"deviceType": "Device type",
		"alarmStartTime": "Alarm start time",
		"alarmEndTime": "Alarm end time",
		"solution": "Solution",
		"loginIn": "Login in……",
		"checkVersion": "Check Version",
		"versionUpgrade": "Version Upgrade",
		"messageDetail": "Message Details",
		"todayConsumption": "Today Consumption",
		"totalConsumption": "Total Consumption",
		"dataloggerSN": "Datalogger SN",
		"configurationGuidance": "Configuration guidance",
		"guideVideo": "Guide video",
		"manualInstuction": "Manual instuction",
		"manualInstuction4G": "4G manual instuction",
		"manualInstuctionMasterControl": "Master control manual instuction",
		"masterControlConfiguration": "Master control guide",
		"defaultConfiguration": "Default guide",
		"findEmail": "Find email",
		"findPhone": "Find phone",
		"family": "Family",
		"agent": "Agent",
		"endUsers": "End Users",
		"deviceManagement": "Device Management",
		"deviceSN": "Device SN",
		"firmwareVersion": "Firmware Version",
		"alias": "Alias",
		"information": "Information",
		"domainName": "DomainName",
		"restartdatalogger": "Restart datalogger",
		"value": "Value",
		"customizable": "Customizable ",
		"ready": "Ready",
		"deviceDetails": "Device Details",
		"startAddress": "Start Register Address",
		"endAddress": "End Register Address",
		"settingParam": "Setting Parameters",
		"tariffType": "Tariff Type",
		"fixed": "Fixed",
		"timeshare": "Timeshare",
		"addTime": "Add time",
		"notAllDay": "Not all day",
		"dealer": "Dealer",
		"dealerNumber": "dealer number",
		"toggle": "Toggle",
		"environmentalBenefit": "Environmental Benefit",
		"basicParam": "Basic param",
		"realTimeParam": "Real time Param",
		"export": "Export",
		"localDebug": "Local debug",
		"mode": "Mode",
		"manuallyAdd": "Manually add"
	},
	"tip": {
		"noData": "No more data",
		"base": {
			"maxDistance": "Farthest",
			"minDistance": "Nearest",
			"maxSize": "Max",
			"minSize": "Min"
		},
		"request": {
			"loading": "Loading",
			"netWork": "Network connection failed. Please check the network",
			"connectServerFail": "Unable to connect to the server",
			"requestTimeout": "Request timeout"
		},
		"validate": {
			"choiceCountry": "Please select a country first",
			"country": "Country cannot be empty, please select country",
			"timeZone": "Time zone cannot be empty. Please select time zone",
			"email": "Email format is wrong, please enter a correct email",
			"verification": "Please send the verification code first",
			"verificationError": "Verification code error, please enter a valid verification code",
			"passwordFormTip": "Please enter a password consisting of 6-20 digits and letters",
			"passwordError": "The password format is incorrect. Please enter a password consisting of 6-20 digits and letters",
			"passwordError2": "Please enter a valid password (password should not include any special characters, symbols and Spaces)",
			"validatePassword": "The two passwords you entered do not match, please check and re-enter",
			"confirmAgreement": "Please read and agree to the Privacy Policy first",
			"plantName": "Plant name cannot be empty, please enter plant name",
			"plantType": "Plant type can't be empty, please select a plant type",
			"pvCapacity": "Solar cell capacity cannot be empty, please input the battery capacity",
			"installationDate": "Installation date cannot be empty, please select installation date",
			"city": "City cannot be empty, please select city",
			"incomeunit": "Currency cannot be empty, please select currency",
			"energyPrice": "Electricity price can't be empty, please enter the price",
			"firstChoiceCountry": "Please select a country",
			"oldPassword": "Old password cannot be empty. Please enter the old password",
			"newPassword": "New password cannot be empty, please enter a new password",
			"confirmPassword": "Confirm password cannot be empty, please confirm password",
			"passwordNotMatch": "The two passwords are inconsistent. Please confirm and submit",
			"SN1": "The serial number cannot be null",
			"SN2": "Serial number must be 10",
			"noSocketTimer": "There is currently no socket timer, please click Add",
			"cameraPermission": "The App requires camera permissions to be used for scanning codes to add datalogger",
			"searchingState": "Do not click repeatedly while searching for Bluetooth",
			"notInTheScope": "The input parameters are not within the specified range",
			"enterDecimal": "Must enter {num} decimal places or integers",
			"integer": "Must be an integer",
			"phone": "Mobile phone number only supports Chinese Mainland, please use email to retrieve others",
			"can`tEmpty": "{param} can`t be empty",
			"dealerSope": "Dealer numbers range from 6 to 8 digits",
			"firstChoicePlant": "Please select a power station first"
		},
		"log": {
			"permissionOpen": "Ensure that location and Bluetooth permissions are enabled",
			"openBluetoothSystem": "Open bluetooth Settings",
			"openIosBtSystem": "Please restart the Bluetooth switch",
			"openLocationSystem": "Open location Settings",
			"bluetoothAuthorization": "APP Bluetooth authorization is not enabled",
			"locationAuthorization": "APP location authorization is not enabled",
			"searchSurroundingDevicesPermission": "Search around unauthorized devices",
			"bluetoothNotPermission": "Bluetooth not enabled",
			"locationNotPermission": "Location not open",
			"bluetoothPermission": "Bluetooth enabled",
			"locationPermission": "Locate on",
			"bluetoothException": "The Bluetooth is disconnected abnormally. Please confirm that the Bluetooth of the device is enabled, and then reconnect",
			"listeningFailure": "Failed to monitor the change of the feature value, please reconnect the Bluetooth",
			"communicationException": "Bluetooth communication is abnormal, please reconnect the Bluetooth",
			"reconnect": "Bluetooth reconnect",
			"connectFail": "Bluetooth connection failed",
			"connect": "Connect Bluetooth",
			"bluetoothService": "Please enable Bluetooth service",
			"locationService": "Enable the location service",
			"notsupport": "The current platform support bluetooth function call",
			"locationNotTurnOn": "Positioning is not open, can't search the bluetooth",
			"init": "Bluetooth initialization",
			"searching": "Searching around Bluetooth",
			"searchFail": "No device is found around, please make sure that Bluetooth is enabled, and then click Refresh",
			"stopSearch": "Stop searching around Bluetooth",
			"stopSearchFail": "Stop search around bluetooth fail",
			"connectSuccess": "Bluetooth connection successful",
			"setMTUsSuccess": "Set the maximum transmission unit",
			"setMTUsFail": "Failed to set the maximum transmission unit",
			"closeSuccess": "Bluetooth has been disconnected",
			"closeFail": "Disconnect the bluetooth connection",
			"wifiLoadFail": "WiFi List load failed, please manually enter",
			"bluetoothAbnormal": "Failed Bluetooth connection, trying to reconnect",
			"getBluetoothServiceSuccess": "Get a bluetooth device service success",
			"getBluetoothServiceFail": "Failed to get a bluetooth device service",
			"getFeatureValuesSucess": "Has been successful for bluetooth devices all eigenvalues of the specified services",
			"getFeatureValuesFail": "Failed to obtain all eigenvalues in the specified service of the Bluetooth device",
			"subscriptionEigenvalueSuccess": "The notify function of the feature value change of the Bluetooth Low Power device has been enabled",
			"subscriptionEigenvalueFail": "Description Failed to subscribe to the feature value when the notify function of the feature value of the Bluetooth Low Power device was enabled",
			"monitoringSuccess": "The feature value change event of Bluetooth Low energy device has been successfully monitored",
			"monitoringFail": "Failed to listen for the feature value change event of the Bluetooth Low energy device",
			"CRC": "CRC check failure",
			"queryWiFi": "Query WiFi List successfully",
			"queryWiFiState": "WiFi List query command has been successfully delivered",
			"wifiConenct": "Querying routing connection status",
			"wifiFail": "Please check the router account and password and try to reconnect",
			"connectWiFiSuccess": "Router connection successful",
			"connectWiFiFail": "Router connection failed",
			"routerAccount": "Router account error",
			"routerPassword": "Router password error",
			"gettingIP": "Getting router IP",
			"getIPFail": "Failed to get router IP",
			"queryServerState": "The command for querying the interaction status between the device and the server has been successfully delivered",
			"server03": "There are 03 data interactions",
			"server04": "There are 04 data interactions",
			"heartbeat": "Interaction with data",
			"noInteraction": "No data interaction",
			"bluetoothCommunication": "Bluetooth communication is available",
			"connectWiFi": "Connect to WiFi",
			"getWiFiList": "Querying the WiFi List",
			"wifiName": "WiFi name cannot be empty, please select or enter 2.4WiFi",
			"wifiPasword": "WiFi can't be empty. Please enter a password",
			"wifiPasswordError": "WiFi password cannot contain special characters(ǎǐò•♢∝⌒℃℉ etc.)",
			"masterControlId": "Master ID cannot be empty, please enter ID"
		},
		"addDatalogger": {
			"noSearchDevice": "The device cannot be queried. Please ensure that the Bluetooth of the device is enabled",
			"searchAroundBluetooth": "Ambient Bluetooth",
			"scan": "Scan the QR code or manually enter the datalogger serial number under the QR code.",
			"connectBluetoothFail": "Bluetooth connection fails, please scan the QR code or manually enter the datalogger serial number under the QR code and connect again."
		},
		"connectWifi": {
			"tip1": "Configure the device to the network",
			"tip": "Please select2.4G WiFi",
			"devicePower": "Device power",
			"enterSSID": "Please enter WIFI SSID",
			"enterPassword": "Please enter WIFI Password",
			"masterId": "Please enter master control Id",
			"commandSetFail": "Command setup failed, please reconnect the datalogger"
		},
		"configurationState": {
			"connecting": "Connecting",
			"connectFail": "Connect fail",
			"connectSuccess": "Connect Success",
			"wait": "Please wait a moment",
			"addDeviceSuccess": "Adding a device successfully"
		},
		"home": {
			"today": "Daily power generation",
			"month": "Monthly power generation",
			"year": "Annual power generation",
			"total": "Total power generation",
			"homePower": "Home power",
			"socketPower": "Socket power",
			"off-gridPower": "Off-grid power",
			"acPower": "Mains power",
			"on-gridPower": "On-grid active power",
			"others": "Others",
			"latestVersion": "This is the latest version",
			"frontEndLoad": "Front-end load",
			"backEndLoad": "Back-end load"
		},
		"energy": {
			"choiceDate": "The current data only goes through",
			"explain": "Explain",
			"tip1": {
				"load": "Energy consumed by load equipment",
				"solar": "Energy that converts solar energy into electrical energy that can be used to charge batteries or power loads",
				"grid": "Cumulative energy sold to the grid",
				"battery": "The accumulated electrical energy used to charge the battery"
			},
			"tip2": "= photovoltaic energy * electricity price",
			"tip3": {
				"title": "Social contribution calculation criteria",
				"title2": "1kWh equal to:",
				"Co2": "Reduce CO2 emissions:",
				"coal": "Saving the use of standard coal:",
				"deforestation": "Protect trees:"
			}
		},
		"plant": {
			"comprehensive": "Comprehensive ranking",
			"totalEnergyMax": "Max total electricity",
			"totalEnergyMin": "Min total electricity",
			"realTimePower": "Real-time power",
			"pvcapacityMax": "Highest PV capacity",
			"pvcapacityMin": "Lowest PV capacity",
			"installationDateMax": "Latest installed date",
			"installationDateMin": "Farthest installed date",
			"paramChoice": "Preferinces",
			"filter": "Filtrate",
			"addVisitor": "Add Visitor",
			"tip": "Tip",
			"visitorTip": "Can only be shared to registered users",
			"name": "Name",
			"permission": "Permissions",
			"read-write": "Read-write",
			"onlyread": "Read-only",
			"visitor": "Visitor",
			"unfollow": "Are you sure you want to unfollow this power plant?"
		},
		"device": {
			"power": "Power ",
			"type": "Type",
			"updateTime": "Update time ",
			"powerOnTime": "Power-on time",
			"todayEnergy": "Today energy ",
			"signal": "Signal strength "
		},
		"delDevice": "Are you sure to delete this {device}?",
		"currentNoDevice": "Current no device",
		"currentNoDatalog": "Current no datalogger",
		"messageInfo": {
			"storage": "Storage",
			"inverter": "Inverter",
			"microInverter": "Micro Inverter"
		},
		"userLocation": "User location",
		"ops": {
			"setDomainName": "Are you sure want to set up this domain name?",
			"restartDatalogger": "Are you sure want to restart the datalogger?"
		},
		"opsLog": {
			"addUser": "Add End User",
			"bindSuccess": "Bind successfully",
			"selectDate": "Select Date",
			"start": "Start",
			"end": "End",
			"upgrade": "Upgrade",
			"operation": "Operation",
			"communication": "Communication",
			"type": "Type",
			"content": "Content",
			"control": "Controller",
			"command": "Command Type",
			"reset": "Reset",
			"serverTime": "Server Time",
			"inverter": "Interval",
			"bindAgent": "Binding agent",
			"deleteDeviceSN": "Please confirm whether to delete?",
			"recordLog": "A device that needs to log"
		},
		"setting": {
			"productModelNumberRelevancyOn-gridPower": "The grid-connected active power cannot be greater than the maximum power of the selected product model"
		}
	},
	"productEquipment": {
		"productModelNumber": "Modell nach modell.",
		"warnMessage": "Warn message",
		"customInstruction": "custom instruction",
		"firmwareUpdate": "firmware update",
		"historicalData": "historical data",
		"linkageInformation": "linkage information",
		"batchRead":"Batch read",
		"batchSetting":"Batch setting",
		"protocolDirectives":"Protocol Directives",
		"basicSettings":"Basic settings",
		"otherSettings":"Other settings",
	},
	"systemAuthority": {
	    "systemSettings": 'System Settings',
	    "authorityManagement": 'Authority Management',
	    "accountManagement": "Account Management",
		"Disabled":"Disabled",
		"enable":"enable"
	},
	"org":{
		"ChangeSupplier":'Change supplier'
	}
}
