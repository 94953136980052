 const permission = {
  state: {
    userInfo: {},
    user: {},
    menuIds: [],
    roles:[],
  },
  mutations: {
    SET_USERINFO: (state, params) => {
      state.userInfo = params
    },
    SET_MENUIDS: (state, params) => {
      state.menuIds = params
    },
    SET_USER: (state, params) => {
      state.user = params
    },
    SET_ROLES: (state, params) => {
      state.roles = params
    }
  },
  actions: {
    setUserInfo({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_USERINFO', data)
        resolve()
      })
    },
    setMenuIds({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_MENUIDS', data)
        resolve()
      })
    },
    setUser({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_USER', data)
        resolve()
      })
    },
    setRoles({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_ROLES', data)
        resolve()
      })
    }
  }
}

export default permission
