import http from "@/utils/request";

// 查询电站信息
//  设备详情接口
// export const getEnergyDataDay = (params) => http.post('/admin/energy/getEnergyDataDay', params);

/*****
 *
 * import { get, post, uploadFile } from "@/utils/axios";
 * import service from "@/utils/axios";
 */

import service from "@/utils/axios";

import QS from 'qs'
/*
* methods: 请求
* @param url 请求地址
* @param params 请求参数
*/

// export const test = (userId) => http.get(url+`/${userId}`);
// export const test = (params) => http.post(url, params);
// export const test = (userId, params) => http.put(url+`/${userId}`, params);
// export const test = (userId) => http.delete(url+`/${userId}`);
// 登录
export const isLogin = (params) => http.post('/admin/agent/login', params);
// 获取首页概览数据
export const getMenuData = (params) => http.get('/admin/log/getMenuData', { params });
// 通过用户名获取用户
export const getAgentById = (params) => http.post('/admin/agent/getAgentById', params);
//  update user   更新用户数据
export const updatePassword = (params) => http.post('/admin/agent/updatePassword', params);

// 代理商表格内容    提交数据格式不对
export const getAgentPage = (params) => http.post('/admin/agent/getAgentPage', params);

/**
 * 自定义设置设备参数
 * @param deviceType integer  必传
 * @param deviceSn  string  必传
 * @param  object {
 *    @param  test  string  参数名称（可变）
 * }
 */

export const setCustomParams = (params) => service.post('/admin/device/setCustomParams', params, 
  {headers: { 'Content-Type': 'application/json;charset=UTF-8' }
});
/**
 * 自定义设置设备参数
 * @param startAddr integer  必传
 * @param deviceSn  string  必传
 * @param  data {
 *    @param  test  string  参数名称（可变）
 * }
 */
export const setDeviceParam = (params) => service.post('/admin/device/setDeviceParam', params,  );
 
//  设备详情接口
export const getAdminDeviceInfo = (params) => service.post('/admin/device/getAdminDeviceInfo', params,
  {  headers: { 'Content-Type': 'application/json;charset=UTF-8' },}
);//引入qs模块，用来序列化post类型的数据
 
/**
 * 首页根据电站id获取统计数据
 * @param {Sting} plantId
 * @method post
 */
export const getHomeCountData = (params) => service.post('admin/energy/getHomeCountData', params)
/**
* 获取天气信息
*  @param {Sting} plantId
*/
export const getWeatherInfo = (params) => service.post('admin/weather/getWeatherInfo', params)

/**
* 获取天的能源数据（按分钟/小时）
* @method post
* @param  {Sting}   plantId
* @param  {Sting}  time
*/
export const getEnergyDataDay = (params) => service.post('admin/energy/getEnergyDataDay', params)
/**
* 获取月的能源数据（日期）
* @method post
*/
export const getEnergyDataMonth = (params) => service.post('admin/energy/getEnergyDataMonth', QS.stringify(params))
/**
 * 获取年的能源数据（年份）
 * @method post
 */
export const getEnergyDataYear = (params) => service.post('admin/energy/getEnergyDataYear', params)
/**
 * 获取累计的能源数据（总量-往前推5年）
 * @method post
 */
export const getEnergyDataTotal = (params) => service.post('admin/energy/getEnergyDataTotal', params)
/**
 * 根据id查询电站
 * @method get
 * @param {plantId}
 */
export const getPlantById = (params) => service.get('admin/plant/getPlantById/' + params)

/**
 * 分页查询设备信息
 * @method post
 * @param {pageNow,pageSize}
 *
 * @headers { 'Content-Type': 'application/json;charset=UTF-8', "InterfaceType": 2 }
 */
export const getDevicePage = (params) => service.post('admin/device/getDevicePage', params, {
  headers: { 'Content-Type': 'application/json;charset=UTF-8', InterfaceType: 2 }
},)
/**
 * 按条件分页模糊查询并统计消息数据
 * @method post
 * @param {*} params
 * @returns
 */
export const getMsgPage = (params) => service.post('admin/msg/getMsgPage', params, {
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
},)
// export const getMsgPage = (params) => service.get('admin/msg/getMsgPage', + params)

/**
 * 根据类型和序列号获取设备的设置信息
 * @method post
 * @param {*} params deviceSn deviceTypeCode
 * @returns
 */
export const getSettingInfo = (params) => service.post('/admin/device/getSettingInfo', params);
/**
 * 查询单个设备详情信息
 * @method post
 * @param {*} params sn deviceType time
 * @returns
 */
export const getDeviceBySn = (params) => service.post('/admin/device/getDeviceBySn', params);

/**
 * 查询用户邮箱
 * @method post
 * @param
 * */
export const getUserEmailBySearch = (params) => service.post('/admin/agent/getUserEmailBySearch', params);
/**
 * 透传读单个设备参数 Modbus 03
 * @method post
 * @param
 * */
export const getDeviceData = (params) => service.post('/admin/device/getDeviceData', params, {
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
},);



/**
 *透传写多个设备参数 Modbus 10
 * @method post
 * @param
 * */
export const setDeviceDatas = (params) => service.post('/admin/device/setDeviceDatas', params, {
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
},);

/**
 * 读取采集器参数 
 * @method post
 * @param
 * */

export const getDatalogParam = (params) => service.post('/admin/datalog/getDatalogParam', params,);

/**
 * 采集器远程升级 
 * @method post
 * @param
 * */
export const upgrade = (params) => service.post('/admin/datalog/upgrade', null, {params },);

/**
 * 采集器远程设置 
 * @method post
 * @param   post 请求  传参方式需要拼接再地址后面
 * */
export const setDataLogCmd = (params) => service.post('/admin/datalog/setDataLogCmd', null , { params} );

/**
 * 检查采集器版本号 
 * @method post
 * @param   post 请求  传参方式需要拼接再地址后面
 * */
export const checkDataLogVersion = (params) => service.post('/admin/datalog/checkDataLogVersion', null , { params} );

/**
 * 查询采集器升级进度 
 * @method post
 * @param   post 请求  传参方式需要拼接再地址后面
 * */
export const getUpgradeProgress = (params) => service.post('/admin/datalog/getUpgradeProgress', params );













// 查询options列表数据
export const listOnce = (params) => service.get('/admin/dict/getDictDataByTypeId/'+params );

/**
 * 登录新
 *
 * 10000 返回 401   重新登陆
 */
export const Login = (params) => service.post('/nahui-ems/auth/agent/verify/login', params, {
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
},);
/**
 *
 * 测试token接口
 * */
export const ping = (params) => service.get('/nahui-ems/ping', { params });
/**
 * 获取电站下所有下挂设备
 * */
export const gitPlantDeviceList = (plantId) => service.get('/admin/device/list/device?plantId='+plantId,  );
/**
 * 按条件分页模糊查询采集器
 * */
export const getDatalogPage = (params) => service.get('/admin/datalog/getDatalogPage',params  );
/**
 *  获取设备连接采集器信息
 * */
export const getRelatedDatalog = (deviceSn) => service.get('/admin/datalog/list/device/related/datalog?deviceSn=' +deviceSn  );
 
 