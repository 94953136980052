 
/*
 * @Author: lqj
 * @Desc:  copy 指令
 * @Date: 2024-08-08 17:06:47
 * @LastEditors: lqj
 * @LastEditTime: 2024-08-08 17:28:43
 */
/**
 * @description: 复制内容
 * @empty  v-copy="复制内容" 
 */
const copy = {
    bind(el, { value }) {
        el._value = value
        el.handler = () => {
            if (!el._value) {
                // 复制的值为空时，给出的提示操作。
                console.log('无可复制的内容')
                return
            }

            // 创建 textarea 标签
            const textarea = document.createElement('textarea')
            // 设置readOnly(规定字段为只读)属性，防止 iOS下自动唤起键盘，并移除可视区域
            textarea.readOnly = 'readOnly'
            textarea.style.position = 'absolute'
            textarea.style.left = '-9999px'
            // 将需要复制的值，赋给 textarea 标签的 value  值
            textarea.value = el._value 
            // 将 textarea 插入到 body 中
            document.body.appendChild(textarea)

            // 选中值并复制
            textarea.select()
            const result = document.execCommand('Copy')
            if (result) {
                console.log('复制成功')
            }
            document.body.removeChild(textarea)
        }
        // 绑定点击事件
        el.addEventListener('click', el.handler)
    },
    // 当传进来的值更新的时候触发
    componentUpdated(el, { value }) {
        el._value = value
    },
    // 指令与元素解绑的时候触发，移除事件绑定
    unbind(el) {
        el.removeEventListener('click', el.handler)
    }
}

export default copy
