<template>
    <fragment>
        <!--一级菜单-->
        <template v-for="item in menuList">
            <el-submenu v-if="item.children && item.children.length && menuIds.includes(item.meta.menuId)"
                :index="item.path" :key="item.path">
                <!-- router-link-active -->
                <template slot="title"  >
                    <i slot="title" class="iconfont  Icon" :class="item.meta.icon"></i>
                    <span slot="title">{{ $t(item.meta.title) }}</span>
                </template>
                <template>
                    <MainMenu :menuList="item.children" :menuIds="menuIds"></MainMenu>
                </template>
            </el-submenu>
            <el-menu-item popper-append-to-body :index="item.path"
                v-else-if="!overHidden(item) && !item.meta.menuId || !overHidden(item) && menuIds.includes(item.meta.menuId)"
                :key="item.path">
                <i class="iconfont Icon" :class="item.meta.icon ? item.meta.icon : ''"></i>
                <span slot="title" v-if="!item.TOutput">
                    {{ $t(item.meta.title) }}
                </span>
                <span v-else>{{ item.meta.title }}</span>
            </el-menu-item>
        </template>
    </fragment>
</template>

<script>

 

export default {
    name: "MainMenu",
    components: {},
    props: {
        menuList: {
            type: Array,
            default: () => []
        },
        dns_ym: {
            default: ''
        },
        menuIds: { default: () => [] },
    },
    data() {
        return {


        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        overHidden(item) {
            return item.hidden ? true : false
        }
    }
};
</script>
<style scoped lang='scss'>
.Icon {
    margin: 0 10px 0 -1px;
}
</style>