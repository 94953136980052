const device = {
  state: {
    listItem : {},
    
  },
  mutations: {
    SET_LIST_ITEM: (state, params) => {
      state.listItem = params
    },
   
  },
  actions: {
    setDataInfo({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_LIST_ITEM', data)
        resolve()
      })
    },
     
  }
}

export default device
