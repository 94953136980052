export default {
	"language": {
		"name": "Italiano"
	},
  product:{
    "PleaseEnter": "Si prega di inserire il nome del prodotto",
    "PleaseEnterTheProductSerialNumber": "Si prega di inserire il numero di serie del prodotto",
    "PleaseEnterSubmodelNumber": "Si prega di inserire il numero del sottomodello",
    "PleaseenterTheSubmodelSerialNumber": "Si prega di inserire il numero di serie del sottomodello",
    "PleaseSelectTheDeviceType": "Si prega di selezionare il tipo di dispositivo",
    "PleaseSelectDevelopmentType": "Si prega di selezionare il tipo di sviluppo",
    "management": "gestione del prodotto",
    "name": "nome del prodotto",
    "reset": "reimposta",
    "search": "ricerca",
    "ApplicationList": "elenco applicazioni",
    "newProduct": "nuovo prodotto",
    "editName": "modifica prodotto",
    "DetailSetting": "impostazioni dettaglio",
    "delete": "elimina",
    "serialNumber": "numero di serie",
    "deviceType": "tipo di dispositivo",
    "RandDtype": "tipo di R&S (Ricerche e Sviluppo)",
    "subType": "sottotipo",
    "updateTime": "tempo di aggiornamento",
    "operation": "operazione",

    "deviceList": "Elenco dispositivi (Registro di spedizione)",
    "parameterManagement": "Gestione dei parametri",
    "updataManagement": "Gestione degli aggiornamenti",

    "ParentDevice": "Dispositivo Padre",
    "AssociatedPowerStationState": "Stato Stazione Elettrica Associata",
    "ImportDeviceList": "Importa Elenco Dispositivi",
    "PlantDetails": "Dettagli Centrale Elettrica",
    "parameterName": "Nome Parametro",

    "OwningPowerStation": "Stazione Elettrica Proprietaria",

    "ParameterClass": "Categoria Parametro",
    "ParameterSettingType": "Tipo di Impostazione del Parametro",
    "ParameterOperationType": "Tipo di Operazione del Parametro",
    "ImportParameter": "Importa Parametro",
    "OwningUpperLevelParameterCategory": "Categoria Superiore del Parametro Proprietario",
    "WhetherYoSupportBatchRead": "Se Supporta la Lettura di Gruppo",
    "value": "valore",
    "scope": "ambito",
    "unit": "unità",
    "explain": "spiegazione",
    "remark": "osservazione",

    "fileName": "Nome del File",
    "fileAddress": "Indirizzo del File",
    "versionNumber": "Numero di Versione",
    "ProductOfOwnership": "Prodotto di Proprietà",

    "ProductDetailsSettings": "Balcone 1.0 - Impostazioni Dettagli Prodotto",
    "QueryResultList": "Elenco Risultati Ricerca",
    "AddFirmware": "Aggiungi Firmware",
    "NewParameter": "Nuovo Parametro",

    "datalog": "Registratore dati",
    "Inverter": "Inverter",
    "storage": "Memorizzazione",
    "battery": "Batteria",
    "socket": "Presa",

    "DownloadTemplate": "Scarica modello",
    "nation": "nazione",
    "agent": "agente",
    "expirationDate": "data di scadenza",
    "UpdateTime": "Tempo di aggiornamento",
    "LastWeek": "Ultima settimana",
    "LastMonth": "Ultimo mese",
    "LastThreeMonths": "Ultimi tre mesi",

    "parameterType": "Parametertyp",
    "ProtocolAddress": "Protokolladresse",
    "stepSize": "Schrittgröße",
    "PleaseEnterAParameterName": "Bitte geben Sie einen Parameternamen ein",
    "parameterCoding": "Parametercodierung",
    "PleaseEnterTheParameterCode": "Bitte geben Sie den Parametercode ein",
    "ParameterDataType": "Parameterdatentyp",
    "PleaseEnterTheAddressInTheParameterProtocol": "Bitte geben Sie die Adresse im Parameterprotokoll ein",
    "blockEncoding": "Blockcodierung",
    "PleaseEnterABlockCode": "Bitte geben Sie einen Blockcode ein",
    "GroupName": "Gruppenname",
    "PleaseEnterAGroupName": "Bitte geben Sie einen Gruppennamen ein",
    "PleaseEnterTheUnit": "Bitte geben Sie die Einheit ein",
    "decimalPoint": "Dezimalpunkt",
    "PleaseEnterTheDecimalPoint": "Bitte geben Sie den Dezimalpunkt ein",
    "leastValue": "Kleinstwert",
    "PleaseEnterTheUnit": "Bitte geben Sie den kleinsten Wert ein", // 注意：此处修正为 "Please enter the least value"
    "maximumValue": "Maximalwert",
    "PleaseEnterTheMaximumValue": "Bitte geben Sie den Maximalwert ein",
    "PleaseEnterTheStepSize": "Bitte geben Sie die Schrittgröße ein",
    "addEquipment": "Gerät hinzufügen",
    "equipment": "Gerät",
    "remove": "Entfernen",
    "sort": "Sortieren",
    "PleaseEnterTheOrder": "Bitte geben Sie die Reihenfolge ein",
    "PleaseEnterTheParameterSettingType": "Si prega di inserire il tipo di impostazione del parametro",

    "versionNumber": "numero di versione",
    "ProductOfOwnership": "prodotto di appartenenza",
    "PleaseSelectAFolderPath": "si prega di selezionare il percorso della cartella",
    "ReturnToSuperior": "torna al superiore",
    "PleaseEnterTheVersionNumber": "si prega di inserire il numero di versione",
    "firmwareSpecification": "specifiche del firmware",
    "PleaseEnterFirmwareDescription": "si prega di inserire la descrizione del firmware",

    "FileName": "nome del file",
    "DeviceType": "tipo di dispositivo",
    "FileAddress": "indirizzo del file",
    "time": "tempo",
  },
	"sidebar": {
		"home": "Panoramica dei dati",
		"endbenutzer": "Gestione terminale",
		"plant": "Scene",
		"plant_plant": "Centrale",
		"device": "Gest. dispositivi",
		"datalog": "Lista collezionisti",
		"inverter": "Lista inverter",
		"sotrage": "Energia stoccata",
		"socket": "Presa",
		"battery": "Elenco delle pile",
		"agent": "Gestione agenti",
		"systematicSupply": "Giornale di bordo",
		"firmware": "Elenco fw",
		"firmware_up": "sull’impresa",
		"firmware_log": "Reg. aggiornamento",
		"system": "Impost sistema",
		"system_datalog_log": "Reg. raccoglitore",
		"system_log": "Reg. operazioni",
		"system_tongxun": "Reg. comunicazioni",
		"system_app": "Ver. app",
		"personal": "Informazioni sull utente",
		"logout": "Conto di uscita",
		"organizationalManagement":"Gestione organizzativa"
	},
	"login": {
		"email": "email",
		"password": "password",
		"remember": "ricorda email e password",
		"enteremail": "inserisci l'email",
		"enterpassword": "inserisci la password"
	},
	"home": {
		"echarts1": {
			"title": "Utenti registrati",
			"subhead": "Nuovi utenti oggi/Numero totale di utenti"
		},
		"echarts2": {
			"title": "Utenti connessi",
			"subhead": "Accessi odierni/Numero totale di utenti"
		},
		"echarts3": {
			"title": "Aggiungi rivenditore",
			"subhead": "Accessi odierni/Numero totale di rivenditori"
		},
		"echarts4": {
			"title": "Aggiungi scena",
			"subhead": "Nuove scene/Totale delle scene",
			"title_plant": "Aggiungi impianto",
			"subhead_plant": "Nuove installazioni/Installazioni totali"
		},
		"echarts5": {
			"title": "Aggiungi collezionista",
			"subhead": "Nuovi collezionisti/Numero totale di collezionisti"
		},
		"echarts6": {
			"title": "Aggiungi dispositivo",
			"subhead": "Nuovi dispositivi/Numero totale di dispositivi"
		}
	},
	"End_user": {
		"input_email": "Inserisci l'email dell'utente",
		"btn_search": "Cerca",
		"btn_add": "Aggiungi",
		"btn_delete": "Elimina",
		"btn_binding": "Associa",
		"add_station": "Aggiungi scenario",
		"lable_StationName": "Nome della scena",
		"lable_StationType": "Tipo di scena",
		"lable_PVCapacity": "Capacità pannelli solari",
		"lable_InstalltionDate": "Data di installazione",
		"lable_Country": "Paese",
		"lable_City": "Città",
		"lable_Address": "Indirizzo",
		"lable_TimeZone": "Fuso orario",
		"lable_Energyprice": "Prezzo dell'energia",
		"lable_IncomeUnit": "Unità di reddito",
		"input_StationName": "Inserisci il nome della scena",
		"input_StationType": "Seleziona il tipo",
		"input_PVCapacity": "Inserisci la capacità dei pannelli solari",
		"input_InstalltionDate": "Seleziona la data di installazione",
		"input_Country": "Seleziona il paese",
		"input_City": "Seleziona la città",
		"input_Address": "Inserisci l'indirizzo",
		"input_TimeZone": "Seleziona il fuso orario",
		"input_Energyprice": "Inserisci il prezzo",
		"input_IncomeUnit": "Seleziona l'unità di reddito",
		"max_20": "Massimo 20 caratteri",
		"max_5": "Massimo 5 caratteri",
		"max_8": "Massimo 8 caratteri",
		"format": "Inserisci un formato corretto",
		"close": "Chiudi",
		"submit": "Invia",
		"title_delete": "Eliminare?",
		"no": "No",
		"yes": "Sì",
		"Bingding_Agent": "Associa agente",
		"Agent_Code": "Agente:",
		"please_select": "Seleziona"
	},
	"plant": {
		"input_email": "Inserisci l'email dell'utente",
		"search": "Cerca",
		"edit": "Modifica",
		"delete": "Elimina",
		"edit_plant": "Modifica scenario",
		"input_station": "Inserisci il nome dello scenario",
		"lable_StationName": "Nome dello scenario",
		"lable_StationType": "Tipo di scenario",
		"lable_PVCapacity": "Capacità pannelli solari",
		"lable_InstalltionDate": "Data di installazione",
		"lable_Country": "Paese",
		"lable_City": "Città",
		"lable_Address": "Indirizzo",
		"lable_TimeZone": "Fuso orario",
		"lable_Energyprice": "Prezzo dell'energia",
		"lable_IncomeUnit": "Unità di reddito",
		"input_StationName": "Nome dello scenario",
		"input_StationType": "Seleziona il tipo",
		"input_PVCapacity": "Inserisci la capacità dei pannelli solari",
		"input_InstalltionDate": "Seleziona la data di installazione",
		"input_Country": "Seleziona il paese",
		"input_City": "Seleziona la città",
		"input_Address": "Inserisci l'indirizzo",
		"input_TimeZone": "Seleziona il fuso orario",
		"input_Energyprice": "Inserisci il prezzo",
		"input_IncomeUnit": "Seleziona l'unità di reddito",
		"max_20": "Massimo 20 caratteri",
		"max_5": "Massimo 5 caratteri",
		"max_8": "Massimo 8 caratteri",
		"format": "Inserisci un formato corretto",
		"close": "Chiudi",
		"submit": "Invia",
		"title_delete": "Eliminare?",
		"no": "No",
		"yes": "Sì"
	},
	"datalog": {
		"email": "Inserisci l'email",
		"datalogSn": "numero di serie del raccoglitore",
		"search": "Cerca",
		"Version": "Versione",
		"setting": "Impostazioni",
		"upgrade": "Aggiorna",
		"delete": "Elimina",
		"title_datalog": "Impostazioni del collezionista",
		"span_info": "Informazioni",
		"span_datalog": "Numero di serie del collezionista:",
		"span_name": "Alias:",
		"span_time": "Ultimo aggiornamento:",
		"span_firmware": "Segnale/Versione firmware:",
		"span_order": "Comandi",
		"span_settingdns": "Imposta nome di dominio",
		"span_inputsetting": "Inserisci nome di dominio",
		"span_upgrade": "Aggiorna collezionista",
		"span_reset": "Riavvia collezionista",
		"span_setup": "Impostazioni avanzate",
		"span_param": "Parametro",
		"span_value": "Valore",
		"btn_setup": "Impostazioni avanzate",
		"btn_setting": "Impostazioni",
		"btn_settingdns": "Imposta nome di dominio",
		"btn_upgrade": "Aggiorna",
		"btn_reset": "Riavvia",
		"btn_read": "Leggi",
		"btn_close": "Annulla",
		"methods_version": "Verificare l'aggiornamento della versione?",
		"no": "No",
		"yes": "Sì",
		"methods_delete": "Eliminare?",
		"methods_rereset": "Riavviare?",
		"methodes_inputDns": "Inserisci il nome di dominio",
		"methodes_DNS": "Impostare questo nome di dominio?"
	},
	"sotrage": {
		"select": "Seleziona",
		"email": "Inserisci l'email",
		"datalogSn": "numero di serie del raccoglitore",
		"deviceSn": "Num. di serie disp.",
		"search": "Cerca",
		"storage": "Impostazioni di stoccaggio"
	},
	"device": {
		"select": "Seleziona",
		"email": "Inserisci l'email",
		"datalogSn": "numero di serie del raccoglitore",
		"deviceSn": "Num. di serie disp.",
		"search": "Cerca",
		"title": "Impostazioni dell'inverter",
		"setting": "Impostazioni",
		"setting_inverte": "Imposta i parametri dell'inverter",
		"input_start_address": "Indirizzo del registro iniziale",
		"input_end_address": "Indirizzo del registro finale",
		"input_setting_value": "Inserisci il valore da impostare",
		"read_data": "Leggi i dati del registro:",
		"input_start_read": "Indirizzo del registro iniziale",
		"input_end_read": "Indirizzo del registro finale",
		"btn_address": "Imposta indirizzo",
		"btn_value": "Imposta parametro",
		"btn_read": "Leggi",
		"btn_close": "Indietro",
		"select_data": "Seleziona data"
	},
	"battery": {
		"select": "Seleziona",
		"email": "Inserisci l'email",
		"datalogSn": "numero di serie del raccoglitore",
		"deviceSn": "Num. di serie disp.",
		"search": "Cerca"
	},
	"socket": {
		"title": "Impostazioni della presa elettrica",
		"setting": "Impostazioni",
		"span": "Se hai impostato più indirizzi di registro, devi anche impostare più valori. I valori devono essere separati da virgola (esempio: 1, 2, 3)",
		"setting_device": "Imposta i parametri della presa elettrica:",
		"startaddress": "Indirizzo di registro iniziale",
		"stopaddress": "Indirizzo di registro finale",
		"value": "Inserisci il valore da impostare",
		"readdata": "Leggi i dati del registro:",
		"setting_address": "Imposta indirizzo",
		"setting_value": "Imposta parametro",
		"read": "Leggi",
		"return": "Indietro"
	},
	"agent": {
		"close": "Chiudi",
		"submit": "Invia",
		"input_email": "Inserisci l'email",
		"search": "Cerca",
		"add_agent": "Aggiungi agente",
		"import_device": "Importa dispositivo",
		"edit": "Modifica",
		"title": "Aggiungi agente",
		"email": "Email",
		"password": "Password",
		"phone": "Numero di telefono",
		"company": "Azienda",
		"language": "Lingua",
		"address": "Indirizzo",
		"input_password": "Inserisci la password",
		"input_phone": "Inserisci il numero di telefono",
		"input_company": "Seleziona l'azienda",
		"input_language": "Inserisci la lingua",
		"input_address": "Inserisci l'indirizzo",
		"relus_email": "Inserisci un indirizzo email valido",
		"title_edit": "Modifica agente",
		"title_device": "Carica",
		"span": "Esempio: foglio Excel",
		"btn_upload": "Carica",
		"delete": "Elimina",
		"agent_delete": "Eliminare l'agente?"
	},
	"ship": {
		"starttime": "Ora di inizio",
		"endtime": "Ora di fine",
		"deviceSn": "Num. di serie disp.",
		"select": "Seleziona azienda",
		"search": "Cerca"
	},
	"Firmware": {
		"file_name": "Inserisci il nome del file",
		"search": "Cerca",
		"add": "Aggiungi",
		"delete": "Elimina",
		"title": "Caricamento del firmware",
		"select_folder": "Seleziona una cartella:",
		"select_folder_type": "Seleziona il tipo di cartella",
		"craete_folder": "Crea una nuova cartella",
		"folder_path": "Percorso della cartella:",
		"folder_name": "Nome della cartella:",
		"input_folder_name": "Inserisci il nome della cartella",
		"create": "Crea cartella",
		"listed_files": "Elenco dei file:",
		"import_folder": "Importa cartella",
		"uplad_folder": "Carica cartella",
		"close": "Indietro"
	},
	"Upgrade": {
		"starttime": "Orario di inizio",
		"endtime": "Orario di fine",
		"deviceSn": "Num. di serie disp.",
		"type": "Seleziona il tipo",
		"search": "Cerca",
		"datalog": "Collezionista",
		"Inverter": "Inverter",
		"Control": "Controllore"
	},
	"datalogs_logs": {
		"starttime": "Orario di inizio",
		"endtime": "Orario di fine",
		"deviceSn": "Num. di serie disp.",
		"Version": "Inserisci la versione",
		"search": "Cerca"
	},
	"Logs": {
		"starttime": "Orario di inizio",
		"endtime": "Orario di fine",
		"deviceSn": "Num. di serie disp.",
		"type": "Seleziona il tipo",
		"search": "Cerca"
	},
	"Special": {
		"starttime": "Ora di inizio",
		"endtime": "Ora di fine",
		"deviceSn": "Num. di serie disp.",
		"datalogSn": "numero di serie del raccoglitore",
		"type": "Seleziona il tipo",
		"search": "Cerca",
		"save": "Salva",
		"Examples": "Esempi: (2305210230, 2305210139)"
	},
	"App": {
		"System_Config": "Configurazione di sistema",
		"APP_Version": "Pubblicazione della versione dell'app",
		"Android_Version": "Versione Android:",
		"IOS_Version": "Versione iOS:",
		"APP_Type": "Tipo di app:",
		"New_Version": "Nuova versione",
		"update_content": "Descrizione degli aggiornamenti: (utilizza '/n' per separare)",
		"Whether_update": "Aggiornamento obbligatorio:",
		"no": "No",
		"yes": "Sì",
		"submit": "Invia"
	},
	"Tips": {
		"app_submit": "Confermi di voler modificare le informazioni?",
		"firmware_delete": "Conferma eliminazione?",
		"firmware_deleteCDN": "Elimina file nel CDN?",
		"yes": "Sì",
		"no": "No"
	},
	"global": {
		"DataDetails": "Dettagli dei dati",
		"device": "Impostazioni dell'inverter",
		"device_data": "Imposta i parametri dell'inverter",
		"storage": "Impostazioni di storage",
		"storage_data": "Imposta i parametri di storage",
		"battery": "Impostazioni della batteria",
		"battery_data": "Imposta i parametri della batteria",
		"socket": "Impostazioni della presa",
		"socket_data": "Imposta i parametri della presa"
	},
	"btn": {
		"setting_address": "Indirizzo impostazioni",
		"close": "Chiudi",
		"submit": "Invia",
		"Setting_parameters": "Impostazione parametri",
		"Reading": "Lettura",
		"search": "Ricerca",
		"add": "aggiungi",
		"import": "importa",
		"download": "scarica",
		"edit": "modifica",
		"login": "Accedi",
		"export": "Esporta"
	},
	"form": {
		"addShip": "Aggiungi dispositivo di spedizione",
		"editShip": "Modifica dispositivo di spedizione",
		"import": "Importa informazioni sul dispositivo",
		"shipSN": "Numero di serie di spedizione",
		"agent": "Agente",
		"networkCall": "Eseguire la chiamata di rete",
		"warrantyPeriod": "Periodo di garanzia",
		"shiptime": "Ora di spedizione",
		"shiptype": "Tipo di spedizione",
		"classify": "Classificazione",
		"file": "Trascina il file qui o clicca per caricare",
		"edit_plant": "Modifica scenario",
		"input_station": "Inserisci nome dello scenario",
		"lable_StationName": "Nome dello scenario",
		"lable_StationType": "Tipo di scenario",
		"input_StationName": "Inserisci nome dello scenario",
		"edit_plant_plant": "Mod. Centrale",
		"input_station_plant": "Inserisci nome centrale",
		"lable_StationName_plant": "Nome centrale",
		"lable_StationType_plant": "Tipo centrale",
		"input_StationName_plant": "Inserisci nome centrale"
	},
	"placeholder": {
		"select": "Selezione",
		"choiceCountry": "Selezionare un paese",
		"choiceTimezone": "Selezionare il fuso orario",
		"enterEmail": "Inserire l'indirizzo e-mail",
		"enterPhone": "Inserire il numero di cellulare",
		"verificationCode": "Inserire il codice di verifica",
		"enterPassword": "Inserire una password di 6-20 cifre",
		"confirmPassword": "Confermare la password",
		"readAndAgree": "Ho letto e accetto",
		"search": "Ricerca",
		"searchPlantName": "Ricerca per nome dell'impianto",
		"searchDeviceName": "Ricerca per nome del dispositivo",
		"searchDataloggerName": "Ricerca del nome del raccoglitore",
		"enterPlantName": "Inserire il nome della pianta",
		"enterPlantType": "Inserire il tipo di impianto",
		"enterPVCapacity": "Inserire la capacità fotovoltaica",
		"choiceInstallationDate": "Selezionare la data di installazione",
		"enterPlantAddress": "Inserire l'indirizzo dell'impianto",
		"enterOldPassword": "Inserire la vecchia password",
		"enterNewPassword": "Inserire la nuova password",
		"enterConfirmPassword": "Confermare la nuova password",
		"enter": "Inserire{scope}",
		"country": "Paese",
		"city": "Città",
		"scanCodeAddPlant": "Scansiona il codice per aggiungere una centrale!",
		"currentNoPlant": "Al momento non ci sono centrali, clicca per aggiungerne una!",
		"selectRepeatTime": "Selezionare un tempo di ripetizione",
		"enterDatalogger": "Inserire il numero di serie del collettore",
		"enterDeviceSN": "Inserire il numero di serie del dispositivo"
	},
	"setting": {
		"general": {
			"needGreat": "necessità maggiori",
			"needSmall": "necessità inferiore",
			"gapScope": "La gamma di valori di ingresso è",
			"valScope": "I valori di input variano"
		},
		"socket": {
			"switchStatus": "Interruttore di presa",
			"repetition": "Tempo di ripetizione",
			"enableOrNot": "Abilitato o no",
			"monday": "Lunedì",
			"tuesday": "Martedì",
			"wednesday": "Mercoledì",
			"thursday": "Giovedì",
			"friday": "venerdì",
			"saturday": "sabato",
			"sunday": "domenica"
		},
		"type3": {
			"dischargeScheduleMode": "Modalità di scarico temporizzato",
			"timeDischarge": "Scarica programmata",
			"workingDay": "Giorno feriale",
			"weekend": "Fine settimana",
			"pureLightControlMode": "Modalità controllo luce pura",
			"stopDischarge": "SOC Valore di impostazione dell'arresto della scarica in basso",
			"chargeTransferDischarge": "SOC Valore di impostazione della scarica completa",
			"batterySOCSetting": "Impostazione SOC batteria",
			"lowBatteryAlam": "Valore di impostazione dell'allarme di fine carica della batteria SOC basso",
			"lowBatteryShutdown": "Valore di impostazione dell'allarme di fine scarica del SOC della batteria",
			"systemSetting": "Impostazione del sistema",
			"systemWorkMode": "Modalità di lavoro del sistema",
			"restoreFactory": "Ripristino delle impostazioni di fabbrica",
			"loadMode": "Modalità di carico",
			"timingDischarge": "Modalità di scarica temporizzata",
			"DCOutputNormallyClosed": "Modalità uscita DC normalmente chiusa",
			"DCOutputNormallyOpen": "Modalità uscita CC normalmente aperta",
			"ForcedPowerProtection": "Modalità di conservazione forzata dell'energia",
			"mode": "Modalità",
			"startTime": "Tempo di avvio",
			"stopTime": "Tempo di fine",
			"onlyAddThree": "Aggiungere fino a 21 timer",
			"startCompareEnd": "L'ora di inizio del timer può essere inferiore all'ora di fine.",
			"confirmClose": "Willst du es wirklich ausschalten?"
		},
		"type6": {
			"countryCode": "Codice paese",
			"poweradjppm": "Velocità di regolazione della potenza dell'inverter",
			"enaINVTR": "Interruttore remoto on/off",
			"enaSysProtIF_selfCheck": "Autotest dell'interfaccia di protezione del sistema",
			"enaRestoretodefault": "Ripristino del valore predefinito",
			"UV_resopnseTime": "Tempo di risposta sottotensione livello {num}",
			"UF_resopnseTime": "Tempo di risposta sottofrequenza livello {num}",
			"OV_resopnseTime": "Tempo di risposta di sovratensione di grado {num}",
			"OF_resopnseTime": "Tempo di risposta overclock di livello {num}",
			"open": "Abilitazione",
			"recover": "Ripristino",
			"noHave": "Nessuno",
			"activePowerSwitch": "Interruttori di potenza attiva",
			"activePowerPermillage": "Rapporto millesimale di potenza attiva"
		},
		"type7": {
			"paramterSetting": "Impostazione dei parametri",
			"batteryVoltage": "Tensione della batteria",
			"batteryCapacity": "Capacità della batteria",
			"PVChargingCurrent": "Corrente di carica fotovoltaica",
			"overVoltage": "Sovratensione",
			"chargingLimitVoltage": "Tensione limite di carica",
			"increaseChargeReturnVoltage": "Tensione di ritorno della carica Boost",
			"overDischargeVoltage": "Tensione di sovrascarica",
			"stopchangeCurrent": "Interruzione della corrente di carica",
			"inverter485Address": "485 Indirizzo",
			"passwordValue": "Password utente",
			"inputPassword": "Inserimento password",
			"recordFaultCodes": "Registrazione del codice di guasto",
			"isOpen": "Controllo di commutazione",
			"equalizingChargeCmd": "Equalizzazione immediata della carica",
			"reset": "Ripristino del controllo",
			"factory": "Ripristino delle impostazioni di fabbrica",
			"clearAlarm": "Azzeramento dell'allarme corrente",
			"clearHistory": "Azzeramento della cronologia",
			"generatorSwitchCmd": "Commutazione del generatore",
			"nowTimeStr": "Ora corrente",
			"setVal": {
				"reset": "Ripristino",
				"recover": "Ripristino",
				"clearAlarm": "Avvertenze chiare",
				"clearHistory": "Cancella la cronologia",
				"toggle": "Commutazione"
			}
		},
		"type8": {
			"systemSwitch": "Commutazione di sistema"
		},
		"type10": {
			"modeSelect": "Modalità di selezione",
			"standbyMode": "Modalità standby",
			"PureOffGrid": "Modalità di avvio puramente off-grid",
			"energyNet": "Alimentazione energetica",
			"inverter0": "Inverter verso il carico locale",
			"inverter1": "Inverter a generazione di rete",
			"inverter2": "Inverter al carico locale con TA",
			"inverter3": "Inverter al carico locale con presa o contatore intelligente",
			"chargePriority": "Priorità di carica",
			"PVPriority": "Priorità al fotovoltaico",
			"utilityPriority": "Priorità alle utenze",
			"hybridModel": "Modo ibrido",
			"PVOnly": "Solo fotovoltaico",
			"batteryLowAlarm": "Valore di allarme basso del SOC della batteria",
			"batteryLowShutdown": "Valore di allarme di SOC basso della batteria",
			"batteryFull": "Valore di giudizio SOC pieno della batteria",
			"ACCharging": "Ricarica AC",
			"startTime": "Ora di inizio",
			"endTime": "Tempo di fine",
			"forcedDischarge": "Scarica forzata",
			"phaseGridSide": "Fase lato rete",
			"power": "Con potenza",
			"current": "Corrente",
			"gridConnectionStandard": "Standard paralleli",
			"baudRate": "485 tassi di cambio",
			"disable": "Disattivare",
			"enable": "Abilitato",
			"ApprovalStandards": "Standard certificati",
			"antiRefluxPower": "Potenza antivegetativa ",
			"antiRefluxSet": "L’antiflusso consente di produrre energia",
			"batFullSocSet": "Valore di giudizio SOC pieno della batteria (%)",
			"auxSourceSet": "Interruttore di alimentazione ausiliaria",
			"timeSharingGridConnectedDischarge": "Risoluzione del parlamento europeo sui diritti dell’uomo in cina",
			"timeSharingGridConnection": "Multiproprietà in rete",
			"outputPower": "Produzione di energia",
			"onlyAddThree": "Aggiungere al massimo {num} temporizzatori",
			"productModelNumber": "Modello di prodotto",
			"PleaseTurnOnThe_PCS_switchFirst":"Accendere prima l'interruttore PCS",
			"ThereIs_a_conflictInTheChargingAndDischargingTime":" C'è un conflitto nel tempo di carica e scarica"
		},
		"type11": {
			"switchPCS": "Interruttore PCS",
			"functionSwitch": "Interruttore di funzione",
			"maxChargeVol": "Tensione massima di carica (V)",
			"onGridMinDischargeVol": "Tensione minima di scarica collegata alla rete (V)",
			"offGridOutputVol": "Tensione di uscita fuori rete (V)",
			"maxChargeCur": "Corrente di carica massima (A)",
			"onGridMaxDischargeCur": "Corrente di scarica massima collegata alla rete (A)",
			"offGridMaxDisChargeCur": "Corrente di scarica massima fuori rete (A)",
			"minSocBMS": "BMS SOC minimo (%)",
			"minSocDischarge": "Scarico intelligente SOC minimo (%)",
			"maxSocDischarge": "Scarico intelligente SOC massimo (%)",
			"timeStr": "Tempo del dispositivo",
			"timerSetting": "Impostazione del timer {num}",
			"chargeStartTime1": "Tempo di avvio della carica1",
			"chargeLongTime1": "Durata di carica 1",
			"chargeTimeMaxSOC1": "Tempo di carica 1 SOC massimo (%)",
			"chargeTimeCurrent1": "Tempo di carica 1 Corrente di carica (A)",
			"dischargeStartTime1": "Tempo di avvio della scarica1",
			"dischargeLongTime1": "Durata della scarica1",
			"dischargeTimeMaxSOC1": "Tempo di scarica 1 SOC minimo (%)",
			"dischargeTimeCurrent1": "Tempo di scarica 1 Corrente di scarica (A)",
			"chargeStartTime2": "Tempo di avvio della carica 2",
			"chargeLongTime2": "Durata della carica 2",
			"chargeTimeMaxSOC2": "Durata della carica 2 SOC massimo (%)",
			"chargeTimeCurrent2": "Durata della carica 2 Corrente di carica (A)",
			"dischargeStartTime2": "Tempo di avvio della scarica 2",
			"dischargeLongTime2": "Durata della scarica2",
			"dischargeTimeMaxSOC2": "Durata della scarica 2 SOC minimo (%)",
			"dischargeTimeCurrent2": "Durata della scarica 2 Corrente di scarica (A)",
			"chargeStartTime3": "Tempo di avvio della carica 3",
			"chargeLongTime3": "Durata della carica 3",
			"chargeTimeMaxSOC3": "Durata della carica 3 SOC massimo (%)",
			"chargeTimeCurrent3": "Durata della carica 3 Corrente di carica (A)",
			"dischargeStartTime3": "Tempo di scarica iniziale 3",
			"dischargeLongTime3": "Periodo di scarica 3 SOC minimo (%)",
			"dischargeTimeMaxSOC3": "Periodo di scarica 3 SOC minimo (%)",
			"dischargeTimeCurrent3": "Tempo di scarica 3 Corrente di scarica (A)",
			"chargeStartTime4": "Tempo di avvio della carica 4",
			"chargeLongTime4": "Durata della carica 4",
			"chargeTimeMaxSOC4": "Durata della carica 4 SOC massimo (%)",
			"chargeTimeCurrent4": "Durata della carica 4 Corrente di carica (A)",
			"dischargeStartTime4": "Tempo di avvio della scarica 4",
			"dischargeLongTime4": "Durata della scarica 4",
			"dischargeTimeMaxSOC4": "Tempo di scarica 4 SOC minimo (%)",
			"dischargeTimeCurrent4": "Tempo di scarica 4 Corrente di scarica (A)",
			"functionSwitchObj": {
				"val1": "Modalità intelligente",
				"val2": "Modalità timer",
				"val3": "Modalità autogenerazione e autoconsumo"
			}
		},
		"type12": {
			"LEDSwitch": "Interruttore LED",
			"LEDSpeed": "Velocità del LED",
			"LEDEffect": "Effetto LED",
			"LEDBrightness": "Luminosità del LED",
			"dataColor": "Dati Rappresentazione del colore",
			"LED1Color": "Colore LED1",
			"LED2Color": "Colore LED2",
			"LED3Color": "Colore LED3",
			"buzzerAlarm": "Allarme cicalino",
			"overLoadToBypass": "Sovraccarico in bypass",
			"lcdRestartPage": "LCD non funzionante ripristinare l'interfaccia principale",
			"overloadReset": "Riavvio per sovraccarico",
			"overloadTemperature": "Riavvio per sovratemperatura",
			"backLightOn": "LCD senza retroilluminazione",
			"inputSourceChangeAlarm": "Allarme cambio sorgente d'ingresso",
			"faultCodeRecord": "Registrazione del guasto del software di comunicazione",
			"solarFeedToGird": "Solare inattivo Connessione alla rete",
			"switchOptions": "Opzioni dell'interruttore",
			"batteryParam": "Parametri della batteria",
			"outputParam": "Parametri di uscita",
			"ratedOutputVol": "Tensione di uscita nominale",
			"ratedOutputFreq": "Frequenza di uscita nominale",
			"inputVolRange": "Intervallo di tensione d'ingresso",
			"outPutSourcePriority": "Priorità della sorgente di uscita",
			"batType": "Tipo di batteria",
			"batUnderVol": "Tensione di spegnimento della batteria",
			"batBulkVol": "Tensione di carica costante della batteria",
			"batFloatVol": "Tensione di carica costante della batteria",
			"maxChargingCur": "Corrente di carica massima",
			"maxAcChargingCur": "Corrente massima di carica AC",
			"chargeSourcePriority": "Priorità della fonte di carica",
			"batReChargeVol": "Tensione di carica di recupero",
			"batFullRestoreDischargeVol": "Ripresa della tensione di scarica",
			"solarSupplyPriority": "Priorità all'energia solare",
			"resetPvStorage": "Ripristina i dati statistici FV",
			"countryRegulations": "Normativa nazionale",
			"acChargeStartTime": "Tempo di avvio del caricabatterie CA",
			"acChargeEndTime": "Tempo di arresto del caricabatterie CA",
			"acSupplyStartTime": "Tempo di avvio dell'alimentazione CA",
			"acSupplyEndTime": "Tempo di arresto dell'alimentazione CA",
			"restoreFactorySet": "Ripristino delle impostazioni di fabbrica",
			"parallelMode": "Modalità parallela",
			"bulkChargeVol": "Tensione di carica CV",
			"batEquSet": "Funzione di equalizzazione della batteria",
			"batEquTime": "Tempo di equalizzazione della batteria (min)",
			"batEquDay": "Intervallo di carica della batteria giorni (d)",
			"batEquChargeVol": "Tensione di carica della batteria (V)",
			"batEquTimeout": "Timeout di carica della batteria (min)",
			"equInfoSet": "Impostazione informazioni equalizzazione",
			"val": {
				"slow": "Lento",
				"medium": "Medio",
				"fast": "Veloce",
				"low": "Basso",
				"high": "Alta",
				"powerCycle": "Ciclo di potenza",
				"powerRotation": "Rotazione della potenza",
				"additionalPower": "Potenza aggiuntiva",
				"powerUnchanged": "Potenza invariata",
				"solarPowerInput": "Potenza solare in ingresso",
				"batteryCapacity": "Capacità della batteria Percentuale",
				"percentageLoad": "Percentuale del carico",
				"energySource": "Fonte di energia",
				"batteryStatus": "Stato di carica/scarica della batteria",
				"blue": "Blu",
				"cyan": "Ciano",
				"green": "Più verde",
				"pink": "Rosa",
				"purple": "Viola",
				"orange": "Arancione",
				"yellow": "Giallo",
				"white": "Bianco",
				"inputOvlRange1": "Modalità APL（90-280V）",
				"inputOvlRange2": "Modalità UPS（170-280V）",
				"outputSource1": "Solare->Utilità->Batteria",
				"outputSource2": "Solare->Batteria->Utilità",
				"chargeSourcePriority1": "Priorità al FV",
				"chargeSourcePriority2": "FV e Utilità",
				"chargeSourcePriority3": "Solo FV",
				"solarSupplyPriority1": "Batteria-Carico-Comune",
				"solarSupplyPriority2": "Carico-Batteria-Comune",
				"countryRegulations1": "India",
				"countryRegulations2": "Germania",
				"countryRegulations3": "Sud America",
				"parallelMode1": "Nessun parallelo",
				"parallelMode2": "Parallelo monofase",
				"outPutSourcePriority1": "Priorität des Stromnetzes",
				"outPutSourcePriority2": "Solarenergie kommt zuerst",
				"outPutSourcePriority3": "Solarstrom -> akku -> strom"
			}
		},
		"ops15": {
			"invSwitch": "Commutazione dell'inverter",
			"workMode": "Modelli di lavoro",
			"workMode1": "Priorità dell'alimentatore",
			"workMode2": "Modalità off-grid",
			"workMode3": "Autogenerazione e autoconsumo",
			"invTime": "Tempo dell'inverter",
			"batType": "Tipo di batteria",
			"batType1": "Nessuna batteria",
			"batType2": "Batterie al litio universali",
			"batType3": "Batteria al piombo",
			"batType4": "Batteria al litio",
			"timingTime": "Tempo di funzionamento",
			"offGirdSoc": "Sovrascarica SOC (%)",
			"optimizeRevenue": "Vantaggi ottimizzati"
		},
		"type19": {
			"totalActivePower": "Potenza attiva",
			"offgridSettings": "E posizionate fuori rete",
			"gridConnectedControlMode": "Controllo parallelo",
			"offGridControlMode": "Controllo fuori rete",
			"offgrid": {
				"gridNet": "collegataallarete",
				"offNet": "Dalle reti"
			}
		},
		"type22": {
			"parameterScope": "Gamma di parametri",
			"ACStartVoltage": "Impostazione della tensione/frequenza di avvio",
			"highVoltage": "Avvio in AC ad alta tensione",
			"lowVoltage": "Avvio AC a bassa tensione",
			"pvStartLowVol": "Avvio PV a bassa tensione",
			"acUnderVol1": "Sottotensione AC del primo ordine (V)",
			"acOverVol1": "Sovratensione AC del primo ordine (V)",
			"acUnderVolTime1": "Tempo di primo ordine della sottotensione AC (s)",
			"acOverVolTime1": "Sovratensione AC del primo ordine tempo(s)",
			"acUnderVol2": "Sottotensione AC del secondo ordine (V)",
			"acOverVol2": "Sovratensione AC del secondo ordine (V)",
			"acUnderVolTime2": "Sottotensione AC del secondo ordine tempo(s)",
			"acOverVolTime2": "Sovratensione AC secondo ordine tempo(s)",
			"startDelayTime": "Tempo di ritardo all'avvio",
			"restartDelayTime": "Tempo di ritardo al riavvio",
			"ACStartFrequency": "Impostazione sovratensione/frequenza",
			"highFrequency": "Avvio AC ad alta frequenza",
			"lowFrequency": "Avvio AC a bassa frequenza",
			"acUnderFrequency1": "Sottofrequenza AC del primo ordine (Hz)",
			"acOverFrequency1": "Sovrafrequenza AC del primo ordine (Hz)",
			"acUnderFrequencyTime1": "AC sottofrequenza primo ordine tempo(s)",
			"acOverFrequencyTime1": "Sovrafrequenza AC del primo ordine tempo(s)",
			"acUnderFrequency2": "Sottofrequenza AC del 2° ordine (Hz)",
			"acOverFrequency2": "Overclock AC del secondo ordine (Hz)",
			"acUnderFrequencyTime2": "Sottoclock AC del secondo ordine tempo(s)",
			"acOverFrequencyTime2": "Overclock AC del secondo ordine tempo(s)",
			"systemSetting": "Impostazione delle informazioni sul dispositivo",
			"SNset": "Impostazione del numero di serie",
			"reactiveControlMode": "Modalità di controllo della potenza reattiva",
			"inputMode": "Modalità di ingresso",
			"startSolpe": "Rampa di avvio",
			"restartSolpe": "Pendenza di riavvio",
			"reactivePercentage": "Percentuale reattiva",
			"activePercentage": "Percentuale attiva",
			"shutdown": "Interruttore remoto",
			"functionSelect": "Selezione della funzione",
			"manufacturer": "Produttore",
			"setFunctionSelect": {
				"val1": "Disattivare",
				"val2": "Abilita la corrente di inversione"
			},
			"setManufacturer": {
				"val1": "INVISIONE",
				"val2": "Skyworth"
			},
			"reactiveControlModeChoice": {
				"val1": "Controllo del fattore di potenza",
				"val2": "Controllo della percentuale di potenza reattiva",
				"val3": "Controllo della curva QV"
			},
			"inputModeChoice": {
				"val1": "Modalità indipendente dall'ingresso",
				"val2": "Modalità parallela di ingresso",
				"val3": "Modalità sorgente DC"
			}
		}
	},
	"title": {
		"login": "Accedi",
		"vistorLogin": "Accesso del visitatore",
		"createAccount": "Creare un account",
		"retrievePassword": "Recupera la password",
		"userAgreement": "Accordo con l'utente",
		"privacyPolicy": "Informativa sulla privacy",
		"phone": "Numero di telefono",
		"addPlant": "Aggiungi una centrale elettrica",
		"editPlant": "Modifica sito",
		"accountInfo": "Informazioni sull'account",
		"changePassword": "Modifica della password",
		"message": "Messaggi",
		"systemMessage": "Messaggi di sistema",
		"faultMessage": "Messaggi di errore",
		"deviceMessage": "Notizie attrezzature",
		"aboutUs": "Informazioni su",
		"device": "Dispositivi",
		"datalogger": "Raccoglitore",
		"setting": "Impostazioni",
		"socketSetting": "Impostazioni della presa",
		"addSchedule": "Aggiungi timer",
		"editSchedule": "Modifica timer",
		"repetitionTime": "Ripeti tempo",
		"addDatalogger": "Aggiunta di attrezzature",
		"connectWifi": "Instradamento della connessione",
		"distributionNetworkStatus": "Stato della distribuzione",
		"language": "Lingua",
		"selectCountry": "Selezionare il paese",
		"selectCity": "Selezionare la città"
	},
	"bar": {
		"home": "Pagina",
		"energy": "Energia",
		"plant": "Stazione",
		"user": "Utenti",
		"endpoint": "Endpoint",
		"mine": "Mio",
		"my": "Mio"
	},
	"button": {
		"logOut": "Disconnessione",
		"rememberMe": "Ricorda la password",
		"forgetPassword": "Dimenticato la password",
		"register": "Registrazione",
		"ok": "Conferma",
		"send": "Inviare",
		"auto": "Automaticamente",
		"cancle": "Storni",
		"yes": "Sì",
		"close": "Chiudere",
		"refresh": "Aggiornare",
		"next": "Avanti",
		"redistributionNetwork": "Ricablare",
		"upload": "Carica",
		"confirm": "Ricono",
		"edit": "Modifica",
		"delete": "Cancellare",
		"save": "Salva",
		"allReady": "Leggi tutto",
		"selectAll": "Seleziona tutto",
		"deselectAll": "Annulla tutto",
		"unsubscribe": "Annulla account",
		"later": "Più tardi",
		"upgrade": "Aggiornamento",
		"unfollow": "Unfollow",
		"add": "Aggiunta"
	},
	"subtitle": {
		"NeedMoreHelp": "Serve aiuto?",
		"VisitSupportCenter": "Visita il centro assistenza",
		"energyFlowDiagram": "Diagramma del flusso energetico",
		"selectTimezone": "Selezionare il fuso orario",
		"selectPlantType": "Selezionare il tipo di impianto",
		"selectInstallationDate": "Selezionare la data di installazione",
		"selectCurrency": "Seleziona la valuta",
		"selectDateType": "Selezionare il tipo di data",
		"selectDeviceType": "Seleziona il tipo di dispositivo",
		"socketBluetooth": "Presa di corrente Bluetooth",
		"log": "Registri",
		"all": "Tutti",
		"online": "Online",
		"offline": "Offline",
		"normal": "Normale",
		"abnormal": "Eccezioni",
		"on": "Acceso",
		"off": "Spento",
		"todayEnergy": "Energia di oggi",
		"totalEnergy": "Energia totale",
		"totalRevenue": "Entrate totali",
		"plantImage": "Immagine",
		"plantName": "Nome",
		"plantType": "Tipo",
		"PVCapacity": "Capacità fotovoltaica",
		"installationDate": "Data di installazione",
		"plantAddress": "Indirizzo centrale elettrica",
		"revenue": "Entrate",
		"timeZone": "Fuso orario",
		"updatePhone": "Aggiornamento telefono",
		"solar": "PV",
		"grid": "Rete",
		"battery": "Batteria",
		"home": "Carico",
		"load": "Carico",
		"socket": "Presa di corrente",
		"today": "Giorno",
		"day": "Giorno",
		"month": "Mese",
		"year": "Anno",
		"total": "Totale",
		"time": "Tempo",
		"Co2": "Co2",
		"coal": "Carbone",
		"deforestation": "Deforestazione",
		"parameter": "Parametri",
		"schedule": "Timer",
		"countdown": "Countdown",
		"socketSwitch": "Interruttore della presa di corrente",
		"currency": "Valuta",
		"energyPrice": "Tariffa",
		"storagePlant": "Impianti di accumulo di energia",
		"on-GridPlant": "Centrali elettriche collegate alla rete",
		"photo": "Avatar",
		"email": "Email",
		"account": "Account",
		"version": "Versioni",
		"officalWebsite": "Sito web ufficiale",
		"SN": "Numero di serie",
		"wifiSignal": "Segnale WiFi",
		"lastSync": "Ultima sincronizzazione",
		"switch": "Interruttore",
		"happening": "Si verifica",
		"restored": "Recuperato",
		"details": "Dettagli",
		"warn": "Avviso",
		"fault": "Errore",
		"status": "Stato",
		"level": "Grado",
		"deviceType": "Tipo di dispositivo",
		"alarmStartTime": "Ora di inizio allarme",
		"alarmEndTime": "Ora di fine allarme",
		"solution": "Soluzione",
		"loginIn": "Accedere a ......",
		"checkVersion": "Verifica aggiornamenti",
		"versionUpgrade": "Aggiornamento della versione",
		"messageDetail": "Dettagli del messaggio",
		"todayConsumption": "Consumo odierno",
		"totalConsumption": "Consumo totale di energia",
		"dataloggerSN": "Numero di serie del collettore",
		"configurationGuidance": "Guida alla configurazione",
		"guideVideo": "Video didattico",
		"manualInstuction": "Manuale di orientamento",
		"manualInstuction4G": "Manuale di orientamento 4G",
		"manualInstuctionMasterControl": "Manuale di istruzioni del Master Control",
		"masterControlConfiguration": "Rete di controllo principale",
		"defaultConfiguration": "Rete di distribuzione predefinita",
		"findEmail": "Recuperare la cassetta postale",
		"findPhone": "Trovare il cellulare",
		"family": "Famiglia",
		"agent": "Agenti",
		"endUsers": "Utenti finali",
		"deviceManagement": "Gestione delle attrezzature",
		"deviceSN": "Attrezzature SN",
		"firmwareVersion": "Versione del firmware",
		"alias": "Alias",
		"information": "Informazioni",
		"domainName": "Domini",
		"restartdatalogger": "Riavvia il raccoglitore",
		"value": "Valore",
		"customizable": "Definizione ",
		"ready": "Leggere",
		"deviceDetails": "Dettagli sull'attrezzatura",
		"startAddress": "Indirizzo del registro iniziale",
		"endAddress": "Indirizzo registro finale",
		"settingParam": "Impostazione dei parametri",
		"tariffType": "Tipo di tariffa",
		"fixed": "Fisso",
		"timeshare": "Multiproprietà",
		"addTime": "Aggiungi tempo",
		"notAllDay": "Non tutto il giorno",
		"dealer": "Rivenditori",
		"dealerNumber": "numero del rivenditore",
		"toggle": "Cambio",
		"environmentalBenefit": "Vantaggi ambientali",
		"basicParam": "Parametri di base",
		"realTimeParam": "Parametri in tempo reale",
		"export": "Esportazione",
		"localDebug": "Debug locale",
		"mode": "Modalità",
		"manuallyAdd": "Aggiungi manualmente"
	},
	"tip": {
		"noData": "Dati provvisori non disponibili",
		"base": {
			"maxDistance": "Farthest",
			"minDistance": "Nearest",
			"maxSize": "Max",
			"minSize": "Min"
		},
		"request": {
			"loading": "Caricamento",
			"netWork": "Connessione di rete fallita, controllare la rete",
			"connectServerFail": "Impossibile connettersi al server",
			"requestTimeout": "Timeout della richiesta"
		},
		"validate": {
			"choiceCountry": "Per favore seleziona prima un paese.",
			"country": "Il paese non può essere vuoto, selezionare il paese.",
			"timeZone": "Il fuso orario non può essere vuoto, selezionare il fuso orario.",
			"email": "Il formato dell'e-mail è errato, inserire un indirizzo e-mail corretto.",
			"verification": "Si prega di inviare prima il codice di verifica.",
			"verificationError": "Il codice di verifica è errato, inserire un codice valido.",
			"passwordFormTip": "Inserire una password di 6-20 cifre composta da numeri e lettere.",
			"passwordError": "Il formato della password è errato, inserire una password di 6-20 cifre composta da numeri e lettere.",
			"passwordError2": "Inserire una password valida (la password non deve includere caratteri speciali, simboli e spazi).",
			"validatePassword": "Le due password inserite non corrispondono, si prega di controllarle e reinserirle.",
			"confirmAgreement": "Leggere e accettare prima l'accordo sulla privacy.",
			"plantName": "Il nome della centrale non può essere vuoto, inserire il nome della centrale.",
			"plantType": "Il tipo di centrale non può essere vuoto, selezionare il tipo di centrale.",
			"pvCapacity": "La capacità della batteria solare non può essere vuota, inserire la capacità della batteria.",
			"installationDate": "La data di installazione non può essere vuota, selezionare la data di installazione.",
			"city": "La città non può essere vuota, selezionare la città.",
			"incomeunit": "La valuta non può essere vuota, selezionare la valuta.",
			"energyPrice": "Il prezzo dell'elettricità non può essere vuoto, inserire il prezzo dell'elettricità.",
			"firstChoiceCountry": "Selezionare prima un Paese.",
			"oldPassword": "La vecchia password non può essere vuota, inserire la vecchia password.",
			"newPassword": "La nuova password non può essere vuota, si prega di inserire una nuova password.",
			"confirmPassword": "Confermare la password non può essere vuoto, si prega di confermare la password.",
			"passwordNotMatch": "Le due password non sono uguali, controllare e confermare prima di inviare.",
			"SN1": "Il numero di serie non può essere vuoto.",
			"SN2": "Il numero di serie deve essere di 10 cifre.",
			"noSocketTimer": "Non c'è un timer della presa, fare clic per aggiungerlo.",
			"cameraPermission": "L’APP richiede il profilo della fotocamera da utilizzare per pulire il codice di aggiunta del raccoglitore.",
			"searchingState": "Ricerca di Bluetooth, non fare clic ripetutamente.",
			"notInTheScope": "Il parametro di ingresso non rientra nell'intervallo specificato.",
			"enterDecimal": "Deve inserire {num} cifre decimali o intere.",
			"integer": "Deve essere un numero intero.",
			"phone": "Il numero di cellulare serve solo per la cina continentale, mentre gli altri possono recuperarlo usando la loro cassetta delle lettere",
			"can`tEmpty": "{param} non può essere vuoto",
			"dealerSope": "La gamma dei numeri del rivenditore è di 6-8 cifre",
			"firstChoicePlant": "Selezionare prima la centrale"
		},
		"log": {
			"permissionOpen": "Assicuratevi che i permessi di localizzazione e Bluetooth siano attivati",
			"openBluetoothSystem": "Aprire le impostazioni Bluetooth",
			"openIosBtSystem": "Riavviare l’interruttore bluetooth, per favore",
			"openLocationSystem": "Aprire le impostazioni di posizionamento",
			"bluetoothAuthorization": "APP Autorizzazione Bluetooth non attivata",
			"locationAuthorization": "Autorizzazione di localizzazione APP non attivata",
			"searchSurroundingDevicesPermission": "Ricerca intorno ai dispositivi",
			"bluetoothNotPermission": "Il Bluetooth non è abilitato.",
			"locationNotPermission": "Il posizionamento non è abilitato.",
			"bluetoothPermission": "Il Bluetooth è abilitato.",
			"locationPermission": "Il posizionamento è attivo.",
			"bluetoothException": "L’bluetooth è stato interrotto eccezionalmente e confermare che l’apparecchio è stato attivato e quindi ricollegato",
			"listeningFailure": "Se le caratteristiche di ascolto non cambiano, per favore collegatevi nuovamente all’blueoth",
			"communicationException": "La comunicazione bluetooth è anomala. Per favore ricongiunzione bluetooth",
			"reconnect": "Riconnessione Bluetooth.",
			"connectFail": "Connessione Bluetooth fallita.",
			"connect": "Connessione Bluetooth.",
			"bluetoothService": "Attivare il servizio Bluetooth.",
			"locationService": "Attivare i servizi di localizzazione.",
			"notsupport": "La piattaforma attuale supporta la funzione Bluetooth.",
			"locationNotTurnOn": "Il posizionamento non è attivato, non è possibile cercare il Bluetooth.",
			"init": "Inizializzazione del Bluetooth.",
			"searching": "Ricerca del Bluetooth circostante.",
			"searchFail": "Non vi è alcuna richiesta di dispositivi in giro, accertatevi che siano stati attivati e cliccare su di loro per lavarsi di nuovo",
			"stopSearch": "Interruzione della ricerca del Bluetooth nelle vicinanze.",
			"stopSearchFail": "Impossibile interrompere la ricerca del Bluetooth nelle vicinanze.",
			"connectSuccess": "Connessione Bluetooth riuscita.",
			"setMTUsSuccess": "Impostazione dell'unità di trasmissione massima riuscita.",
			"setMTUsFail": "Impostazione dell'unità di trasmissione massima fallita.",
			"closeSuccess": "Disconnessione della connessione Bluetooth.",
			"closeFail": "Disconnessione connessione Bluetooth fallita.",
			"wifiLoadFail": "Caricamento elenco WiFi fallito, inserire manualmente.",
			"bluetoothAbnormal": "La connessione Bluetooth è anormale, provare a riconnettersi.",
			"getBluetoothServiceSuccess": "Impossibile ottenere il servizio del dispositivo Bluetooth.",
			"getBluetoothServiceFail": "Impossibile ottenere il servizio del dispositivo Bluetooth",
			"getFeatureValuesSucess": "Impossibile ottenere tutti i valori delle caratteristiche del servizio specificato del dispositivo Bluetooth.",
			"getFeatureValuesFail": "Impossibile ottenere tutti i valori delle caratteristiche nel servizio specificato di un dispositivo Bluetooth.",
			"subscriptionEigenvalueSuccess": "Abilitata la notifica sulle modifiche dei valori delle caratteristiche per i dispositivi Bluetooth a basso consumo, sottoscrizione dei valori delle prestazioni.",
			"subscriptionEigenvalueFail": "Abilitazione della notifica alla modifica del valore della funzione per i dispositivi Bluetooth a basso consumo, sottoscrizione dei valori della funzione non riuscita.",
			"monitoringSuccess": "Ascoltato con successo l'evento di modifica del valore di caratteristica del dispositivo Bluetooth a bassa potenza.",
			"monitoringFail": "Fallito l'ascolto dell'evento di modifica dell'autovalore del dispositivo Bluetooth a bassa potenza.",
			"CRC": "Verifica CRC fallita.",
			"queryWiFi": "Interrogazione riuscita dell'elenco WiFi.",
			"queryWiFiState": "Comando di interrogazione dell'elenco WiFi emesso con successo.",
			"wifiConenct": "Interrogazione dello stato di connessione del router.",
			"wifiFail": "Controllare l'account e la password del router e provare a riconnettersi.",
			"connectWiFiSuccess": "Connessione del router riuscita.",
			"connectWiFiFail": "Connessione del router fallita.",
			"routerAccount": "Errore dell'account del router.",
			"routerPassword": "Errore password del router.",
			"gettingIP": "Acquisire l'IP del router",
			"getIPFail": "Impossibile ottenere l'ip del router",
			"queryServerState": "È stato emesso con successo il comando per interrogare lo stato di interazione tra il dispositivo e il server.",
			"server03": "Ci sono 03 interazioni di dati.",
			"server04": "Ci sono 04 interazioni di dati.",
			"heartbeat": "Interazione dati Heartbeat.",
			"noInteraction": "Nessuna interazione di dati.",
			"bluetoothCommunication": "La comunicazione Bluetooth è stata stabilita.",
			"connectWiFi": "Connessione al WiFi.",
			"getWiFiList": "Controllo dell'elenco WiFi.",
			"wifiName": "Il nome del WiFi non può essere vuoto, selezionare o inserire 2.4 WiFi.",
			"wifiPasword": "Il nome WiFi non può essere vuoto, inserire la password.",
			"wifiPasswordError": "La password WiFi non può contenere caratteri speciali (ǎǐò-♢∝⌒℃℉, ecc.).",
			"masterControlId": "L'ID del master non può essere vuoto, inserire l'ID."
		},
		"addDatalogger": {
			"noSearchDevice": "Se non è possibile consultare l’attrezzatura, assicurarsi che l’apparecchiatura sia attiva",
			"searchAroundBluetooth": "Rete bluetooth disponibile intorno",
			"scan": "Scansionare il codice QR o inserire manualmente il numero di serie del collettore sotto il codice QR.",
			"connectBluetoothFail": "La connessione Bluetooth non è riuscita, scansionare il codice QR o inserire manualmente il numero di serie del collettore sotto il codice QR e connettersi di nuovo."
		},
		"connectWifi": {
			"tip1": "Configurare il dispositivo per unirsi alla rete.",
			"tip": "Selezionare 2.4G WiFi.",
			"devicePower": "Potenza attrezzaturaC",
			"enterSSID": "Inserire l'account del router.",
			"enterPassword": "Inserire la password del router.",
			"masterId": "Immettere l'Id di controllo master.",
			"commandSetFail": "Impostazione del comando non riuscita, si prega di ricollegare il collettore"
		},
		"configurationState": {
			"connecting": "Connessione",
			"connectFail": "Connessione fallita",
			"connectSuccess": "Connessione riuscita",
			"wait": "Attendere prego",
			"addDeviceSuccess": "Aggiunta di un dispositivo con successo"
		},
		"home": {
			"today": "Produzione giornaliera di elettricità",
			"month": "Produzione mensile di elettricità",
			"year": "Produzione annua di elettricità",
			"total": "Produzione lorda di elettricità",
			"homePower": "Famiglia.",
			"socketPower": "Carichi della presa di corrente.",
			"off-gridPower": "Carichi off-grid.",
			"acPower": "Potenza di utilità.",
			"on-gridPower": "Potenza attiva collegata alla rete.",
			"others": "Diverso da",
			"latestVersion": "È già l'ultima versione",
			"frontEndLoad": "Front-end",
			"backEndLoad": "Carico back-end"
		},
		"energy": {
			"choiceDate": "I dati attuali sono solo fino a.",
			"explain": "Descrizione",
			"tip1": {
				"load": "Energia consumata dal dispositivo di carico",
				"solar": "Energia utilizzata per convertire l'energia solare in elettricità per caricare le batterie o alimentare i carichi",
				"grid": "Energia accumulata venduta alla rete",
				"battery": "Energia elettrica accumulata per caricare le batterie"
			},
			"tip2": "= energia fotovoltaica * prezzo dell'elettricità.",
			"tip3": {
				"title": "Criteri per il calcolo del contributo sociale.",
				"title2": "1 kWh uguale：",
				"Co2": "L'energia elettrica accumulata utilizzata per caricare la batteria：",
				"coal": "Uso economico del carbone standard:",
				"deforestation": "Protezione degli alberi:"
			}
		},
		"plant": {
			"comprehensive": "Ordinamento completo",
			"totalEnergyMax": "La potenza totale più alta",
			"totalEnergyMin": "La potenza totale è più bassa",
			"realTimePower": "Potenza in tempo reale",
			"pvcapacityMax": "Massima capacità fotovoltaica",
			"pvcapacityMin": "La capacità fotovoltaica è la più bassa",
			"installationDateMax": "La data dell'ultima installazione",
			"installationDateMin": "La data di installazione più lontana",
			"paramChoice": "Preferenze",
			"filter": "Filtro",
			"addVisitor": "Aggiungi visitatore",
			"tip": "Tip",
			"visitorTip": "Può essere condivisa solo con gli utenti registrati",
			"name": "Nome",
			"permission": "Permessi",
			"read-write": "Letto/Scritto",
			"onlyread": "Sola lettura",
			"visitor": "Chiamante",
			"unfollow": "Sei sicuro di voler disiscrivere questa centrale elettrica?"
		},
		"device": {
			"power": "Potenza ",
			"type": "Tipo",
			"updateTime": "Tempo di aggiornamento ",
			"powerOnTime": "Tempo di elettricità ",
			"todayEnergy": "Oggi elettrico ",
			"signal": "Intensità del segnale "
		},
		"delDevice": "Sei sicuro di voler cancellare questo {device}?",
		"currentNoDevice": "Al momento non ci sono dispositivi.",
		"currentNoDatalog": "Al momento non ci sono raccoglitori.",
		"messageInfo": {
			"storage": "Accumulo di energia",
			"inverter": "Inverter",
			"microInverter": "Microinverter"
		},
		"userLocation": "Località dell’utente",
		"ops": {
			"setDomainName": "Siete sicuri di voler impostare questo dominio?",
			"restartDatalogger": "Siete sicuri di voler riavviare il datalogger?"
		},
		"opsLog": {
			"addUser": "Aggiungi utente finale",
			"bindSuccess": "Collegamento riuscito",
			"selectDate": "Date di selezione",
			"start": "Inizio",
			"end": "Fine",
			"upgrade": "Upgrade",
			"operation": "Azione",
			"communication": "Comunicare",
			"type": "Tipo",
			"content": "Contenuto",
			"control": "Controllore",
			"command": "Tipo di comando",
			"reset": "Azzeramento",
			"serverTime": "Tempo del server",
			"inverter": "Intervallo",
			"bindAgent": "Distributori vincolati",
			"deleteDeviceSN": "La prego di confermare la cancellazione？",
			"recordLog": "Apparecchi per la registrazione del giornale di bordo"
		},
		"setting": {
			"productModelNumberRelevancyOn-gridPower": "La potenza attiva connessa alla rete non può essere superiore alla potenza massima del modello di prodotto selezionato"
		}
	},
	"productEquipment": {
		"productModelNumber": "Modello di prodotto",
		"warnMessage": "Avvisa messaggio",
		"customInstruction": "Istruzione personalizzata",
		"firmwareUpdate": "Aggiornamento firmware",
		"historicalData": "Dati storici",
		"linkageInformation": "Informazioni collegamento",
		"batchRead":"Lettura Batch",
		"batchSetting":"Impostazione Batch",
		"protocolDirectives":"Direttive del Protocollo",
		"basicSettings":"Impostazioni di base",
		"otherSettings":"Altre impostazioni",
	},
	"systemAuthority": {
	    "systemSettings": 'Impostazioni di sistema',
	    "authorityManagement": 'Gestione delle autorità',
	    "accountManagement": "Gestione dell'account",
		"Disabled":"Disabile",
		"enable":"abilitare"
	},
	"org":{
		"ChangeSupplier":'Cambia fornitore'
	}
}
