/*
 * @Author: lqj
 * @Desc:  自定义公共方法
 * @Date: 2024-07-11 18:59:05
 * @LastEditors: lqj
 * @LastEditTime: 2024-08-09 11:28:35
 */
import Vue from 'vue'
import store from '@/store/index'

/**
 * @description: 处理时间格式
 * @param {*} format  "yyyy-MM-dd hh:mm:ss:S" 
 * @optional  yyyy-MM-dd hh:mm:ss  || yyyy || yyyy-MM || yyyy-MM-dd ...
 * @call      new Date().format("yyyy-MM-dd hh:mm:ss:S") 
 * @return {*} 2024-07-08 06:53:26
 */

const format = function (format) {
    var args = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),  //quarter
        "S": this.getMilliseconds()
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var i in args) {
        var n = args[i];
        if (new RegExp("(" + i + ")").test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? n : ("00" + n).substr(("" + n).length));
    }
    return format;
};
Date.prototype.format = format




/**
 * @description:  将 schema 初始化 为 obj
 * @return {*}
 */
var diction = {
    // 将 schema 初始化 为 obj
    createObjFormSchema: function (schema) {
        var obj = {}
        let self = this
        for (let field of schema) {
            self.setProp(obj, field.name, '')
            if (field.initValue) obj[field.name] = field.initValue // 非字符串类型值时：给定默认值
            if (field.startName || field.endName) {
                obj[field.startName] = ''
                obj[field.endName] = ''
            }
        }
        return obj
    },
    // 查找 schema 中的该值
    getFieldFromSchema(schema, name) {
        var arr = schema.filter(field => {
            return field.name === name
        })
        var field = arr.length ? arr[0] : null
        return field
    },
    setProp: (obj, path, value) => {
        if (!path) {
            return
        }
        var pList = path.split('.')
        var len = pList.length
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i]
            if (!obj[elem]) obj[elem] = {}
            obj = obj[elem]
        }
        obj[pList[len - 1]] = value
    }
}

const hasJurisdiction = function (code) {
    return store.getters.listResourceCode.includes(code)
}

const formatEmpty = function (data) {
    if (data || data === 0) {
        return data
    }
    return '-'
}
/**
 * @description: canvas 实现 watermark
 * @return {*}
 */
function __canvasWM({
    // 使用 ES6 的函数默认值方式设置参数的默认取值
    // 具体参见 https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Functions/Default_parameters
    container = document.body,
    width = '300px',
    height = '200px',
    textAlign = 'center',
    textBaseline = 'middle',
    font = "12px Microsoft Yahei",
    fillStyle = 'rgba(39,40,72,0.05)',
    content = '请勿外传',
    rotate = '30',
    zIndex = 1000
} = {}) {
    const args = arguments[0];
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    const ctx = canvas.getContext("2d");
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.font = font;
    ctx.fillStyle = fillStyle;
    ctx.rotate(Math.PI / 180 * rotate);
    ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2);
    const base64Url = canvas.toDataURL();
    const __wm = document.querySelector('.__wm');
    const watermarkDiv = __wm || document.createElement("div");
    const styleStr = `
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:${zIndex};
    pointer-events:none;
    background-repeat:repeat;
    background-image:url('${base64Url}')`;
    watermarkDiv.setAttribute('style', styleStr);
    watermarkDiv.classList.add('__wm');
    if (!__wm) {
        container.style.position = 'relative';
        container.insertBefore(watermarkDiv, container.firstChild);
    }
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    if (MutationObserver) {
        let mo = new MutationObserver(function () {
            const __wm = document.querySelector('.__wm');
            // 只在__wm元素变动才重新调用 __canvasWM
            if ((__wm && __wm.getAttribute('style') !== styleStr) || !__wm) {
                // 避免一直触发
                mo.disconnect();
                mo = null;
                // getToken() && __canvasWM(JSON.parse(JSON.stringify(args)));
                __canvasWM(JSON.parse(JSON.stringify(args)));
            }
        });
        mo.observe(container, {
            attributes: true,
            subtree: true,
            childList: true
        })
    }
}
 
  
const yl = {}
const bus = new Vue
yl.install = function (Vue) {
    Vue.prototype.$yl = diction
    Vue.prototype.$bus = bus
    Vue.prototype.$format = format
    // Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_API
    // Vue.prototype.$smartUrl = process.env.VUE_APP_BASE_SMART_API
    // 将filters挂载到vue到原型上，在js中可以调用如：this.filters.functionName(...args) 如this.filters.formatDateByExp('2020-10-11 16:20:20')
    Vue.prototype.$filters = Vue.options.filters
    Vue.prototype.$hasJurisdiction = hasJurisdiction
    Vue.prototype.$formatEmpty = formatEmpty
    Vue.prototype.$__canvasWM = __canvasWM
}

export { diction, yl }
export default diction
