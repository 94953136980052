import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from 'element-ui/lib/locale/lang/en'
import Zh_CN from 'element-ui/lib/locale/lang/zh-CN'
import zh_HK from 'element-ui/lib/locale/lang/zh-TW'

import ENLocale from './en-US'
import ZH_CNLocale from './zh-CN'
import zhTWLocal from './zh-HK'
import deLocal from './de-DE'
import itLocal from './it-IT'
Vue.use(VueI18n)
const messages = {
  'zh-CN': {
    ...ZH_CNLocale,
    ...Zh_CN
  },
  'en-US': {
    ...ENLocale,
    ...EN
  },
  'zh-HK': {
    ...zhTWLocal,
    ...zh_HK
  },
  de: {
    ...deLocal,
  },
  it: {
    ...itLocal,
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('Accept-Language') || 'en-US', // 设置语种
  messages, // 设置全局当地语言包,
  fallbackLocale: 'zh-CN', //如果没有找到要显示的语言，则默认显示 ‘en’

})

export default i18n
